import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Badge from "react-bootstrap/Badge";

function ClearBadges({
    selectedCategories,
    setSelectedCategories,
    selectedFormats,
    setSelectedFormats,
    selectedGrades,
    setSelectedGrades,
    selectedResourceTypes,
    setSelectedResourceTypes,
}) {
    const removeCategory = (category) => {
        const cleanedCategories = selectedCategories.filter(
            (oldCategory) => oldCategory.id !== category.id
        );
        setSelectedCategories(cleanedCategories);
    };

    const removeGrade = (grade) => {
        const cleanedGrades = selectedGrades.filter(
            (oldGrade) => oldGrade.id !== grade.id
        );
        setSelectedGrades(cleanedGrades);
    };

    const removeFormat = (itemToRemove) => {
        console.log("Removing", itemToRemove, "from Selected:", selectedFormats);
        const cleaned = selectedFormats.filter(
            (old) => old.title !== itemToRemove.title
        );
        setSelectedFormats(cleaned);
    };

    const removeResourceType = (itemToRemove) => {
        const cleaned = selectedResourceTypes.filter(
            (old) => old.id !== itemToRemove.id
        );
        setSelectedResourceTypes(cleaned);
    };
    return (
        <Row>
            <Col direction="horizontal" className="mt-2" gap={2}>
                {selectedFormats.map((format) => (
                    <Badge
                        pill
                        bg="secondary"
                        className="cursor-pointer me-1"
                        onClick={() => removeFormat(format)}
                    >
                        {format.title} <i className="bi bi-x"></i>
                    </Badge>
                ))}
                {selectedCategories.map((category) => (
                    <Badge
                        pill
                        bg="secondary"
                        className="cursor-pointer me-1"
                        onClick={() => removeCategory(category)}
                    >
                        {category.title} <i className="bi bi-x"></i>
                    </Badge>
                ))}
                {selectedGrades.map((grade) => (
                    <Badge
                        pill
                        bg="secondary"
                        className="cursor-pointer"
                        onClick={() => removeGrade(grade)}
                    >
                        Grade {grade.title} <i className="bi bi-x"></i>
                    </Badge>
                ))}
                {selectedResourceTypes.map((resourceType) => (
                    <Badge
                        pill
                        bg="secondary"
                        className="cursor-pointer me-1"
                        onClick={() => removeResourceType(resourceType)}
                    >
                        {resourceType.title} <i className="bi bi-x"></i>
                    </Badge>
                ))}
            </Col>
        </Row>
    );
}

export default ClearBadges;
