import React from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";

const EmptySharedAssignmentsPage = (props) => {
    if (props.isLoadingRooms) {
        return null;
    }

    return (
        <div className="h-100 w-100 bg-light">
            <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                <div style={{ maxWidth: "380px" }} className="text-center">
                    <i
                        className="bi bi-easel text-purple-dark"
                        style={{ fontSize: "3rem" }}
                    ></i>
                    <p className="fw-bold">
                        Shared Student Projects allow teachers within an organization to
                        collaborate and share their resources seamlessly.
                    </p>
                    <ListGroup className="text-start">
                        <ListGroup.Item>
                            <strong>How it works</strong>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>1.</strong> Easily share your lessons from{" "}
                            <i>My Lessons</i> page with your organization.
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>2.</strong> A list of lessons will appear
                            here once they are shared by you or other teachers.
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>3.</strong> The lessons are ready for you to
                            explore.
                        </ListGroup.Item>
                    </ListGroup>
                    <Button
                        href="https://gomegaminds.com/schools-districts/"
                        target="_blank"
                        variant="link"
                        className="mb-0 mt-2 fw-light"
                    >
                        <small>Learn More</small>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EmptySharedAssignmentsPage;
