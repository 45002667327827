import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { useAuth0 } from "@auth0/auth0-react";

import { NoSubscription } from "./profile/NoSubscription";
import { HasSubscription } from "./profile/HasSubscription";
import EditProfileCard from "./profile/EditProfileCard";
import { OrganizationSubscription } from "./profile/OrganizationSubscription";
import { useLocation } from "react-router-dom";
import { useAuth } from "../context/AuthContext";
import { Helmet } from "react-helmet";

const Profile = () => {
    const auth = useAuth();

    const location = useLocation();
    const [subscription, setSubscription] = useState(null);
    const [subscriptionSuccess, setSubscriptionSuccess] = useState(false);
    const [isSubscriptionLoading, setSubscriptionLoading] = useState(true);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (location.state?.subscriptionSuccess) {
            console.log("Subscription success!");
            auth.refreshProfile();
            setSubscriptionSuccess(true);
        }
    }, []);

    useEffect(() => {
        if (auth.profile && auth.profile.permanent_premium) {
            setSubscription("Permanent Premium");
        }
        if (auth.profile && auth.profile.stripe_subscription_id) {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                scope: "update:classroom",
            }).then((token) => {
                fetch(
                    process.env.REACT_APP_MM_API_URL +
                        "payments/subscription/retrieve",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        setSubscriptionLoading(false);
                        setSubscription(data);
                    })
                    .catch((error) => {
                        setSubscriptionLoading(false);
                        console.error(error);
                    });
            });
        } else {
            setSubscriptionLoading(false);
        }
    }, [auth, getAccessTokenSilently]);

    if (!auth.profile) {
        return <p>You must be signed in to view this page</p>;
    }

    if (isSubscriptionLoading) {
        return <p>Loading...</p>;
    }

    return (
        <Container className="p-5">
            <Helmet>
                <title>Profile - MegaMinds</title>
            </Helmet>
            {subscriptionSuccess && (
                <Row>
                    <Col>
                        <Alert variant="success">
                            <p>You have successfully subscribed.</p>
                            <p className="mb-0">
                                You can manage your subscription at any time on
                                this page.
                            </p>
                        </Alert>
                    </Col>
                </Row>
            )}
            <Row>
                <Col>
                    <h2>Profile</h2>
                    <p className="fw-light">{auth.profile.email}</p>
                    <hr className="mt-4 mb-4" />
                    <EditProfileCard
                        profile={auth.profile}
                        onUpdated={auth.refreshProfile}
                    />
                </Col>
            </Row>
            {auth.profile.subscription_plan === "Organization Plan" ? (
                <OrganizationSubscription />
            ) : (
                <>
                    {!isSubscriptionLoading && !subscription ? (
                        <NoSubscription />
                    ) : (
                        <HasSubscription
                            subscription={subscription}
                            onUpdate={auth.refreshProfile}
                        />
                    )}
                </>
            )}
        </Container>
    );
};

export default Profile;
