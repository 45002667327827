import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth0 } from "@auth0/auth0-react";
import {
    CardElement,
    AddressElement,
    Elements,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";

const CheckoutForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const [address, setAddress] = useState("");
    const [addressCompleted, setAddressCompleted] = useState(false);
    const [cardCompleted, setCardCompleted] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const [loadingText, setLoadingText] = useState("Pay Now");
    const [errorText, setErrorText] = useState("");

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoadingText("Creating payment method");
        let card = elements.getElement(CardElement);

        console.log(card);

        if (elements == null) {
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: card,
        });
        setLoadingText("Creating subscription");

        if (error) {
            setLoadingText("Pay Now");
            setErrorText(error);
        }

        if (!error) {
            try {
                const { id } = paymentMethod;

                const payload = {
                    orgName: props.organization
                        ? props.organization.name
                        : props.orgName,
                    existing_org: props.organization
                        ? props.organization.id
                        : null,
                    price: props.price,
                    quantity: props.quantity,
                    address: address,
                    paymentMethod: id,
                };

                console.log("Got quantity", payload.quantity);

                getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                    scope: "update:classroom",
                }).then((token) => {
                    fetch(
                        process.env.REACT_APP_MM_API_URL +
                            "payments/organization/create",
                        {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                                Authorization: `Bearer ${token}`,
                            },
                            body: JSON.stringify(payload),
                        }
                    )
                        .then((response) => {
                            return response.json();
                        })
                        .then((data) => {
                            setLoadingText("Getting payment...");
                            console.log("Subscription", data);
                            if (data.latest_invoice.paid) {
                                setLoadingText("Successfully paid.");
                                window.location.replace("/organization");
                            } else {
                                stripe
                                    .confirmPayment({
                                        clientSecret:
                                            data.latest_invoice.payment_intent
                                                .client_secret,
                                        confirmParams: {
                                            return_url: window.location.href,
                                        },
                                    })
                                    .then((result) => {
                                        if (result.error) {
                                            alert(result.error.message);
                                            setLoadingText("Pay Now");
                                        } else {
                                            alert("success card");
                                            setLoadingText("Success");
                                            window.location.replace(
                                                "/organization"
                                            );
                                        }
                                    });
                            }

                            console.log("Success", data);
                        })
                        .catch((error) => {
                            setLoadingText("Pay Now");
                        });
                });
            } catch (error) {
                props.setError(true);
                console.log("Error", error);
            }
        } else {
            props.setError(true);
            console.log(error.message);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-control p-3">
                <Form.Group>
                    <AddressElement
                        options={{ mode: "billing" }}
                        onChange={(event) => {
                            setAddressCompleted(event.complete);

                            if (event.complete) {
                                // Extract potentially complete address
                                setAddress(event.value.address);
                            }
                        }}
                    />
                    <CardElement
                        onChange={(event) => {
                            setCardCompleted(event.complete);
                        }}
                        className="form-control p-3 mt-4"
                    />
                </Form.Group>
            </div>
            <Row>
                <Form.Label className="mt-4">
                    <Col>
                        <p>{errorText.message}</p>
                    </Col>
                </Form.Label>
            </Row>
            <small className="fw-light">
                {props.cycle === "monthly"
                    ? "$" + props.monthlyPrice
                    : "$" + props.yearlyPrice}{" "}
                USD will be charged when clicking "Pay Now".
            </small>
            <Button
                variant="success"
                className="mt-4 w-100"
                type="submit"
                disabled={
                    loadingText !== "Pay Now" ||
                    !addressCompleted ||
                    !cardCompleted
                }
            >
                {loadingText}
            </Button>
        </form>
    );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const getStripePrice = (cycle) => {
    if (cycle === "monthly") {
        return process.env.REACT_APP_STRIPE_MONTHLY_PRICE;
    }
    if (cycle === "yearly") {
        return process.env.REACT_APP_STRIPE_YEARLY_PRICE;
    }
};

export function CheckoutBulkModal(props) {
    const [yearlyPrice, setYearlyPrice] = useState(149.99 * props.quantity);
    const [monthlyPrice, setMonthlyPrice] = useState(14.99 * props.quantity);

    useEffect(() => {
        setMonthlyPrice(14.99 * props.quantity);
        setYearlyPrice(149.99 * props.quantity);
    }, [props.quantity]);

    return (
        <Modal show={props.show} size="md" onHide={() => props.setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Checkout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mt-2">
                    <Col xs={12}>
                        <Card className="h-100">
                            <Card.Body>
                                <Row className="">
                                    <h5 className="">
                                        {props.organization
                                            ? "New subscription for " +
                                              props.organization.name
                                            : props.orgName}
                                    </h5>
                                    <p className="fw-bold">
                                        {props.quantity} Licences
                                    </p>
                                    <p className="fw-bold">
                                        {props.cycle === "monthly"
                                            ? "Billed $" +
                                              monthlyPrice +
                                              " monthly"
                                            : "Billed $" +
                                              yearlyPrice +
                                              " yearly"}
                                    </p>
                                    <p className="fw-light mb-0">
                                        Manage multiple licences and delegate
                                        with an organization
                                    </p>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col>
                        <Elements stripe={stripePromise}>
                            <CheckoutForm
                                price={getStripePrice(props.cycle)}
                                yearlyPrice={yearlyPrice}
                                monthlyPrice={monthlyPrice}
                                cycle={props.cycle}
                                quantity={props.quantity}
                                orgName={props.orgName}
                                organization={props.organization}
                            />
                        </Elements>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => props.setShow(false)}
                >
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
