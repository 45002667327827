import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useAuth0 } from "@auth0/auth0-react";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import moment from "moment";

import { PaymentHistoryModal } from "../payments/modals/PaymentHistoryModal";
import { ManageSubscriptionModal } from "../payments/modals/ManageSubscriptionModal";

export function HasSubscription(props) {
    const [showPaymentHistory, setShowPaymentHistory] = useState(false);
    const [showManageModal, setShowManageModal] = useState(false);

    const [upcomingInvoice, setUpcomingInvoice] = useState(null);
    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        console.log("Got useeffect", props.subscription)
        if (!upcomingInvoice && props.subscription !== "Permanent Premium") {
            console.log("Not permanent premium")
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                scope: "update:classroom",
            }).then((token) => {
                fetch(
                    process.env.REACT_APP_MM_API_URL +
                        "payments/invoices/upcoming",
                    {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                    }
                )
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        console.log("Got upcoming invoice", data);
                        setUpcomingInvoice(data);
                        console.log("Success", data);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            });
        }
    }, [props.subscription, getAccessTokenSilently, upcomingInvoice]);

    if (props.subscription === "Permanent Premium") {
        return (
            <Alert variant="success" className="mt-2 mb-5">
                <h2>Subscription managed by MegaMinds</h2>
            </Alert>
        );
    }

    if (
        props.subscription.status !== "active" &&
        props.subscription.latest_invoice?.paid !== true
    ) {
        return (
            <Alert variant="warning mt-2 mb-5">
                <h2>Action Required</h2>
                <p className="mb-0">
                    We were unable to complete the payment of your latest
                    invoice.
                </p>
                <p>
                    You will be put back on Free Plan on{" "}
                    {moment
                        .unix(props.subscription.latest_invoice?.period_end)
                        .format("dddd, MMMM Do, YYYY, HH:MM")}
                </p>
                <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={props.subscription.latest_invoice?.hosted_invoice_url}
                    className="me-2"
                >
                    Click here to resolve
                </a>
            </Alert>
        );
    }

    return (
        <>
            <Row>
                <Col>
                    <h2>Billing Summary</h2>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col className="fw-bold">
                                    <p className="mb-2">Your next payment</p>
                                    {props.subscription.cancel_at_period_end ? (
                                        <>
                                            <h4 className="fw-bold">${0}</h4>
                                            <small className="fw-light">
                                                Subscription will end at{" "}
                                                {moment
                                                    .unix(
                                                        props.subscription
                                                            .cancel_at
                                                    )
                                                    .format(
                                                        "dddd, MMMM Do, YYYY"
                                                    )}
                                            </small>
                                        </>
                                    ) : (
                                        <>
                                            <h4 className="fw-bold">
                                                ${upcomingInvoice?.amount_due / 100}
                                            </h4>
                                            <small className="fw-light">
                                                {moment
                                                    .unix(
                                                        props.subscription
                                                            .current_period_end
                                                    )
                                                    .format(
                                                        "dddd, MMMM Do, YYYY"
                                                    )}
                                            </small>
                                            {upcomingInvoice?.discount && (
                                                <Alert
                                                    variant="success"
                                                    className="mt-2"
                                                >
                                                    <span className="text-success">
                                                        Coupon active until{" "}
                                                        {moment
                                                            .unix(
                                                                upcomingInvoice
                                                                    .discount
                                                                    .end
                                                            )
                                                            .format(
                                                                "MMMM Do, YYYY"
                                                            )}
                                                    </span>
                                                </Alert>
                                            )}
                                        </>
                                    )}
                                </Col>
                                <Col>
                                    <ListGroup>
                                        <ListGroup.Item>
                                            <PaymentHistoryModal
                                                invoices={props.invoices}
                                                show={showPaymentHistory}
                                                setShow={setShowPaymentHistory}
                                            />
                                            <Button
                                                variant="link"
                                                className="m-0 p-0"
                                                onClick={() =>
                                                    setShowPaymentHistory(true)
                                                }
                                            >
                                                Payment history
                                            </Button>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Button
                                                variant="link"
                                                className="m-0 p-0"
                                                onClick={() =>
                                                    setShowManageModal(true)
                                                }
                                            >
                                                Manage subscription
                                            </Button>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <h2>Current Plan</h2>
                </Col>
                <Col className="d-flex justify-content-end">
                    <ManageSubscriptionModal
                        subscription={props.subscription}
                        show={showManageModal}
                        setShow={setShowManageModal}
                        update={props.onUpdate}
                    />
                    <Button
                        onClick={() => setShowManageModal(true)}
                        className="ms-2"
                        variant="secondary"
                    >
                        Manage
                    </Button>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <Card>
                        <Card.Header className="fw-bold">
                            Premium Plan
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <p>Included</p>
                                    <ListGroup>
                                        <ListGroup.Item>
                                            Unlimited Rooms
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Unlimited Assignment
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            25 users per room
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
