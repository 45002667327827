import React from "react";
import { useParams } from "react-router-dom";

import Students from "./assessment/Students";
import Questions from "./assessment/Questions";

import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import useDashApi from "../../services/useDashApi";
import { useAuth } from "../../context/AuthContext";

const Assessment = (props) => {
    const { id } = useParams();
    const auth = useAuth();

    const [roomData, isLoading, isError, refreshBundles] = useDashApi(
        "assessments/bundles/" + id,
        auth
    );

    if (isLoading) {
        return <p>Loading</p>;
    }

    if (isError) {
        return <p>Error loading room data</p>;
    }

    const refresh = () => {
        console.log("Refreshing...");
        refreshBundles();
    };

    return (
        <>
            <Container fluid className="g-0 bg-light">
                <Row className="bg-white p-3 p-lg-4 border-bottom m-0">
                    <Col md={12} lg={7} className="d-flex align-items-center">
                        <h3 className="mb-0">
                            Assessments for {roomData.title}
                        </h3>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-lg-end mt-4 mb-2 mt-lg-0 mb-lg-0"></Col>
                </Row>
            </Container>
            <Container fluid className="g-0 bg-light">
                <Row className="bg-white p-3 p-lg-4 border-bottom m-0">
                    <Link to="/lessons">
                        <i className="bi bi-arrow-left me-2"></i>Back
                    </Link>
                </Row>
                <Row className="bg-light m-0 mt-3">
                    <Tabs
                        defaultActiveKey="home"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="home" title="Students" className="p-3">
                            <Students
                                refreshInfo={refresh}
                                roomData={roomData}
                            />
                        </Tab>
                        <Tab eventKey="profile" title="Questions">
                            <Questions roomData={roomData} />
                        </Tab>
                    </Tabs>
                </Row>
            </Container>
            <Container className="g-0 bg-light"></Container>
        </>
    );
};

export default Assessment;
