import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useDashApi = (endpoint, auth) => {
    const { getAccessTokenSilently } = useAuth0();
    const [classrooms, setClassrooms] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(0);

    const refresh = () => {
        setRefreshIndex(refreshIndex + 1);
    };

    useEffect(() => {
        if (!auth.isLoading && !auth.isProfileLoading && auth.profile) {
            (async () => {
                if (!auth.user) {
                    setError("Is not signed in");
                } else if (!auth.profile) {
                    setError("Has no profile");
                } else {
                    setError(false);
                    try {
                        const token = await getAccessTokenSilently({
                            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                        });
                        const response = await fetch(
                            process.env.REACT_APP_MM_API_URL + endpoint,
                            {
                                headers: {
                                    Authorization: "Bearer " + token,
                                },
                            }
                        );
                        setClassrooms(await response.json());
                        setLoading(false);
                    } catch (e) {
                        console.error(e);
                        setLoading(false);
                        setError(e);
                    }
                }
            })();
        }
    }, [auth, getAccessTokenSilently, refreshIndex, endpoint]);

    return [classrooms, loading, error, refresh];
};

export default useDashApi;
