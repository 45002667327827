import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useSharedRoomAPI = (page = 1, search = "", order = "-id", auth) => {
    const { getAccessTokenSilently } = useAuth0();
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(0);

    const refresh = () => {
        setRefreshIndex(refreshIndex + 1);
    };

    useEffect(() => {
        setLoading(true);
        if (!auth.isLoading && !auth.isProfileLoading && auth.profile) {
            (async () => {
                try {
                    const token = await getAccessTokenSilently({
                        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                        scope: "read:classrooms",
                    });
                    const response = await fetch(
                        process.env.REACT_APP_MM_API_URL +
                            "assignments/shared-list?page=" +
                            page +
                            "&search=" +
                            search +
                            "&ordering=" +
                            order,
                        {
                            headers: {
                                Authorization: "Bearer " + token,
                            },
                        }
                    );
                    setRooms(await response.json());
                    setLoading(false);
                    setError(false);
                } catch (e) {
                    console.error(e);
                    setLoading(false);
                    setError(e);
                }
            })();
        } else {
            if (auth.isLoading && auth.isProfileLoading) {
                setLoading(true);
            } else if (
                !auth.isLoading &&
                !auth.isProfileLoading &&
                !auth.profile
            ) {
                setLoading(false);
                setError("You must be logged in to view this page.");
            }
        }
    }, [getAccessTokenSilently, refreshIndex, page, search, order, auth]);

    return [rooms, loading, error, refresh];
};

export default useSharedRoomAPI;
