import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Accordion from "react-bootstrap/Accordion";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth0 } from "@auth0/auth0-react";

import useDashApiGuest from "../services/useDashApiGuest";
import supportImg from "../img/support.png";
import useTemplateAPI from "../services/useTemplateAPI";

import roomImg from "../img/room-group.png";

import TemplateCard from "./explore/cards/TemplateCard";
import BundleCard from "./explore/cards/BundleCard";

const HomeGuest = (props) => {
    const { loginWithRedirect } = useAuth0();

    const [featured, isFeaturedLoading, featuredError] = useTemplateAPI(
        "",
        "Featured",
        ""
    );

    const [
        featuredBundles,
        isFeaturedBundlesLoading,
        featuredBundlesError,
        refreshFeaturedBundles,
    ] = useDashApiGuest("templates/bundles/featured");

    const [featuredMasterList, setFeaturedMasterList] = useState([]);

    useEffect(() => {
        if (
            !isFeaturedBundlesLoading &&
            !isFeaturedLoading &&
            featuredBundles &&
            featured
        ) {
            setFeaturedMasterList([...featuredBundles, ...featured.results]);
        }
    }, [
        featuredBundles,
        featured,
        isFeaturedBundlesLoading,
        isFeaturedLoading,
    ]);

    featuredMasterList.sort(function (a, b) {
        if (a.featured_priority < b.featured_priority) {
            return 1;
        }
        if (a.featured_priority > b.featured_priority) {
            return -1;
        }
        return 0;
    });

    return (
        <>
            <Helmet>
                <title>Home | MegaMinds</title>
                <meta
                    name="description"
                    content="MegaMinds meets students where they are and engages and inspires across the curriculum with 3D learning environments they can’t get enough of.
"
                />
            </Helmet>
            <Container
                fluid
                className="home-gradient p-2 p-md-3 pt-4 pb-4 border-bottom"
            >
                <Container className="p-3 p-md-5">
                    <Row className="pt-2 pb-5 p-3">
                        <Col xs={12} className="p-0">
                            <Row className="p-0">
                                <Col xs={12} md={6} className="">
                                    <h1 className="fw-bold text-purple-dark">
                                        Unlock Student-Centered Learning in Your
                                        Classrooms
                                    </h1>
                                    <p className="mt-4 mb-4">
                                        <strong>
                                            MegaMinds meets students where they
                                            are and engages and inspires across
                                            the curriculum with 3D learning
                                            environments they can’t get enough
                                            of.
                                        </strong>
                                    </p>
                                    <Button
                                        onClick={() =>
                                            loginWithRedirect({
                                                authorizationParams: {
                                                    screen_hint: "signup",
                                                },
                                            })
                                        }
                                        size="lg"
                                        variant="success"
                                        className="w-xs-100"
                                    >
                                        Sign Up for Free
                                    </Button>
                                    <Button
                                        variant="link"
                                        className="p-0 mt-4 text-start"
                                        href="https://gomegaminds.com"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Discover what megaminds can do for your
                                        school or district
                                    </Button>
                                </Col>
                                <Col xs={12} md={6} className="mt-5">
                                    <div className="embed-responsive embed-responsive-16by9 h-100">
                                        <iframe
                                            frameborder="0"
                                            className="embed-responsive-item"
                                            allowfullscreen="1"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                                            title="MegaMinds Demo"
                                            width="100%"
                                            height="100%"
                                            src="https://www.youtube.com/embed/lB57Ub8u5uY?autoplay=1&loop=1&amp;mute=1&amp;controls=0&amp;origin=https%3A%2F%2Fgomegaminds.com&amp;playsinline=1&amp;showinfo=0&amp;rel=0&amp;iv_load_policy=3&amp;modestbranding=0&amp;enablejsapi=1&amp;widgetid=1"
                                            id="widget2"
                                            data-gtm-yt-inspected-6="true"
                                        ></iframe>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="p-3 p-md-5 pt-5 pb-5 border-bottom">
                <h3 className="mb-3">Featured Content</h3>
                <Row className="g-3">
                    {featuredMasterList &&
                        featuredMasterList.map((listing) => (
                            <TemplateCard
                                template={listing}
                                bundle={!!listing.bundleattachment_set}
                            />
                        ))}
                </Row>
                <Row className="mt-4 g-4">
                    <Col xs={12} md={6} className="text-center">
                        <Card className="h-100">
                            <Card.Body className="d-flex justify-content-center align-items-center flex-column pt-5 pb-5">
                                <h2>Students Create</h2>
                                <p className="">
                                    Students build their own virtual 3D spaces with
                                    our easy and powerful 3D editing tools.
                                </p>
                                <Button
                                    onClick={() =>
                                        loginWithRedirect({
                                            authorizationParams: {
                                                screen_hint: "signup",
                                            },
                                        })
                                    }
                                    size="lg"
                                    variant="purple-dark"
                                >
                                    Get Started
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} className="text-center">
                        <Card className="h-100">
                            <Card.Body
                                style={{
                                    backgroundImage: 'url("' + roomImg + '")',
                                    backgroundPosition: "center",
                                    backgroundSize: "cover",
                                }}
                                className="d-flex justify-content-center align-items-center flex-column pt-5 pb-5 text-white"
                            >
                                <h2>Browse</h2>
                                <p className="">
                                    Browse user created and partner content and quickly
                                    supercharge your student engagement.
                                </p>
                                <Button
                                    as={Link}
                                    size="lg"
                                    to={"/explore"}
                                    variant="purple-dark"
                                >
                                    Explore
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-1 mb-5 g-4">
                    <Col xs={12} md={6} className="">
                        <h2 className="mb-4 text-center">Benefits</h2>
                        <ListGroup as="ol">
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="me-auto">
                                    <div className="fw-bold">
                                        Develop Higher Order Thinking Skills
                                    </div>
                                    MegaMinds' virtual learning environments
                                    enhance creativity, critical thinking,
                                    problem-solving, and collaboration skills.
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="me-auto">
                                    <div className="fw-bold">
                                        Develop 21st Century Skills
                                    </div>
                                    Equip students with digital literacy skills
                                    that promote active engagement and practical
                                    technology use.
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="me-auto">
                                    <div className="fw-bold">
                                        Content Integration
                                    </div>
                                    MegaMinds seamlessly integrates with your
                                    existing content, resources and LMS.
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="me-auto">
                                    <div className="fw-bold">
                                        Ease of Access
                                    </div>
                                    MegaMinds run in the browser on any device.
                                    That means no downloads, just share a link
                                    to your Lesson.
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item
                                as="li"
                                className="d-flex justify-content-between align-items-start"
                            >
                                <div className="me-auto">
                                    <div className="fw-bold">
                                        Safe and Secure
                                    </div>
                                    Maintain complete control and utilize our
                                    extensive security features to customize
                                    your lessons to suit your needs
                                </div>
                            </ListGroup.Item>
                            <ListGroup.Item className="d-flex justify-content-between align-items-start">
                                <Button
                                    className="text-start p-0 m-0 "
                                    variant="link"
                                    href="https://gomegaminds.com/"
                                >
                                    See all the benefits and features of
                                    MegaMinds on our information page
                                </Button>
                            </ListGroup.Item>
                        </ListGroup>
                    </Col>
                    <Col xs={12} md={6} className="">
                        <h2 className="mb-4 text-center">FAQ</h2>
                        <Accordion className="w-100">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>
                                    What does it cost?
                                </Accordion.Header>
                                <Accordion.Body>
                                    It’s our commitment to keep a free version
                                    of MegaMinds for teachers. We also offer
                                    fully featured Premium licenses and
                                    Site-wide licenses for schools and
                                    districts.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>
                                    What devices can run MegaMinds?
                                </Accordion.Header>
                                <Accordion.Body>
                                    You can access MegaMinds with any device!
                                    And because it is browser-based, there’s
                                    nothing to download. <br />
                                    Simply share a link and your students can
                                    access it right away!
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>
                                    Will my students know how to use this?
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Students thrive in our 3D environments.
                                        Watch their smiles brighten and eyes
                                        light up as they transform into avatars
                                        in our immersive worlds.
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>
                                    What content can I share?
                                </Accordion.Header>
                                <Accordion.Body>
                                    Almost anything! From static content like
                                    photos and PDFs, to YouTube videos, to GIFs
                                    and 3D objects. You can even add assessment
                                    questions to gamify your quizzes.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="4">
                                <Accordion.Header>
                                    How do students join a lesson or activity?
                                </Accordion.Header>
                                <Accordion.Body>
                                    After you've created your virtual Lesson or
                                    project assignment, simply share out the
                                    link via email, Google Classroom, or
                                    any LMS you normally use. It’s that easy.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="5">
                                <Accordion.Header>
                                    What happens to the content after the
                                    students are finished with it?
                                </Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Our virtual Lessons are persistent --after
                                        a Lesson is created it exists until you
                                        delete it. Meaning, it also becomes an
                                        artifact of your student’s learning
                                        journey.
                                    </p>
                                    <p>
                                        And because everything is link based, it
                                        means you can easily share them out with
                                        parents, administrators, other teachers,
                                        and students. They can become digital
                                        portfolios of student work that can even
                                        be used for college or job applications.
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>
                </Row>
            </Container>
            <Container fluid className="home-gradient pt-5 pb-5">
                <Row className="text-center d-flex justify-content-center">
                    <Col
                        xs={12}
                        md={12}
                        className=""
                        style={{ maxWidth: "600px" }}
                    >
                        <h1 className="fw-bold text-purple-dark">
                            Sign up today
                        </h1>
                        <p className="mt-4 mb-4">
                            <strong>
                                No payments, popups, or cards required. Get
                                access to the dashboard instantly.
                            </strong>
                        </p>
                        <p className="mt-4 mb-4">
                            Our Basic plans lets you enjoy 3 Lessons, 3 Student
                            Projects and all the functionality you need to
                            supercharge your lessons.
                        </p>
                        <Button
                            onClick={() =>
                                loginWithRedirect({
                                    authorizationParams: {
                                        screen_hint: "signup",
                                    },
                                })
                            }
                            size="lg"
                            variant="success"
                            className="w-xs-100"
                        >
                            Create Account
                        </Button>
                        <Button
                            variant="link"
                            className="p-0 mt-4 text-center"
                            href="https://gomegaminds.com"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Learn more on Gomegaminds.com
                        </Button>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default HomeGuest;
