import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ReactGA from "react-ga4";
import { useAuth } from "../../../context/AuthContext";
import { cloneSingleTemplate } from "../../../utils/create-room";
import LoginRequiredModal from "./LoginRequiredModal";
import { useAuth0 } from "@auth0/auth0-react";
import useRoomAPI from "../../../services/useRoomAPI";

function CreateRoomModal(props) {
    const auth = useAuth();
    const navigate = useNavigate();

    const [rooms, isLoadingRooms, roomsError, refreshRooms] = useRoomAPI(
        1,
        "",
        "",
        auth
    );

    const restricted =
        auth.profile?.subscription_plan === "Free Plan" &&
        rooms?.results?.length === 3;

    console.log(restricted);

    const { getAccessTokenSilently } = useAuth0();

    const [isPosting, setIsPosting] = useState(false);
    const [showFinalMenu, setShowFinalMenu] = useState(false);

    const [resp, setResp] = useState(null);

    const cloneTemplate = () => {
        setIsPosting(true);
        ReactGA.event({
            category: "classrooms",
            action: "clicked_make_copy",
            label: "User clicked Make Copy / Clone Room button.", // optional
        });

        const prom = new Promise((complete, reject) => {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                scope: "create:classroom",
            }).then((token) => {
                cloneSingleTemplate(
                    props.template.id,
                    token,
                    props.template.title,
                    props.template.description,
                    false,
                    false,
                    props.remix
                )
                    .then((resp) => {
                        setResp(resp);
                        complete();
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        });

        toast.promise(prom, {
            loading: "Creating Lesson...",
            success: "Successfully created Lesson",
            error: "Could not create Lesson at this time.",
        });

        prom.then(() => {
            setShowFinalMenu(true);
            // props.onFinished();
        });
    };

    if (!props.template) {
        return null;
    }

    const redirectToClassroomList = () => {
        navigate("/lessons");
    };

    const redirectToClassroom = () => {
        window.open(
            process.env.REACT_APP_3D_ENDPOINT + "/" + resp.hub_id + "/",
            "_blank"
        );
    };

    const redirectToClassroomListAndPopUpAssignmentModal = () => {
        if (resp) {
            navigate("/lessons", {
                state: {
                    showAssignmentModal: true,
                    selectedClassroom: resp.id,
                },
            });
        } else {
            navigate("/lessons", { state: { showAssignmentModal: false } });
        }
    };

    if (!auth.profile) {
        return (
            <LoginRequiredModal
                show={props.show}
                onHide={() => props.setShowModal(false)}
            />
        );
    }

    if (restricted) {
        return (
            <Modal
                show={props.show}
                centered
                size="lg"
                onHide={() => props.setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Limit reached</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-4 g-4">
                        <Col xs={12} md={12}>
                            <Row>
                                <Col>
                                    <p>
                                        You have reached the limit of 3 Lessons
                                        on MegaMinds Free tier. Upgrade to
                                        MegaMinds Premium to unlock unlimited
                                        lessons and more!
                                    </p>
                                    <Button
                                        as={Link}
                                        to="/buy"
                                        variant="success"
                                        className="me-2"
                                    >
                                        See Plans & Pricing
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={() =>
                                            props.setShowModal(false)
                                        }
                                    >
                                        Close
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <>
            <Modal
                show={props.show}
                size="md"
                fullscreen="md-down"
                centered
                onHide={() => props.setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Add to My Lessons</Modal.Title>
                </Modal.Header>
                {showFinalMenu ? (
                    <Modal.Body>
                        <Row className="mb-4 mt-4">
                            <Col>
                                <Alert variant="success">
                                    <p className="mb-0">
                                        A new Lesson was added to your{" "}
                                        <Link to="/lessons">Lessons</Link> page.
                                    </p>
                                </Alert>
                            </Col>
                        </Row>
                        <Row className="mb-4 g-4">
                            <Col xs={12} md={6}>
                                <Card
                                    className="h-100 border-on-hover"
                                    onClick={() => redirectToClassroom()}
                                >
                                    <Card.Body className="d-flex flex-column justify-content-center align-items-center text-center">
                                        <Row>
                                            <Col>
                                                <p>
                                                    <i
                                                        style={{
                                                            fontSize: "2rem",
                                                        }}
                                                        className="bi bi-door-open text-purple-dark"
                                                    ></i>
                                                </p>
                                                <h5 className="">Open</h5>
                                                <p className="text-muted">
                                                    <small>
                                                        Open the Lesson, invite
                                                        others, add or edit
                                                        content, or just explore
                                                        at your own pace.
                                                    </small>
                                                </p>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                            <Col xs={12} md={6}>
                                <Card
                                    onClick={() => props.setShowModal(false)}
                                    className="h-100 border-on-hover"
                                >
                                    <Card.Body className="d-flex flex-column justify-content-center align-items-center text-center">
                                        <Row>
                                            <Col>
                                                <p>
                                                    <i
                                                        style={{
                                                            fontSize: "2rem",
                                                        }}
                                                        className="bi bi-arrow-return-left text-purple-dark"
                                                    ></i>
                                                </p>
                                                <h5 className="">
                                                    Close and Keep Exploring
                                                </h5>
                                                <p className="fw-light">
                                                    <small className="">
                                                        You will find the Lesson
                                                        waiting on the Lessons
                                                        page.
                                                    </small>
                                                </p>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Modal.Body>
                ) : (
                    <Modal.Body>
                        {props.remix ? (
                            <p className="mb-0">
                                This will add a copy of{" "}
                                <span className="fw-bold">
                                    {props.template.title}
                                </span>{" "}
                                to your Lessons page so that you can edit it
                                before using it as a Student Project.
                            </p>
                        ) : (
                            <p className="mb-0">
                                This will add a copy of{" "}
                                <span className="fw-bold">
                                    {props.template.title}
                                </span>{" "}
                                to your Lessons page.
                            </p>
                        )}
                    </Modal.Body>
                )}
                {!showFinalMenu && (
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => props.setShowModal(false)}
                        >
                            Close
                        </Button>
                        <Button
                            variant="success"
                            disabled={isPosting ? true : false}
                            onClick={() => cloneTemplate()}
                        >
                            {isPosting ? (
                                <>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <span>Loading...</span>
                                </>
                            ) : (
                                <span>
                                    <i className="bi bi-plus-lg me-2"></i>Add to
                                    Lessons
                                </span>
                            )}
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
}

export default CreateRoomModal;
