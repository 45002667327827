import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import moment from "moment";
import "moment-timezone";
import { useAuth0 } from "@auth0/auth0-react";
import toast from "react-hot-toast";
import * as XLSX from "xlsx";

function Answer({ answer, grade, timestamp, id, refresh }) {
    const [showGrading, setShowGrading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const [newGrade, setNewGrade] = useState(grade);

    const saveGrade = (answer, type) => {
        // Fetch patch to object by id
        const payload = {
            answer_type:
                typeof answer === "object" ? "multiplechoice" : "freetext",
            answer_id: id,
            grade: parseInt(newGrade),
        };

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            fetch(process.env.REACT_APP_MM_API_URL + "assessments/grade/", {
                method: "PATCH",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                    refresh();
                    setShowGrading(false);
                    setNewGrade(data.grade);
                    toast.success("Changes were saved.");
                })
                .catch((error) => {
                    toast.error("Something went wrong while saving.");
                    console.error(error);
                    refresh();
                });
        });
    };

    if (typeof answer === "object") {
        // Its multiple choice
        return (
            <Row>
                <Col>
                    <p
                        className={
                            answer.correct
                                ? "text-success mb-2"
                                : "mb-2 text-danger"
                        }
                    >
                        {answer.answer}
                    </p>
                </Col>
                <Col>
                    <p>
                        {answer.correct ? (
                            <span className="float-end fw-light">
                                <small>100%</small>
                                <i className="bi text-success bi-check"></i>
                            </span>
                        ) : (
                            <span className="float-end fw-light">
                                <small>0%</small>
                                <i className="bi text-danger bi-x"></i>
                            </span>
                        )}
                    </p>
                </Col>
            </Row>
        );
    }

    return (
        <p className={answer.correct ? "text-success mb-2" : "mb-2 text-dark"}>
            {answer}
            <>
                {showGrading ? (
                    <>
                        <InputGroup className="w-50 float-end">
                            <input
                                type="number"
                                value={newGrade}
                                onChange={(e) => setNewGrade(e.target.value)}
                                placeholder="0-100"
                                className="float-end form-control"
                                min="0"
                                max="100"
                            />
                            <Button
                                variant="success"
                                disabled={newGrade > 100 || newGrade < 0}
                                onClick={() => saveGrade(answer.id)}
                                className="float-end fw-light"
                            >
                                Save
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => setShowGrading(false)}
                                className="float-end fw-light"
                            >
                                Cancel
                            </Button>
                        </InputGroup>
                    </>
                ) : (
                    <Button
                        variant="link"
                        onClick={() => setShowGrading(true)}
                        className="float-end text-primary fw-light p-0"
                    >
                        <small>
                            {newGrade !== null
                                ? newGrade + "%"
                                : "Needs Grading"}
                        </small>
                    </Button>
                )}
            </>
        </p>
    );
}

function getGrade(submissions) {
    const values = Object.values(submissions);
    let needsGrading = false;

    const sum = values.reduce((accumulator, value) => {
        if (typeof value.answer === "object") {
            if (value.answer.correct) {
                return accumulator + 100;
            } else {
                return accumulator + 0;
            }
        } else {
            if (value.grade === null) {
                needsGrading = true;
            }
            return accumulator + value.grade;
        }
    }, 0);

    if (needsGrading) return "Needs grading";

    return sum / values.length + "%";
}

function StudentModal({
    showModal,
    setShowModal,
    selectedStudent,
    refreshInfo,
}) {
    const [count, setCount] = useState(0);

    if (!selectedStudent) return null;

    const refresh = () => {
        setCount(count + 1);
        refreshInfo();
    };

    return (
        <Modal
            show={showModal}
            size="lg"
            scrollable
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Student Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{selectedStudent.name}</h4>
                <p>
                    Student has submitted{" "}
                    <span className="fw-bold">
                        {selectedStudent.data.length}
                    </span>{" "}
                    answers.
                </p>
                <hr />
                <ListGroup>
                    {selectedStudent.data.map((submission) => (
                        <ListGroup.Item key={submission.timestamp}>
                            <Row>
                                <Col>
                                    <p>
                                        {submission.question.question}
                                        <small className="float-end">
                                            {moment(
                                                submission.timestamp
                                            ).format("MMMM Do HH:ss")}
                                        </small>
                                    </p>
                                </Col>
                            </Row>
                            <Answer
                                refresh={refresh}
                                id={submission.id}
                                answer={submission.answer}
                                grade={submission.grade}
                                timestamp={submission.timestamp}
                            />
                        </ListGroup.Item>
                    ))}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
export default function Students({ roomData, refreshInfo }) {
    const [showModal, setShowModal] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState(null);

    const students = Object.entries(roomData.students).map((e) => ({
        name: e[0],
        data: e[1],
    }));

    const totalMCQ = roomData.mcqbundle_set.reduce(
        (count, current) => count + current.mcqquestion_set.length,
        0
    );

    const totalFreetext = roomData.freetextbundle_set.reduce(
        (count, current) => count + current.freetextquestion_set.length,
        0
    );

    const totalQuestions = totalMCQ + totalFreetext;

    const refresh = () => {
        refreshInfo();
    };

    const generateCSV = () => {
        const data = [];

        console.log(students);

        students.forEach((student) => {
            data.push({
                Name: student.name,
                Participation: (student.data.length / totalQuestions) * 100,
                Questions: student.data.length + "/" + totalQuestions,
                Grade: getGrade(student.data),
            });
        });

        let exportData = XLSX.utils.json_to_sheet(data);

        // Create a new Workbook
        var wb = XLSX.utils.book_new();
        //
        //     // Name your sheet
        if (roomData.title.length <= 15) {
            XLSX.utils.book_append_sheet(
                wb,
                exportData,
                "Assessments for " + roomData.title
            );
            XLSX.writeFile(wb, roomData.title + "_assessments.xlsx");
        } else {
            XLSX.utils.book_append_sheet(
                wb,
                exportData,
                "Assessments for Your Lesson"
            );
            XLSX.writeFile(wb, roomData.title + "_assessments.xlsx");
        }
    };

    return (
        <>
            <StudentModal
                showModal={showModal}
                refreshInfo={refresh}
                setShowModal={setShowModal}
                selectedStudent={selectedStudent}
            />
            <Row>
                <Col>
                    <p>
                        This Lesson has{" "}
                        <span className="fw-bold">{totalQuestions}</span>{" "}
                        assessment items.
                        <br />A total of{" "}
                        <span className="fw-bold">{students.length}</span>{" "}
                        students has submitted answers.
                    </p>
                </Col>
                <Col>
                    <Button
                        className="float-end"
                        variant="purple-dark"
                        onClick={() => generateCSV()}
                    >
                        <i className="bi bi-download me-2"></i>Export
                    </Button>
                </Col>
            </Row>
            <Row>
                <Table striped bordered hover>
                    <thead>
                        <tr>
                            <th>Name</th>
                            <th>Total Participation</th>
                            <th>Questions</th>
                            <th>Grade Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {students.map((student, index) => {
                            const totalGrade = getGrade(student.data);

                            return (
                                <tr
                                    key={index}
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        setShowModal(true);
                                        setSelectedStudent(student);
                                    }}
                                >
                                    <td>
                                        <span className="fw-bold">
                                            {student.name}
                                        </span>
                                    </td>
                                    <td className="fw-bold">
                                        {(student.data.length /
                                            totalQuestions) *
                                            100}
                                        %
                                    </td>
                                    <td>
                                        <Badge bg="purple-dark" pill>
                                            {student.data.length} of{" "}
                                            {totalQuestions}
                                        </Badge>
                                    </td>
                                    <td className="">
                                        {totalGrade === "Needs grading" ? (
                                            <span className="fw-bold badge bg-warning text-dark">
                                                Needs Grading
                                            </span>
                                        ) : (
                                            totalGrade
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </Table>
            </Row>
        </>
    );
}
