import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { getPicture } from "../../../utils/get-picture";
import { Link } from "react-router-dom";

function toLowerCase(string) {
    return string.replace(/\s+/g, "-").toLowerCase();
}

function TemplateCard({ template, horizontal, bundle, home }) {
    console.log("Bundle", bundle, template);
    return (
        <Col
            key={template.id}
            xs={horizontal ? 12 : home ? 6 : 6}
            sm={horizontal ? 12 : home ? 6 : 6}
            md={horizontal ? 12 : home ? 4 : 4}
            lg={horizontal ? 12 : home ? 3 : 4}
        >
            <Button
                variant="empty"
                className="m-0 p-0 text-start w-100 h-100"
                as={Link}
                to={
                    bundle
                        ? "/collections/" + template.id
                        : "/explore/" +
                          template.id +
                          "/" +
                          toLowerCase(template.title)
                }
            >
                <Card className="hover-shadow h-100">
                    <Card.Img
                        className={horizontal ? "dim rounded-0" : "dim"}
                        height="100px"
                        variant="top"
                        src={getPicture(template.image)}
                    />
                    <Card.ImgOverlay></Card.ImgOverlay>
                    <Card.Body className="d-flex p-0">
                        <Row className="p-0 m-0 w-100 p-2 pt-4 pb-4">
                            <Col xs={12}>
                                <Card.Title className="text-dark mb-3">
                                    {template.title}
                                </Card.Title>
                            </Col>
                            <Col
                                xs={12}
                                className="d-flex justify-content-between align-items-end"
                            >
                                <Card.Text className="text-secondary fw-light mb-0">
                                    {bundle ? (
                                        <small>Bundle</small>
                                    ) : (
                                        <small>
                                            {template.assignment_mode
                                                ? "Student Project"
                                                : "Lesson"}
                                        </small>
                                    )}
                                </Card.Text>
                                <img
                                    alt="Profile picture of the creator of this listing"
                                    className="rounded-circle"
                                    style={{
                                        maxHeight: "20px",
                                    }}
                                    src={getPicture(
                                        bundle
                                            ? template.image
                                            : template.creator.image
                                    )}
                                />
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Button>
        </Col>
    );
}

export default TemplateCard;
