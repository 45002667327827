import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import ReactGA from "react-ga4";
import { useAuth } from "../../context/AuthContext";
import { cloneSingleTemplate } from "../../utils/create-room";
import LoginRequiredModal from "./modals/LoginRequiredModal";

import { useAuth0 } from "@auth0/auth0-react";
import useRoomAPI from "../../services/useRoomAPI";
import useAssignmentAPI from "../../services/useAssignmentAPI";

function SelectorMenu({
    roomsLeft,
    studentProjectsLeft,
    normalRooms,
    studentProjectRooms,
    bundle,
    freeTier,
    guides,
    showModal,
    setShowModal,
    exclusive,
    getAccessTokenSilently,
    setShowFinalMenu,
    selectedRooms,
    setSelectedRooms,
    selectedStudentProjects,
    setSelectedStudentProjects,
}) {
    const [isPosting, setIsPosting] = useState(false);

    const handleCheckboxChange = (template) => {
        const isChecked = selectedRooms.includes(template);
        const newSelectedRooms = isChecked
            ? selectedRooms.filter(
                  (oldTemplate) => oldTemplate.id !== template.id
              )
            : [...selectedRooms, template];

        // Check if the count exceeds the limit
        if (freeTier && newSelectedRooms.length > roomsLeft) {
            toast.error("You have reached the limit of Lessons.");
            return;
        }

        setSelectedRooms(newSelectedRooms);
    };

    const handleStudentProjectCheckboxChange = (template) => {
        const isChecked = selectedStudentProjects.includes(template);
        const newSelectedStudentProjects = isChecked
            ? selectedStudentProjects.filter(
                  (oldTemplate) => oldTemplate.id !== template.id
              )
            : [...selectedStudentProjects, template];

        // Check if the count exceeds the limit
        if (freeTier && newSelectedStudentProjects.length > studentProjectsLeft) {
            toast.error("You have reached the limit of Student Projects.");
            return;
        }

        setSelectedStudentProjects(newSelectedStudentProjects);
    };

    const disableStudentRooms = freeTier && studentProjectsLeft === 0;
    const disableRooms = freeTier && roomsLeft === 0;

    const cloneSelectedTemplates = () => {
        setIsPosting(true);

        ReactGA.event({
            category: "bundles",
            action: "clicked_make_multiple",
            label: "User clicked Make Copy / Clone Room button.", // optional
        });

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "create:classroom",
        }).then((token) => {
            const allRooms = [...selectedRooms, ...selectedStudentProjects];

            allRooms.forEach((template, i) => {
                console.log("Adding template", template);
                setTimeout(() => {
                    cloneSingleTemplate(
                        template.id,
                        token,
                        template.title,
                        template.description,
                        false,
                        false
                    )
                        .then((resp) => {
                            // If its the last one, we show a new menu
                            if (i === allRooms.length - 1) {
                                setShowFinalMenu(true);
                            }
                        })
                        .catch((e) => {});
                }, 1500 * i);
            });
        });

        // For each template, add to room
    };

    return (
        <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Which experiences would you like to add?
                </Modal.Title>
            </Modal.Header>
            {normalRooms.length > 0 && (
                <Modal.Body>
                    <h5>Lessons</h5>
                    {freeTier &&
                        roomsLeft !== 0 &&
                        roomsLeft < normalRooms.length && (
                            <Alert variant="warning" className="p-2">
                                <p className="mb-0">
                                    You can add{" "}
                                    <span className="fw-bold">{roomsLeft}</span>{" "}
                                    more rooms to your account.{" "}
                                    <Link
                                        to="/buy"
                                        size="sm"
                                        variant="link"
                                        className="p-0 m-0 float-end"
                                    >
                                        Upgrade to add unlimited Lessons.
                                    </Link>
                                </p>
                            </Alert>
                        )}
                    {freeTier && roomsLeft === 0 && (
                        <Alert variant="warning" className="p-2">
                            <Button
                                as={Link}
                                size="sm"
                                to="/buy"
                                variant="link"
                                className="float-end p-0"
                            >
                                Upgrade Plan
                            </Button>
                            <p className="mb-0">
                                You have reached the limit of 3 Lessons.
                            </p>
                        </Alert>
                    )}

                    <ListGroup>
                        {normalRooms.map((template) => {
                            return (
                                <ListGroup.Item>
                                    <div className="float-end">
                                        <Form.Check
                                            label="Select"
                                            disabled={freeTier && roomsLeft === 0}
                                            onChange={() =>
                                                handleCheckboxChange(template)
                                            }
                                            checked={selectedRooms.includes(
                                                template
                                            )}
                                        />
                                    </div>
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">
                                            {template.title}
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                </Modal.Body>
            )}
            {studentProjectRooms.length > 0 && (
                <Modal.Body>
                    <h5>Student Projects</h5>
                    {freeTier && disableStudentRooms && (
                        <Alert variant="warning" className="p-2">
                            <Button
                                as={Link}
                                size="sm"
                                to="/buy"
                                variant="link"
                                className="float-end p-0"
                            >
                                Upgrade Plan
                            </Button>
                            <p className="mb-0">
                                You have reached the limit of 3 Student Lessons.
                            </p>
                        </Alert>
                    )}
                    {freeTier &&
                        studentProjectsLeft !== 0 &&
                        studentProjectsLeft < studentProjectRooms.length && (
                            <Alert variant="warning" className="p-2">
                                <p className="">
                                    You can add{" "}
                                    <span className="fw-bold">
                                        {studentProjectsLeft}
                                    </span>{" "}
                                    more Student Projects.
                                </p>
                                <Link
                                    to="/buy"
                                    size="sm"
                                    variant="link"
                                    className="p-0 m-0"
                                >
                                    Upgrade plan
                                </Link>
                            </Alert>
                        )}
                    <ListGroup>
                        {studentProjectRooms.map((template) => {
                            return (
                                <ListGroup.Item>
                                    <div className="float-end">
                                        <Form.Check
                                            disabled={disableStudentRooms}
                                            label="Select"
                                            onChange={() =>
                                                handleStudentProjectCheckboxChange(
                                                    template
                                                )
                                            }
                                            checked={selectedStudentProjects.includes(
                                                template
                                            )}
                                        />
                                    </div>
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">
                                            {template.title}
                                        </div>
                                    </div>
                                </ListGroup.Item>
                            );
                        })}
                    </ListGroup>
                </Modal.Body>
            )}
            <Modal.Footer>
                <Button
                    variant="secondary"
                    onClick={() => setShowModal(false)}
                    disabled={isPosting}
                >
                    Cancel
                </Button>
                <Button
                    variant="success"
                    disabled={
                        isPosting ||
                        (selectedRooms.length === 0 &&
                            selectedStudentProjects.length === 0)
                    }
                    onClick={() => cloneSelectedTemplates()}
                >
                    {isPosting ? (
                        <>
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span>Loading...</span>
                        </>
                    ) : (
                        <span>
                            <i className="bi bi-plus me-2"></i>
                            Add to My Account
                        </span>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function UpgradeModal({ setShowModal, showModal }) {
    return (
        <Modal show={showModal} size="lg" onHide={() => setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Limit Reached</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="warning">
                    <p className="">
                        You have reached the limit on your MegaMinds free tier.
                    </p>
                    <Button as={Link} to="/buy" variant="success" className="">
                        Upgrade for unlimited Lessons and Student Projects
                    </Button>
                    <Button
                        onClick={() => setShowModal(false)}
                        variant="secondary"
                        className="ms-2"
                    >
                        Close
                    </Button>
                </Alert>
            </Modal.Body>
        </Modal>
    );
}

function FinishedPage(props) {
    return (
        <Modal show={true} size="lg" onHide={() => props.setShowModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Added{" "}
                    {props.selectedRooms.length +
                        props.selectedStudentProjects.length}{" "}
                    {props.selectedRooms.length +
                        props.selectedStudentProjects.length >
                    1
                        ? "experiences"
                        : "experience"}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-4 mt-4">
                    <Col>
                        <Alert variant="success">
                            <p className="mb-0">🎉 Success!</p>
                        </Alert>
                        {props.selectedRooms.length > 0 && (
                            <p>
                                {props.selectedRooms.length} Lessons were added to
                                your <Link to="/lessons">Lessons Page</Link>
                            </p>
                        )}
                        {props.selectedStudentProjects.length > 0 && (
                            <p>
                                {props.selectedStudentProjects.length} Student
                                Projects were added to your{" "}
                                <Link to="/student-projects">Student Projects page</Link>
                            </p>
                        )}
                    </Col>
                </Row>
                <Row className="mb-4 g-0">
                    <Col xs={12} md={12}>
                        {props.selectedRooms.length > 0 && (
                            <Button
                                as={Link}
                                to="/lessons"
                                className="mb-4 me-2 mt-3"
                                variant="success"
                            >
                                Open Lessons Page
                            </Button>
                        )}
                        {props.selectedStudentProjects.length > 0 && (
                            <Button
                                as={Link}
                                to="/student-projects"
                                className="mb-4 me-2 mt-3"
                                variant="success"
                            >
                                Open Student Projects Page
                            </Button>
                        )}

                        <Button
                            onClick={() => {
                                props.setShowFinalMenu(false);
                                props.setShowModal(false);
                            }}
                            variant="secondary"
                            className="mb-4 mt-3"
                        >
                            Close and keep exploring
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
        </Modal>
    );
}

function CreateFromBundleModal(props) {
    console.log(props);
    const auth = useAuth();

    const { getAccessTokenSilently } = useAuth0();

    const [isPosting, setIsPosting] = useState(false);
    const [showFinalMenu, setShowFinalMenu] = useState(false);

    const [selectedRooms, setSelectedRooms] = useState([]);
    const [selectedStudentProjects, setSelectedStudentProjects] = useState([]);

    const [rooms, isLoadingRooms, roomsError, refreshRooms] = useRoomAPI(
        1,
        "",
        "",
        auth
    );

    const [
        assignments,
        isLoadingAssignments,
        assignmentError,
        refreshAssignments,
    ] = useAssignmentAPI(1, "", "", auth);

    const freeTier = auth.profile?.subscription_plan === "Free Plan";

    const roomsLeft = 3 - rooms?.results?.length;
    const studentProjectsLeft = 3 - assignments?.results?.length;

    const normalRooms = props.bundle.template_set.filter(
        (template) => template.assignment_mode === false
    );

    const studentProjectRooms = props.bundle.template_set.filter(
        (template) => template.assignment_mode === true
    );

    const cloneMultipleTemplates = () => {
        setIsPosting(true);

        ReactGA.event({
            category: "bundles",
            action: "clicked_make_multiple",
            label: "User clicked Make Copy / Clone Room button.", // optional
        });

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "create:classroom",
        }).then((token) => {
            props.bundle.template_set.forEach((template, i) => {
                setTimeout(() => {
                    cloneSingleTemplate(
                        template.id,
                        token,
                        template.title,
                        template.desc,
                        false,
                        false
                    )
                        .then((resp) => {
                            toast.success(template.title + " completed.");
                            // If its the last one, we show a new menu
                            if (i === props.bundle.template_set.length - 1) {
                                setShowFinalMenu(true);
                            }
                        })
                        .catch((e) => {});
                }, 1500 * i);
            });
        });

        // For each template, add to room
    };

    if (!props.bundle) {
        return null;
    }

    const redirectToClassroomList = () => {
        window.location.replace("/rooms");
    };

    if (!auth.profile) {
        return (
            <LoginRequiredModal
                show={props.show}
                onHide={() => props.setShowModal(false)}
            />
        );
    }

    if (showFinalMenu) {
        return (
            <FinishedPage
                setShowModal={props.setShowModal}
                setShowFinalMenu={setShowFinalMenu}
                selectedRooms={selectedRooms}
                selectedStudentProjects={selectedStudentProjects}
            />
        );
    }

    console.log(roomsLeft > props.bundle.template_set.length);

    if (
        freeTier &&
        normalRooms.length > 0 &&
        roomsLeft == 0 &&
        studentProjectRooms.length > 0 &&
        studentProjectsLeft == 0
    ) {
        return (
            <UpgradeModal
                showModal={props.show}
                setShowModal={props.setShowModal}
            />
        );
    }

    return (
        <SelectorMenu
            freeTier={freeTier}
            bundle={props.bundle}
            guides={props.guides}
            roomsLeft={roomsLeft}
            studentProjectsLeft={studentProjectsLeft}
            normalRooms={normalRooms}
            studentProjectRooms={studentProjectRooms}
            exclusive={props.exclusive}
            setShowModal={props.setShowModal}
            setShowFinalMenu={setShowFinalMenu}
            showModal={props.show}
            getAccessTokenSilently={getAccessTokenSilently}
            selectedRooms={selectedRooms}
            setSelectedRooms={setSelectedRooms}
            selectedStudentProjects={selectedStudentProjects}
            setSelectedStudentProjects={setSelectedStudentProjects}
        />
    );
}

export default CreateFromBundleModal;
