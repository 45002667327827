import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";

import useAdminStats from "../services/useAdminStats";

import { useAuth0 } from "@auth0/auth0-react";

const useAdminAPI = () => {
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0();
    const [rooms, setRooms] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(0);

    const refresh = () => {
        setRefreshIndex(refreshIndex + 1);
    };

    useEffect(() => {
        setLoading(true);
        if (isAuthenticated && !isLoading) {
            (async () => {
                try {
                    const token = await getAccessTokenSilently({
                        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                        scope: "read:classrooms",
                    });
                    const response = await fetch(
                        process.env.REACT_APP_MM_API_URL +
                            "admin/stats",
                            {
                                headers: {
                                    Authorization: "Bearer " + token,
                                },
                            }
                    );
                    setRooms(await response.json());
                    setLoading(false);
                    setError(false);
                } catch (e) {
                    console.error(e);
                    setLoading(false);
                    setError(e);
                }
            })();
        } else {
            if (isLoading) {
                setLoading(true);
            } else {
                setLoading(false);
                setError("You must be logged in to view this page.");
            }
        }
    }, [getAccessTokenSilently, refreshIndex, isAuthenticated, isLoading]);

    return [rooms, loading, error, refresh];
};

export default useAdminAPI;
