import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useLocation } from "react-router-dom";
import logo from "../Logo.png";
import placeholderImage from "../placeholder-profile.png";

import { faBook } from "@fortawesome/free-solid-svg-icons";
import Row from "react-bootstrap/Row";
import Placeholder from "react-bootstrap/Placeholder";
import Col from "react-bootstrap/Col";
import { useAuth } from "../context/AuthContext";
import { getPicture } from "../utils/get-picture";

export function SidebarItem(props) {
    return (
        <li
            onClick={props.onClick}
            className={
                props.to && props.activePath === props.to
                    ? "hover-darken darken"
                    : "hover-darken"
            }
        >
            <Link
                to={props.to}
                className="text-decoration-none text-dark d-flex w-100 h-100 ps-4 pe-4 pt-3 pb-3 d-flex align-items-center"
            >
                <i
                    style={{ fontSize: "0.9rem" }}
                    className={"bi " + props.icon + " me-3 text-purple-dark"}
                ></i>
                {props.text}
            </Link>
        </li>
    );
}

export function DashSidebar(props) {
    const auth = useAuth();
    const location = useLocation();
    const { pathname } = location;

    const openLink = (url) => {
        window.open(url, "_blank");
    };

    return (
        <div
            style={{ width: "330px", listStyle: "none" }}
            className="vh-100 bg-white d-none d-sm-block border"
        >
            <Row className="pt-4 pb-4 border-bottom">
                <Col className="text-center">
                    <img
                        alt="MegaMinds Logo"
                        width={142}
                        height={80}
                        src={logo}
                    />
                </Col>
            </Row>
            <Link
                style={{ textDecoration: "none" }}
                className="text-dark hover-darken"
                to={"/profile"}
            >
                <Row className="p-3 border-bottom">
                    <Col
                        xs={3}
                        className="d-flex justify-content-middle align-items-center"
                    >
                        {auth.isProfileLoading && auth.isLoading ? (
                            <img
                                alt="User profile"
                                className="rounded-circle img-fluid"
                                src={placeholderImage}
                            />
                        ) : (
                            <>
                                {!auth.isProfileLoading &&
                                auth.profile?.image ? (
                                    <img
                                        alt="User profile"
                                        className="rounded-circle img-fluid"
                                        src={getPicture(auth.profile?.image)}
                                    />
                                ) : (
                                    <img
                                        alt="User profile"
                                        className="rounded-circle img-fluid"
                                        src={
                                            !auth.isLoading && auth.user.picture
                                        }
                                    />
                                )}
                            </>
                        )}
                    </Col>
                    <Col className="d-flex justify-content-middle flex-column">
                        {!auth.isProfileLoading && !auth.isAuthLoading ? (
                            <>
                                <p className="mb-0 fw-bold">
                                    {auth.profile?.first_name}
                                </p>
                                <small className="mb-0 fw-light">
                                    {auth.profile?.subscription_plan}
                                </small>
                            </>
                        ) : (
                            <>
                                <p className="mb-0 fw-bold">
                                    <Placeholder xs={2} />
                                </p>
                                <small className="mb-0 fw-light">
                                    <Placeholder xs={5} />
                                </small>
                            </>
                        )}
                    </Col>
                </Row>
            </Link>
            <div
                className="d-flex flex-column justify-content-between overflow-y-auto overflow-x-hidden"
                style={{ height: "calc(100% - 207px)" }}
            >
                <Row>
                    <Col>
                        <ul style={{ listStyle: "none" }} className="m-0 p-0">
                            <SidebarItem
                                to="/"
                                icon="bi-house-fill"
                                text="Home"
                                activePath={pathname}
                            />
                            <SidebarItem
                                to="/student-projects"
                                icon="bi-boxes"
                                text="Student Projects"
                                activePath={pathname}
                            />
                            <SidebarItem
                                to="/lessons"
                                icon="bi-easel-fill"
                                text="Lessons"
                                activePath={pathname}
                            />
                            <SidebarItem
                                to="/explore"
                                icon="bi-search"
                                text="Content Library"
                                activePath={pathname}
                            />
                            {auth.profile && auth.profile?.has_organization && (
                                <SidebarItem
                                    to="/organization"
                                    icon="bi-people-fill"
                                    text="Organization"
                                    activePath={pathname}
                                />
                            )}
                        </ul>
                    </Col>
                </Row>
                <Row className="border-top">
                    <Col>
                        <SidebarItem
                            to="/whats-new"
                            icon="bi-rocket-takeoff-fill"
                            text="What's New?"
                            activePath={pathname}
                        />
                        <SidebarItem
                            onClick={() =>
                                openLink("https://megaminds.tawk.help/")
                            }
                            icon="bi-book"
                            text="Help & Resources"
                        />
                        <SidebarItem
                            onClick={() => openLink("https://gomegaminds.com/")}
                            icon="bi-info-circle"
                            text="About MegaMinds"
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}
