import React from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Tab from "react-bootstrap/Tab";

const EmptyAssignmentsPage = (props) => {
    if (props.isLoadingRooms) {
        return null;
    }

    return (
        <div className="h-100 w-100 bg-light">
            <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                <div style={{ maxWidth: "380px" }} className="text-center">
                    <i
                        className="bi bi-boxes text-purple-dark"
                        style={{ fontSize: "3rem" }}
                    ></i>
                    <p className="fw-bold mt-2">
                        Student Projects enable creativity and project-based
                        learning
                    </p>
                    <ListGroup className="text-start">
                        <ListGroup.Item>
                            <strong>How it works</strong>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>1.</strong> Create a Student Project (top
                            right corner) and send the link out to your students
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>2.</strong> Students get their own 3D Spaces to
                            work individually or in groups
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>3.</strong> Students can start out with an
                            empty space or use one of your existing Lessons as a
                            template.
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>4.</strong>
                            You can monitor everything from this page.
                        </ListGroup.Item>
                    </ListGroup>
                    <Button
                        href="https://megaminds.tawk.help/article/guide-to-student-projects"
                        target="_blank"
                        variant="link"
                        className="mb-0 mt-2 fw-light"
                    >
                        <small>Learn More</small>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EmptyAssignmentsPage;
