import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ArchivedClassRoomCard from "../cards/ArchivedClassRoomCard";
import Paginator from "../../../components/Paginator";
import EmptyArchivePage from "../EmptyArchivePage";
import { Socket } from "phoenix";

function ArchivedRoomsTab({
    auth,
    restricted,
    restrictedStudentProjects,
    newRoom,
    showInviteModal,
    showAssignModal,
    handleSearch,
    searchValue,
    sortValue,
    setSortValue,
    page,
    setPage,
    refreshSingleRoom,
    archived_rooms,
    isLoadingArchived,
    archivedError,
    refreshArchived,
    refreshRooms
}) {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const socketSettings = {};
        socketSettings.logger = (kind, msg, data) => {
            // console.log(kind, msg, data);
        };

        const socket = new Socket(
            process.env.REACT_APP_PHOENIX_ENDPOINT,
            socketSettings
        );
        socket.onError(async (error) => {
            console.log("Socket errored", error);
        });

        socket.connect();
        setSocket(socket);
    }, [archived_rooms]);
    
    return (
    <>
    {isLoadingArchived && (
                    <Row className="m-0 p-3 p-lg-4">
                        <Col>
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        </Col>
                    </Row>
                )}

                {!isLoadingArchived && archivedError && (
                    <Row className="m-0 p-3 p-lg-4">
                        <Col>
                            <p>{archivedError}</p>
                        </Col>
                    </Row>
                )}
    {searchValue ||
        (!isLoadingArchived &&
            !archivedError &&
            archived_rooms &&
            archived_rooms?.results?.length > 0) ? (
        <Container className="p-0 m-0">
            <Row className="g-3">
                {archived_rooms &&
                    archived_rooms?.results?.length >= 0 &&
                    archived_rooms?.results?.map((room) => (
                        <ArchivedClassRoomCard
                            refreshSingleRoom={refreshSingleRoom}
                            refresh={refreshRooms}
                            newRoom={newRoom === room.id}
                            classroom={room}
                            disabled={
                                auth.profile?.has_active_subscription &&
                                archived_rooms?.results?.length > 3
                            }
                            profile={auth.profile}
                            auth={auth}
                            showInviteModal={showInviteModal}
                            showAssignModal={showAssignModal}
                            socket={socket}
                            key={room.id}
                            restricted={restricted}
                            restrictedStudentProjects={
                                restrictedStudentProjects
                            }
                        />
                    ))}
            </Row>
            <Col sm={12} md={12} lg={12} xxl={12} className="mt-3">
                {searchValue &&
                    archived_rooms?.length >= 1 &&
                    (!archived_rooms ||
                        (archived_rooms?.results?.length <= 0 && (
                            <Col xs={3}>
                                <p>No rooms matched your filters.</p>
                                <Button
                                    variant="purple-dark"
                                    onClick={() => null}
                                >
                                    Reset filters
                                </Button>
                            </Col>
                        )))}
                {searchValue && archived_rooms?.results?.length <= 0 && (
                    <Col>
                        <p>No rooms matched your search.</p>
                        <Button
                            variant="purple-dark"
                            onClick={() => handleSearch("")}
                        >
                            Reset filters
                        </Button>
                    </Col>
                )}
            </Col>

            {archived_rooms?.count > 20 && (
                <Row className="m-0 p-3 p-lg-4">
                    <Paginator
                        items={archived_rooms}
                        page={page}
                        setPage={setPage}
                    />
                </Row>
            )}
        </Container>
    ) : (
        <EmptyArchivePage 
        isLoadingArchived={isLoadingArchived} 
        auth={auth}
        />
    )}
    </>
)}
export default ArchivedRoomsTab;