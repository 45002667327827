import React, { useState, useEffect } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListGroup from "react-bootstrap/ListGroup";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useAuth0 } from "@auth0/auth0-react";

export default function NewRoomButton(props) {
    const [isPostingPresentation, setIsPostingPresentation] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    return (
        <Button
            as={Link}
            to="/new/student-project"
            disabled={isPostingPresentation}
            variant="purple-dark"
            className={props.fullWidth ? "w-100 text-start" : ""}
        >
            <i className="bi bi-plus-lg me-2"></i>
            <span className="">New Student Project</span>
        </Button>
    );
}
