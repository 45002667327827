import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-hot-toast";
import ReactGA from "react-ga4";

function DeleteClassroomModal({
    endpoint,
    title,
    id,
    buttonText,
    scope,
    refresh,
    category,
    variant,
}) {
    const [show, setShow] = useState(false);
    const [isPosting, setIsPosting] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const handleClose = () => setShow(false);

    const openModal = () => {
        ReactGA.event({
            category: category,
            action: "modal_opened_delete_room",
            label: "User opened the Delete modal", // optional
        });
        setShow(true);
    };

    const handleSubmit = () => {
        setIsPosting(true);

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: scope,
        }).then((token) => {
            fetch(process.env.REACT_APP_MM_API_URL + endpoint + "/" + id, {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    setShow(false);
                    setIsPosting(false);
                    toast.success("Deleted");
                    refresh();
                })
                .catch((error) => {
                    setIsPosting(false);
                });
        });
    };

    return (
        <>
            <Button
                variant={variant ? variant : "white"}
                className="rounded-0 border-0"
                as={variant ? Button : Dropdown.Item}
                onClick={() => openModal()}
            >
                {buttonText}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Delete</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        Are you sure you want to permanently delete{" "}
                        <span className="fw-bold">{title}</span>?
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        disabled={isPosting ? true : false}
                        onClick={handleSubmit}
                    >
                        {isPosting ? (
                            <>
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                <span>Deleting...</span>
                            </>
                        ) : (
                            <span>Delete</span>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default DeleteClassroomModal;
