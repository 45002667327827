import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const useProfileAPI = (isAuthenticated, isLoading) => {
    const { getAccessTokenSilently } = useAuth0();
    const [profile, setProfile] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(0);
    const [banned, setBanned] = useState(false);

    const refresh = () => {
        setRefreshIndex(refreshIndex + 1);
    };

    useEffect(() => {
        // Ensure auth0 has been initialized first
        if (!isLoading) {
            if (isAuthenticated) {
                (async () => {
                    try {
                        const token = await getAccessTokenSilently({
                            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                            scope: "read:classrooms",
                        });
                        const response = await fetch(
                            process.env.REACT_APP_MM_API_URL + "teacherprofile",
                            {
                                headers: {
                                    Authorization: "Bearer " + token,
                                },
                            }
                        );
                        if (response.status == 403) {
                            console.log(response.message);
                            setProfile(await response.json());
                            setBanned(true);
                        } else {
                            setProfile(await response.json());
                            setLoading(false);
                        }
                    } catch (e) {
                        console.error(e);
                        setLoading(false);
                        setError(e);
                    }
                })();
            } else {
                setProfile(null);
                setLoading(false);
            }
        } else {
            // Still loading auth0...
        }
    }, [getAccessTokenSilently, refreshIndex, isAuthenticated, isLoading]);

    return [profile, loading, error, refresh, banned];
};

export default useProfileAPI;
