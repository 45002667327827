export function createRoom(classroom, profile_token) {
    return new Promise((resolve, error) => {
        fetch(process.env.REACT_APP_MM_API_URL + "classrooms/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${profile_token}`,
            },
            body: JSON.stringify(classroom),
        })
            .then((res) => res.json())
            .then((data) => resolve(data))
            .catch((e) => {
                error(e);
            });
    });
}

export function cloneRoom(classroom_id, profile_token) {
    return new Promise((resolve, error) => {
        const payload = {
            original_id: classroom_id,
        };

        console.log("Cloning classroom", payload);

        fetch(process.env.REACT_APP_MM_API_URL + "clone/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${profile_token}`,
            },
            body: JSON.stringify(payload),
        })
            .then((res) => res.json())
            .then((data) => resolve(data))
            .catch((e) => {
                error(e);
            });
    });
}

export function createTemplate(payload, profile_token) {
    return new Promise((resolve, error) => {
        payload.append("create_template", true);

        console.log("POSTING PAYLOAD", payload);

        fetch(process.env.REACT_APP_MM_API_URL + "templates/mine/", {
            method: "POST",
            headers: {
                Authorization: `Bearer ${profile_token}`,
            },
            body: payload,
        })
            .then((res) => res.json())
            .then((data) => resolve(data))
            .catch((e) => {
                error(e);
            });
    });
}

export async function cloneSingleTemplate(
    template_id,
    profile_token,
    title,
    description,
    selectedMode,
    isGroup,
    remix
) {
    console.log("REMIX", remix);

    return new Promise((resolve, error) => {
        const payload = {
            title: title,
            description: description ? description : "",
            template_id: template_id,
            selectedMode: selectedMode,
            isGroup: isGroup,
            remix: remix,
        };

        fetch(process.env.REACT_APP_MM_API_URL + "templates/clone/", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${profile_token}`,
            },
            body: JSON.stringify(payload),
        })
            .then((res) => res.json())
            .then((data) => resolve(data))
            .catch((e) => {
                error(e);
            });
    });
}

