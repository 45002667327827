import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Tooltip } from "react-tooltip";
import { useNavigate, Link } from "react-router-dom";
import { Presence } from "phoenix";
import { toast } from "react-hot-toast";
import ReactGA from "react-ga4";
import moment from "moment";

import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";

import EditAssignmentModal from "../modals/EditAssignmentModal";
import RoomSecurityModal from "../modals/RoomSecurityModal";
import DeleteAssignmentModal from "../modals/DeleteAssignmentModal";
import { RealtimeModal } from "../modals/RealTimeModal";
import UnshareModal from "../modals/UnshareModal";

import { getPicture } from "../../../utils/get-picture";

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <Button
        variant="none"
        className="text-dark"
        ref={ref}
        onClick={(e) => {
            e.preventDefault();
            onClick(e);
        }}
    >
        {children}
        <i className="bi bi-list" style={{ fontSize: "1.4rem" }}></i>
    </Button>
));

export default function SharedAssignmentCard({
    auth,
    assignment,
    profile,
    socket,
    refresh,
    refreshSingleRoom,
    showInviteModal,
    showStudentRoomsModal,
    newRoom,
}) {
    const navigate = useNavigate();
    const [channel, setChannel] = useState(null);

    const { getAccessTokenSilently } = useAuth0();

    return (
        <Col sm={12} md={12} lg={12} xxl={12}>
            <Tooltip id="buildTip" />
            <Card className="mb-3 mt-3">
                <Row className="p-0 m-0 g-0">
                    <Col className="">
                        <Row className="border-bottom m-0">
                            <Col
                                xs={9}
                                md={8}
                                className="d-flex justify-content-start align-items-center"
                            >
                                <Card.Title className="text-dark mb-0 ms-3 me-3 text-truncate">
                                    {assignment.title}
                                </Card.Title>
                                {newRoom && (
                                    <span className="badge bg-success d-none d-md-block me-2">
                                        Just created!
                                    </span>
                                )}
                                {assignment.locked && (
                                    <span className="badge bg-secondary d-none d-md-block me-2">
                                        Locked
                                    </span>
                                )}
                            </Col>
                            <Col
                                xs={3}
                                md={4}
                                className="d-flex justify-content-end align-items-center p-1"
                            >
                                <RoomSecurityModal
                                    auth={auth}
                                    refresh={refresh}
                                    refreshSingleRoom={refreshSingleRoom}
                                    object={assignment}
                                    socket={socket}
                                    channel={channel}
                                />
                                <Dropdown>
                                    <Dropdown.Toggle
                                        as={CustomToggle}
                                        variant="white"
                                        className="text-white"
                                        id="dropdown-basic"
                                    ></Dropdown.Toggle>

                                    <Dropdown.Menu>
                                        {profile.id === assignment.owner.id && (
                                            <UnshareModal
                                                refresh={refresh}
                                                endpoint="assignments/detail"
                                                buttonText="Unshare from Your Organization"
                                                title={assignment.title}
                                                object={assignment}
                                                profile={profile}
                                            />
                                        )}
                                        <EditAssignmentModal
                                            refresh={refresh}
                                            buttonText="Edit"
                                            object={assignment}
                                        />
                                    </Dropdown.Menu>
                                </Dropdown>
                            </Col>
                        </Row>
                        <Row className="m-0 mt-4 mb-4 ms-3">
                            <Col
                                xs={12}
                                sm={4}
                                className="d-flex justify-content-start align-items-center d-none d-sm-block"
                            >
                                <Row>
                                    <Col>
                                        {profile.id === assignment.owner ? (
                                            <small className="">
                                                Created by You
                                            </small>
                                        ) : (
                                            <small className="">
                                                Shared by{" "}
                                                <span className="fw-bold">
                                                    {
                                                        assignment.owner
                                                            .first_name
                                                    }
                                                </span>{" "}
                                                from {assignment.shared_to.name}
                                            </small>
                                        )}
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <small className="fw-light text-dark mb-0">
                                            Instructions: {assignment.instructions}
                                        </small>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                xs={12}
                                sm={8}
                                className="d-flex justify-content-end align-items-center"
                            >
                                <Button
                                    data-tooltip-id="classroomCard"
                                    data-tooltip-content="Invite users to this space"
                                    onClick={() => showInviteModal(assignment)}
                                    className="me-3"
                                    variant="card-purple-dark"
                                >
                                    <span className="">Share</span>
                                </Button>
                                <Button
                                    onClick={() =>
                                        showStudentRoomsModal(assignment)
                                    }
                                    className="me-2"
                                    variant="success"
                                >
                                    <span className="">
                                        View Student Spaces
                                    </span>
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
}
