import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Card from "react-bootstrap/Card";
import moment from "moment";
import "moment-timezone";

function MCQQuestionModal({
    showModal,
    setShowModal,
    selectedQuestion,
    refreshInfo,
}) {
    if (!selectedQuestion) return null;

    console.log(selectedQuestion);
    const totalAnswers = selectedQuestion.mcqanswer_set.reduce(
        (count, current) => count + current.mcqsubmission_set.length,
        0
    );

    return (
        <Modal
            show={showModal}
            size="lg"
            scrollable
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Question Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{selectedQuestion.question}</h4>
                <Row className="mt-4 p-3">
                    <ListGroup>
                        {selectedQuestion.mcqanswer_set.map((answer) => (
                            <ListGroup.Item key={answer.timestamp}>
                                <Row>
                                    <Col></Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p className="float-end mb-0">
                                            {answer.mcqsubmission_set.length ? (
                                                <span className="mb-2 ms-4 fw-light">
                                                    <small>
                                                        {(
                                                            (answer
                                                                .mcqsubmission_set
                                                                .length /
                                                                totalAnswers) *
                                                            100
                                                        ).toFixed(2) + "%"}
                                                    </small>
                                                </span>
                                            ) : (
                                                <span className="mb-2 ms-4 fw-light">
                                                    <small>0%</small>
                                                </span>
                                            )}
                                        </p>
                                        <p className="fw-bold mb-0">
                                            {answer.correct ? (
                                                <i class="bi bi-check text-success me-2"></i>
                                            ) : (
                                                <i class="bi bi-x text-danger me-2"></i>
                                            )}
                                            {answer.answer}
                                        </p>
                                    </Col>
                                </Row>
                                <Row className="">
                                    <Col>
                                        {answer.mcqsubmission_set.map(
                                            (submission) => (
                                                <p className="fw-light mt-3">
                                                    <small>
                                                        {
                                                            submission.student_name
                                                        }
                                                    </small>
                                                </p>
                                            )
                                        )}
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function QuestionModal({
    showModal,
    setShowModal,
    selectedQuestion,
    refreshInfo,
}) {
    if (!selectedQuestion) return null;

    return (
        <Modal
            show={showModal}
            size="lg"
            scrollable
            onHide={() => setShowModal(false)}
        >
            <Modal.Header closeButton>
                <Modal.Title>Question Details</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <h4>{selectedQuestion.question}</h4>
                <Row className="mt-4 p-3">
                    <ListGroup>
                        {selectedQuestion.freetextanswer_set.map((answer) => (
                            <ListGroup.Item key={answer.timestamp}>
                                <Row>
                                    <Col>
                                        <p>
                                            {answer.student_name}
                                            <small className="float-end">
                                                {moment(
                                                    answer.timestamp
                                                ).format("MMMM Do HH:ss")}
                                            </small>
                                        </p>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <p>{answer.answer}</p>
                                    </Col>
                                    <Col>
                                        <p>
                                            {answer.grade && (
                                                <span className="float-end fw-light">
                                                    <small>
                                                        {answer.grade}%
                                                    </small>
                                                </span>
                                            )}
                                        </p>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModal(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

function FreetextCard({ bundle }) {
    const [showModal, setShowModal] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(false);

    const openModal = (question) => {
        setShowModal(true);
        setSelectedQuestion(question);
    };

    return (
        <Card>
            <QuestionModal
                showModal={showModal}
                setShowModal={setShowModal}
                selectedQuestion={selectedQuestion}
            />
            <Card.Body>
                {bundle.freetextquestion_set.map((question, index) => (
                    <Row key={index}>
                        <Col>
                            <Button
                                variant="link"
                                onClick={() => openModal(question)}
                            >
                                {question.question} (
                                {question.freetextanswer_set.length}{" "}
                                submissions)
                            </Button>
                        </Col>
                    </Row>
                ))}
            </Card.Body>
        </Card>
    );
}

function MCQCard({ bundle }) {
    const [showModal, setShowModal] = useState(false);
    const [selectedQuestion, setSelectedQuestion] = useState(false);

    const openModal = (question) => {
        setShowModal(true);
        setSelectedQuestion(question);
    };

    return (
        <Card>
            <MCQQuestionModal
                showModal={showModal}
                setShowModal={setShowModal}
                selectedQuestion={selectedQuestion}
            />
            <Card.Body>
                {bundle.mcqquestion_set.map((question, index) => {
                    const totalAnswers = question.mcqanswer_set.reduce(
                        (count, current) =>
                            count + current.mcqsubmission_set.length,
                        0
                    );
                    return (
                        <div key={index}>
                            <Button
                                onClick={() => openModal(question)}
                                variant="link"
                            >
                                {question.question}
                            </Button>
                            <Row className="m-3">
                                {question.mcqanswer_set.map(
                                    (answer, answerIndex) => {
                                        return (
                                            <Col
                                                key={answerIndex}
                                                xs={3}
                                                className={
                                                    answer.correct
                                                        ? "border bg-success text-white text-center pt-2"
                                                        : "text-center border pt-2"
                                                }
                                            >
                                                {answer.answer}
                                                {answer.mcqsubmission_set
                                                    .length ? (
                                                    <p className="mb-2 fw-bold">
                                                        {(
                                                            (answer
                                                                .mcqsubmission_set
                                                                .length /
                                                                totalAnswers) *
                                                            100
                                                        ).toFixed(2) + "%"}
                                                    </p>
                                                ) : (
                                                    <p>
                                                        <small>0%</small>
                                                    </p>
                                                )}
                                            </Col>
                                        );
                                    }
                                )}
                            </Row>
                        </div>
                    );
                })}
            </Card.Body>
        </Card>
    );
}

export default function Questions({ roomData }) {
    const mcq_bundles = roomData.mcqbundle_set;
    const freetext_bundles = roomData.freetextbundle_set;

    const totalMCQ = mcq_bundles.reduce(
        (count, current) => count + current.mcqquestion_set.length,
        0
    );

    const totalFreetext = freetext_bundles.reduce(
        (count, current) => count + current.freetextquestion_set.length,
        0
    );

    const totalQuestions = totalMCQ + totalFreetext;

    return (
        <Row>
            <Col>
                <p>
                    Total <span className="fw-bold">{totalQuestions}</span>{" "}
                    questions in the Lesson{" "}
                </p>
            </Col>
            <Row className="mt-3">
                <Col>
                    <h5>Multiple Choice Questions</h5>
                    {mcq_bundles.map((bundle, index) => (
                        <MCQCard bundle={bundle} key={index} />
                    ))}
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <h5>Freetext Questions</h5>
                    {freetext_bundles.map((bundle, index) => (
                        <FreetextCard bundle={bundle} key={index} />
                    ))}
                </Col>
            </Row>
        </Row>
    );
}
