import React, { useState, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import useRoomAPI from "../../services/useRoomAPI";
import RoomBrowserCard from "./cards/RoomBrowserCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import Paginator from "../../components/Paginator";
import { useAuth } from "../../context/AuthContext";

const MyRoomsBrowser = ({
    goBack,
    onFinished,
    selectedMode,
    show,
    selectedRoom,
    setSelectedRoom,
    setRoom,
    setShow,
}) => {
    const auth = useAuth();
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [tempSearch, setTempSearch] = useState("");

    const [rooms, isLoading, isError] = useRoomAPI(
        page,
        searchValue,
        "-id",
        auth
    );

    useEffect(() => {
        if (rooms?.results?.length > 0 && !selectedRoom) {
            setRoom(rooms?.results[0]);
        }
    }, [rooms]);

    const handleSearch = () => {
        setPage(1);
        setSearchValue(tempSearch);
    };

    if (isError) {
        return <p>Error loading your rooms.</p>;
    }

    return (
        <Modal size="lg" fullscreen="md-down" show={show} scrollable onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <p>Select a Lesson</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-3">
                    <Col>
                        <InputGroup>
                            <Form.Control
                                type="text"
                                placeholder={"Search your Lessons"}
                                aria-label="Filter lessons by name"
                                value={tempSearch}
                                onChange={(e) => setTempSearch(e.target.value)}
                                onKeyDown={(e) => {
                                    if (e.key === "Enter") {
                                        handleSearch();
                                    }
                                }}
                            />
                            <Button
                                variant="light border"
                                onClick={() => handleSearch()}
                            >
                                <FontAwesomeIcon icon={faSearch} fixedWidth />
                            </Button>
                        </InputGroup>
                    </Col>
                </Row>

                <Row className="mb-4 g-4">
                    {!isLoading &&
                        rooms?.results?.map((room) => (
                            <Col xs={12} md={4}>
                                <RoomBrowserCard
                                    room={room}
                                    onSelect={() => onFinished(room)}
                                />
                            </Col>
                        ))}
                </Row>
                {!isLoading && (
                    <Row className="mb-4 g-4">
                        <Col>
                            <Paginator
                                items={rooms}
                                page={page}
                                setPage={setPage}
                            />
                        </Col>
                    </Row>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default MyRoomsBrowser;
