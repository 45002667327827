import Accordion from "react-bootstrap/Accordion";
import ListGroup from "react-bootstrap/ListGroup";
import moment from "moment";
import "moment-timezone";

function getString(event) {
    console.log(event);
    if (event.action === "com_share_screen") {
        return (
            <p className="mb-0">
                <i className="bi bi-display me-2"></i>
                {event.name} shared their screen
                <span className="float-end">
                    {moment(event.date).format("MMMM Do HH:mm")}
                </span>
            </p>
        );
    }
    if (event.action === "com_share_webcam") {
        return (
            <p className="mb-0">
                <i className="bi bi-webcam-fill me-2"></i>
                {event.name} shared their webcam
                <span className="float-end">
                    {moment(event.date).format("MMMM Do HH:mm")}
                </span>
            </p>
        );
    }
    if (event.action === "com_raised_hand") {
        return (
            <p className="mb-0">
                <i className="bi bi-exclamation me-2"></i>
                {event.name} raised their hand
                <span className="float-end">
                    {moment(event.date).format("MMMM Do HH:mm")}
                </span>
            </p>
        );
    }
    if (event.action === "room_enter") {
        return (
            <p className="mb-0">
                <i className="bi bi-door-open me-2"></i>
                {event.name} entered the Lesson
                <span className="float-end">
                    {moment(event.date).format("MMMM Do HH:mm")}
                </span>
            </p>
        );
    }

    if (event.action === "room_leave") {
        return (
            <p className="mb-0">
                <i className="bi bi-box-arrow-left me-2"></i>
                {event.name} left the Lesson
                <span className="float-end">
                    {moment(event.date).format("MMMM Do HH:mm")}
                </span>
            </p>
        );
    }

    if (event.action === "com_chat_message") {
        return (
            <p className="mb-0">
                <i className="bi bi-chat me-2"></i>
                {event.name} sent a chat message
                <span className="float-end">
                    {moment(event.date).format("MMMM Do HH:mm")}
                </span>
            </p>
        );
    }

    if (event.action === "object_deleted") {
        return (
            <p className="mb-0">
                <i className="bi bi-trash-fill me-2"></i>
                {event.name} deleted an object
                <span className="float-end">
                    {moment(event.date).format("MMMM Do HH:mm")}
                </span>
            </p>
        );
    }

    if (event.action === "object_edited") {
        return (
            <p className="mb-0">
                <i className="bi bi-pencil me-2"></i>
                {event.name} edited an object
                <span className="float-end">
                    {moment(event.date).format("MMMM Do HH:mm")}
                </span>
            </p>
        );
    }

    if (event.action === "object_created") {
        return (
            <p className="mb-0">
                <i className="bi bi-plus-circle me-2"></i>
                {event.name} created a {event.related_object?.media_type}
                <span className="float-end">
                    {moment(event.date).format("MMMM Do HH:mm")}
                </span>
            </p>
        );
    }

    return event.action;
}

function UserCard({ name, sessions, index }) {
    // Sum all the sessions together for a total time
    var sum = 0;

    sessions.forEach(function (session) {
        sum += session.time_spent_minutes;
    });

    var eventList = [];
    sessions.forEach((item) => {
        if (item.logevent_set.length > 0) {
            item.logevent_set.forEach((event) => {
                eventList.push(event);
            });
        }
    });

    return (
        <Accordion.Item eventKey={index}>
            <Accordion.Header>
                <p className="mb-0">
                    <span className="fw-bold">{name}</span> spent a total of{" "}
                    {sum.toFixed(2)} minutes in the Lesson.
                </p>
            </Accordion.Header>
            <Accordion.Body>
                {eventList.length > 0 ? (
                    <ListGroup>
                        {eventList.map((event) => (
                            <ListGroup.Item>{getString(event)}</ListGroup.Item>
                        ))}
                    </ListGroup>
                ) : (
                    <ListGroup>
                        <p className="mb-0 fw-light">
                            No events for this user.
                        </p>
                    </ListGroup>
                )}
            </Accordion.Body>
        </Accordion.Item>
    );
}

export default UserCard;
