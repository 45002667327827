import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import ReactGA from "react-ga4";
import { useAuth } from "../../../context/AuthContext";
import { cloneSingleTemplate } from "../../../utils/create-room";
import LoginRequiredModal from "./LoginRequiredModal";
import { useAuth0 } from "@auth0/auth0-react";
import useAssignmentAPI from "../../../services/useAssignmentAPI";

function CreateStudentProjectModal(props) {
    const auth = useAuth();
    const navigate = useNavigate();

    const [
        assignments,
        isLoadingAssignments,
        assignmentError,
        refreshAssignments,
    ] = useAssignmentAPI(1, "", "", auth);

    const restricted =
        auth.profile?.subscription_plan === "Free Plan" &&
        assignments?.results?.length === 3;

    const { getAccessTokenSilently } = useAuth0();

    const [isPosting, setIsPosting] = useState(false);
    const [isGroup, setIsGroup] = useState(false);
    const [showFinalMenu, setShowFinalMenu] = useState(false);

    const [resp, setResp] = useState(null);

    const cloneTemplate = () => {
        setIsPosting(true);
        ReactGA.event({
            category: "classrooms",
            action: "clicked_make_copy",
            label: "User clicked Make Copy / Clone Room button.", // optional
        });

        const prom = new Promise((complete, reject) => {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                scope: "create:classroom",
            }).then((token) => {
                cloneSingleTemplate(
                    props.template.id,
                    token,
                    props.template.title,
                    props.template.description,
                    false,
                    isGroup
                )
                    .then((resp) => {
                        setResp(resp);
                        complete();
                    })
                    .catch((e) => {
                        reject();
                    });
            });
        });

        toast.promise(prom, {
            loading: "Creating Room...",
            success: "Successfully created Room",
            error: "Could not create room at this time.",
        });

        prom.then(() => {
            setShowFinalMenu(true);
            // props.onFinished();
        });
    };

    if (!props.template) {
        return null;
    }

    if (!auth.profile) {
        return (
            <LoginRequiredModal
                show={props.show}
                onHide={() => props.setShowModal(false)}
            />
        );
    }

    if (restricted) {
        return (
            <Modal
                show={props.show}
                centered
                size="lg"
                onHide={() => props.setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Limit reached</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-4 g-4">
                        <Col xs={12} md={12}>
                            <Row>
                                <Col>
                                    <p>
                                        You have reached the limit of 3 Student
                                        Projects on MegaMinds Free tier. Upgrade
                                        to MegaMinds Premium to unlock unlimited
                                        Rooms, Student Projects and more!
                                    </p>
                                    <Button
                                        as={Link}
                                        to="/buy"
                                        variant="success"
                                        className="me-2"
                                    >
                                        See Plans & Pricing
                                    </Button>
                                    <Button
                                        variant="secondary"
                                        onClick={() =>
                                            props.setShowModal(false)
                                        }
                                    >
                                        Close
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        );
    }

    return (
        <>
            <Modal
                show={props.show}
                size="md"
                fullscreen="md-down"
                centered
                onHide={() => props.setShowModal(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>New Student Project</Modal.Title>
                </Modal.Header>
                {showFinalMenu ? (
                    <Modal.Body>
                        <Row className="mb-4 mt-4">
                            <Col>
                                <Alert variant="success">
                                    <p className="mb-0">
                                        A new Student Project was added to the{" "}
                                        <Link to="/student-projects">
                                            Student Projects Page
                                        </Link>
                                        .
                                    </p>
                                </Alert>
                            </Col>
                        </Row>
                        <Row className="mb-4 g-4">
                            <Col xs={12} md={12}>
                                <Card
                                    onClick={() => props.setShowModal(false)}
                                    className="h-100 border-on-hover"
                                >
                                    <Card.Body className="d-flex flex-column justify-content-center align-items-center text-center">
                                        <Row>
                                            <Col>
                                                <p>
                                                    <i
                                                        style={{
                                                            fontSize: "2rem",
                                                        }}
                                                        className="bi bi-arrow-return-left text-purple-dark"
                                                    ></i>
                                                </p>
                                                <h5 className="">
                                                    Close and Keep Exploring
                                                </h5>
                                                <p className="fw-light">
                                                    <small className="">
                                                        You will find the
                                                        Student Project waiting
                                                        in the Student Projects
                                                        page.
                                                    </small>
                                                </p>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Modal.Body>
                ) : (
                    <Modal.Body>
                        <p className="">
                            Do you want the students to build individual rooms
                            or in group rooms?
                        </p>
                        <div className="d-inline">
                            <small>
                                <Form.Check // prettier-ignore
                                    type="radio"
                                    id="individual-option"
                                    name="individual-or-group"
                                    label="Individual Rooms"
                                    checked={!isGroup}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setIsGroup(false);
                                        } else {
                                            setIsGroup(true);
                                        }
                                    }}
                                />
                                <Form.Check // prettier-ignore
                                    type="radio"
                                    id="group-option"
                                    name="individual-or-group"
                                    label="Group Rooms"
                                    checked={isGroup}
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setIsGroup(true);
                                        } else {
                                            setIsGroup(false);
                                        }
                                    }}
                                />
                            </small>
                        </div>
                    </Modal.Body>
                )}
                {!showFinalMenu && (
                    <Modal.Footer>
                        <Button
                            variant="secondary"
                            onClick={() => props.setShowModal(false)}
                        >
                            Close
                        </Button>
                        <Button
                            variant="success"
                            disabled={isPosting ? true : false}
                            onClick={() => cloneTemplate()}
                        >
                            {isPosting ? (
                                <>
                                    <span
                                        className="spinner-border spinner-border-sm"
                                        role="status"
                                        aria-hidden="true"
                                    ></span>
                                    <span>Loading...</span>
                                </>
                            ) : (
                                <span>
                                    <i className="bi bi-plus-lg me-2"></i>Add to{" "}
                                    {props.template.assignment_mode
                                        ? "Student Projects"
                                        : "Rooms"}
                                </span>
                            )}
                        </Button>
                    </Modal.Footer>
                )}
            </Modal>
        </>
    );
}

export default CreateStudentProjectModal;
