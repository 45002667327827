import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faHand } from "@fortawesome/free-solid-svg-icons";
import getAvatarPicture from "../../../utils/get-avatar-picture";
import getDeviceIcon from "../../../utils/get-device-picture";
import ReactGA from "react-ga4";

export function RealtimeModal(props) {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const openModal = () => {
        ReactGA.event({
            category: "classrooms",
            action: "modal_opened_realtime_monitor",
            label: "User opened the Realtime Monitoring modal", // optional
        });
        setShow(true);
    };

    return (
        <>
            <button
                onClick={() => openModal()}
                className="btn badge bg-light text-dark"
            >
                {props.onlineUsers && props.onlineUsers.length} / 25 Users
                <FontAwesomeIcon
                    icon={faEye}
                    color="#090"
                    className="ms-2"
                    fixedWidth
                />
            </button>

            <Modal show={show} size="lg" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>
                        Currently monitoring{" "}
                        <span className="fw-bold">{props.classroomName}</span>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="fw-bold">
                        {props.onlineUsers && props.onlineUsers.length}{" "}
                        {props.onlineUsers && props.onlineUsers.length === 1
                            ? "user "
                            : "users "}
                        <span className="fw-normal">online right now</span>
                    </p>
                    <Table className="p-5 align-middle" striped bordered hover>
                        <thead>
                            <tr>
                                <th className="text-center">Avatar</th>
                                <th>Display Name</th>
                                <th className="text-center">Raising Hand</th>
                                <th className="text-center">Device</th>
                            </tr>
                        </thead>
                        <tbody className="table-group-divider">
                            {props.onlineUsers.map((user) => {
                                return (
                                    <tr key={user.id}>
                                        <td className="text-center">
                                            <p className="fw-bold mb-0">
                                                {getAvatarPicture(
                                                    user.profile?.avatarId
                                                )}
                                            </p>
                                        </td>
                                        <td>
                                            <p className="fw-bold mb-0">
                                                {user.profile?.displayName}
                                            </p>
                                        </td>
                                        <td className="text-center">
                                            {user.hand_raised ? (
                                                <p className="mb-0">
                                                    <FontAwesomeIcon
                                                        icon={faHand}
                                                        className="rounded bg-purple p-2"
                                                        bounce
                                                        color="#ff0"
                                                        size="xl"
                                                        fixedWidth
                                                    />
                                                </p>
                                            ) : (
                                                <p className="mb-0">No</p>
                                            )}
                                        </td>
                                        <td className="text-center">
                                            {getDeviceIcon(user.context)}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RealtimeModal;
