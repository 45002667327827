import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import NewRoomButton from "./NewRoomButton";
import InputGroup from "react-bootstrap/InputGroup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faCirclePlus } from "@fortawesome/free-solid-svg-icons";

const RoomsHeader = ({ restricted, refreshRooms }) => {
    return (
        <Row className="bg-white border-bottom m-0 p-0 pt-2 pb-2 p-md-3">
            <Col
                md={6}
                lg={4}
                className="d-flex align-items-center d-none d-sm-flex"
            >
                <h3 className="mb-0">Lessons</h3>
            </Col>
            <Col className="d-flex align-items-center justify-content-md-end mt-sm-4 mt-md-0">
                <Button
                    variant="secondary"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://megaminds.tawk.help"
                    className="me-2"
                >
                    <FontAwesomeIcon icon={faBook} fixedWidth />
                    <span className="ms-3">Guides & Tips</span>
                </Button>
                {restricted ? (
                    <Button
                        variant="purple-dark"
                        className="ms-lg-3 p-md-2 p-lg-2 ps-lg-3 pe-lg-3"
                        disabled
                    >
                        <FontAwesomeIcon icon={faCirclePlus} fixedWidth />
                        <span className="ms-3">Limit Reached</span>
                    </Button>
                ) : (
                    <NewRoomButton onFinish={() => refreshRooms()} />
                )}
            </Col>
        </Row>
    );
};

export default RoomsHeader;
