import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

import { CheckoutBulkModal } from "../modals/CheckoutBulkModal";

const Bulk = () => {
    const [orgName, setOrgName] = useState("");
    const [showCheckout, setShowCheckout] = useState(false);
    const [cycle, setCycle] = useState("yearly");
    const [licences, setLicences] = useState(2);

    const [quantityError, setQuantityError] = useState(false);
    const [contactMessage, setContactMessage] = useState(false);

    const monthlyPrice = (14.99 * licences).toFixed(2);

    const yearlyMonthlyCost = ((149.99 * licences) / 12).toFixed(2);
    const yearlyPrice = (149.99 * licences).toFixed(2);

    useEffect(() => {
        if (licences < 2) {
            setQuantityError(true);
        } else {
            setQuantityError(false);
        }
        if (licences > 20) {
            setContactMessage(true);
        } else {
            setContactMessage(false);
        }
    }, [licences]);

    return (
        <div className="mt-5">
            <Alert variant="secondary" className="mt-5">
                <p className="mb-0">
                    <i className="bi bi-info-circle me-2"></i>
                    Get shared Lessons, collaboration and management tools and
                    more with{" "}
                    <a href="https://gomegaminds.com/schools-districts/">
                        School Licences.
                    </a>
                </p>
            </Alert>

            <Alert variant="info">
                <p className="mb-0">
                    After purchasing multiple licences you will be able to
                    delegate your licences to other users.
                </p>
            </Alert>

            <CheckoutBulkModal
                setShow={setShowCheckout}
                show={showCheckout}
                cycle={cycle}
                setCycle={setCycle}
                quantity={licences}
                setQuantity={setLicences}
                orgName={orgName}
            />
            <Card.Body className="">
                <Row className="mb-3 g-4">
                    <Col xs={12} controlId="formGridState">
                        <Card className={"h-100"}>
                            <Card.Body>
                                <Row className="">
                                    <h4 className="mb-0 fw-bold">
                                        Name your organization
                                    </h4>
                                </Row>
                                <Form.Group as={Row} className="mt-4">
                                    <Col xs={4}>
                                        <Form.Control
                                            value={orgName}
                                            onChange={(e) =>
                                                setOrgName(e.target.value)
                                            }
                                            type="text"
                                        />
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3 g-4">
                    <Col xs={12} controlId="formGridState">
                        <Card className={"h-100"}>
                            <Card.Body>
                                <Row className="">
                                    <h4 className="mb-0 fw-bold">
                                        How many licenses do you need?
                                    </h4>
                                </Row>
                                <Form.Group as={Row} className="mt-4">
                                    <Col xs={4}>
                                        <Form.Control
                                            value={licences}
                                            max={20}
                                            min={2}
                                            onChange={(e) =>
                                                setLicences(e.target.value)
                                            }
                                            type="number"
                                        />
                                    </Col>
                                </Form.Group>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                {licences >= 2 && 20 >= licences && (
                    <Row>
                        <Col xs={12} controlId="formGridState">
                            <Card className={"h-100"}>
                                <Card.Body>
                                    <Row className="">
                                        <h4 className="mb-0 fw-bold">
                                            Chose your subscription cycle
                                        </h4>
                                    </Row>
                                    <Row className="mt-4">
                                        <Col xs={4}>
                                            <Card
                                                onClick={() =>
                                                    setCycle("monthly")
                                                }
                                                className={
                                                    cycle === "monthly"
                                                        ? "border border-3 border-success h-100"
                                                        : "h-100"
                                                }
                                            >
                                                <Card.Body>
                                                    <Row className="">
                                                        <h5 className="">
                                                            Monthly
                                                        </h5>
                                                        <p className="fw-bold">
                                                            ${monthlyPrice} /
                                                            month
                                                        </p>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={4}>
                                            <Card
                                                onClick={() =>
                                                    setCycle("yearly")
                                                }
                                                className={
                                                    cycle === "yearly"
                                                        ? "border border-3 border-success h-100"
                                                        : "h-100"
                                                }
                                            >
                                                <Card.Body>
                                                    <Row className="">
                                                        <h5 className="">
                                                            Yearly
                                                        </h5>
                                                        <p className="fw-bold">
                                                            ${yearlyMonthlyCost}{" "}
                                                            / month
                                                        </p>
                                                        <small className="fw-light">
                                                            ${yearlyPrice}{" "}
                                                            billed annually
                                                        </small>
                                                    </Row>
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
                <Row className="mt-4">
                    <Col xs={12} controlId="formGridState">
                        <Card className={"h-100"}>
                            <Card.Body>
                                {quantityError && (
                                    <p>
                                        If you only need 1 licence, please go to
                                        Individual Subscrpition
                                    </p>
                                )}
                                {contactMessage && (
                                    <p>
                                        If you need more than 20 licences,
                                        please contact us.
                                    </p>
                                )}
                                <Button
                                    disabled={quantityError || contactMessage}
                                    onClick={() => setShowCheckout(true)}
                                >
                                    Checkout
                                </Button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
        </div>
    );
};

export default Bulk;
