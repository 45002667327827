import { useEffect, useState } from "react";

const useDashApiGuest = (endpoint) => {
    const [classrooms, setClassrooms] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(0);

    const refresh = () => {
        setRefreshIndex(refreshIndex + 1);
    };

    useEffect(() => {
        (async () => {
            try {
                const response = await fetch(
                    process.env.REACT_APP_MM_API_URL + endpoint
                );
                setClassrooms(await response.json());
                setLoading(false);
            } catch (e) {
                console.error(e);
                setLoading(false);
                setError(e);
            }
        })();
    }, [refreshIndex, endpoint]);

    return [classrooms, loading, error, refresh];
};

export default useDashApiGuest;
