import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { useAuth0 } from "@auth0/auth0-react";

const endpoint = (organization) => {
    if (organization) {
        return (
            process.env.REACT_APP_MM_API_URL +
            "payments/organization/invoices/retrieve/" +
            organization.id
        );
    } else {
        return process.env.REACT_APP_MM_API_URL + "payments/invoices/retrieve";
    }
};

export function PaymentHistoryModal({ setShow, show, organization }) {
    const [invoices, setInvoices] = useState(null);
    const [isInvoicesLoading, setInvoicesLoading] = useState(true);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (show) {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                scope: "update:classroom",
            }).then((token) => {
                fetch(endpoint(organization), {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        setInvoicesLoading(false);
                        setInvoices(data);
                        console.log("Success", data);
                    })
                    .catch((error) => {
                        setInvoicesLoading(false);
                        console.error(error);
                    });
            });
        }
    }, [show, getAccessTokenSilently, organization]);

    return (
        <Modal show={show} size="lg" onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Payment History</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="p-3">
                    <Col>
                        <ListGroup>
                            {isInvoicesLoading && <p>Loading</p>}
                            {!isInvoicesLoading && !invoices && (
                                <p className="mb-0">
                                    Could not find any payment history on this
                                    account.
                                </p>
                            )}
                            {!invoices?.data && (
                                <p className="mb-0">
                                    No invoices on this account
                                </p>
                            )}
                            {invoices?.data?.map((invoice) => (
                                <ListGroup.Item>
                                    <Row>
                                        <Col>
                                            <p className="mb-0">
                                                {invoice.number ? (
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={
                                                            invoice.hosted_invoice_url
                                                        }
                                                        className="me-2"
                                                    >
                                                        {invoice.number}
                                                    </a>
                                                ) : (
                                                    <small className="me-2 fw-light">
                                                        Upcoming Invoice
                                                    </small>
                                                )}
                                                <small>
                                                    From{" "}
                                                    <span className="fw-light">
                                                        {new Date(
                                                            invoice.lines
                                                                .data[0].period
                                                                .start * 1000
                                                        ).toLocaleDateString(
                                                            "en-US"
                                                        )}
                                                        {" to "}
                                                        {new Date(
                                                            invoice.lines
                                                                .data[0].period
                                                                .end * 1000
                                                        ).toLocaleDateString(
                                                            "en-US"
                                                        )}
                                                    </span>
                                                </small>
                                            </p>
                                        </Col>
                                        <Col>
                                            <p className="mb-0 text-end">
                                                {invoice.total > 0 ? (
                                                    <span className="badge bg-secondary me-2">
                                                        $
                                                        {invoice.amount_paid /
                                                            100}
                                                    </span>
                                                ) : (
                                                    <span className="badge bg-info me-2">
                                                        $
                                                        {Math.abs(
                                                            invoice.total / 100
                                                        )}{" "}
                                                        credited due to quantity
                                                        change
                                                    </span>
                                                )}
                                                {invoice.paid && (
                                                    <span className="badge bg-success">
                                                        Paid
                                                    </span>
                                                )}
                                                {invoice.status === "void" && (
                                                    <span className="badge bg-secondary">
                                                        Void
                                                    </span>
                                                )}
                                                {invoice.status === "draft" && (
                                                    <span className="badge bg-secondary">
                                                        Billed Soon
                                                    </span>
                                                )}
                                            </p>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            ))}
                        </ListGroup>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
