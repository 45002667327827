import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { getPicture } from "../../../utils/get-picture";
import { Link } from "react-router-dom";

function toLowerCase(string) {
    return string.replace(/\s+/g, "-").toLowerCase();
}

function BundleCard({ bundle, refresh, openModal, horizontal, home }) {
    return (
        <Col
            key={bundle.id}
            xs={horizontal ? 12 : home ? 6 : 6}
            sm={horizontal ? 12 : home ? 6 : 6}
            md={horizontal ? 12 : home ? 4 : 4}
            lg={horizontal ? 12 : home ? 3 : 4}
        >
            <Button
                variant="empty"
                className="m-0 p-0 text-start w-100 h-100"
                as={Link}
                to={
                    "/collections/" +
                    bundle.id +
                    "/" +
                    toLowerCase(bundle.title)
                }
            >
                <Card
                    className="hover-shadow h-100"
                    style={{ minHeight: "240px" }}
                >
                    <Card.Img
                        height="100px"
                        className={horizontal ? "dim rounded-0" : "dim"}
                        variant="top"
                        src={bundle.image && getPicture(bundle.image)}
                    />
                    <Card.ImgOverlay></Card.ImgOverlay>
                    <Card.Body className="d-flex p-0">
                        <Row className="p-0 m-0 w-100 p-2 pt-4 pb-4">
                            <Col xs={12}>
                                <Card.Title className="text-dark mb-3">
                                    {bundle.title}
                                </Card.Title>
                            </Col>
                            <Col xs={12} className="d-flex align-items-end">
                                <i
                                    className="bi bi-collection"
                                    style={{ fontSize: "0.9rem" }}
                                ></i>
                                <small className="fw-light text-muted ps-2">
                                    Collection
                                </small>
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
            </Button>
        </Col>
    );
}

export default BundleCard;
