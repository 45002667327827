import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { Link } from "react-router-dom";

import { PaymentHistoryModal } from "../payments/modals/PaymentHistoryModal";

export function NoSubscription(props) {
    const [showPaymentHistory, setShowPaymentHistory] = useState(false);

    return (
        <>
            <Row>
                <Col>
                    <h2>Billing Summary</h2>
                    <Card>
                        <Card.Body>
                            <Row>
                                <Col className="">
                                    <p className="mb-2 fw-bold">
                                        Your next payment
                                    </p>
                                    <h4 className="fw-bold mb-2">$0.00</h4>
                                    <small className="fw-light">
                                        You are not subscribed
                                    </small>
                                </Col>
                                <Col>
                                    <ListGroup>
                                        <ListGroup.Item>
                                            <PaymentHistoryModal
                                                invoices={props.invoices}
                                                show={showPaymentHistory}
                                                setShow={setShowPaymentHistory}
                                            />
                                            <Button
                                                variant="link"
                                                className="m-0 p-0"
                                                onClick={() =>
                                                    setShowPaymentHistory(true)
                                                }
                                            >
                                                Payment history
                                            </Button>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <h2>Current Plan</h2>
                </Col>
                <Col className="d-flex justify-content-end">
                    <Button
                        to="/buy"
                        as={Link}
                        className="ms-2 d-flex align-items-center"
                        variant="secondary"
                    >
                        Compare Plans
                    </Button>
                    <Button
                        to="/buy"
                        as={Link}
                        className="ms-2 d-flex align-items-center"
                        variant="success"
                    >
                        Upgrade
                    </Button>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <Card>
                        <Card.Header className="fw-bold">Free Plan</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <p>Included</p>
                                    <ListGroup>
                                        <ListGroup.Item>3 Rooms</ListGroup.Item>
                                        <ListGroup.Item>
                                            25 users per room
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                                <Col>
                                    <p>Not Included</p>
                                    <ListGroup>
                                        <ListGroup.Item>
                                            Unlimited Rooms
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Unlimited Assignment
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            Access to all our EdTech Integrations
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </>
    );
}
