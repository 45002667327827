import { toast } from "react-hot-toast";

export const acceptInvite = (token, invite_id, refresh) => {
    let formdata = new FormData();
    formdata.append("join", true);

    fetch(
        process.env.REACT_APP_MM_API_URL + "organizations/invites/" + invite_id,
        {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formdata,
        }
    )
        .then((response) => {
            window.location.reload();
            return response.json();
        })
        .then((data) => {})
        .catch((error) => {
            console.log(error);
        });
};

export const rejectInvite = (token, invite_id, refresh) => {
    console.log("Rejecting invite", token, invite_id);
    let formdata = new FormData();
    formdata.append("join", false);

    fetch(
        process.env.REACT_APP_MM_API_URL + "organizations/invites/" + invite_id,
        {
            method: "PUT",
            headers: {
                Authorization: `Bearer ${token}`,
            },
            body: formdata,
        }
    )
        .then((response) => {
            toast.success("Rejected invite");
            console.log(response);
            refresh();
            return response.json();
        })
        .then((data) => {})
        .catch((error) => {
            console.log(error);
        });
};
