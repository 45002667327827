import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import AssignmentCard from "../cards/AssignmentCard";
import Paginator from "../../../components/Paginator";

import EmptyAssignmentsPage from "../EmptyAssignmentsPage";
import { Link } from "react-router-dom";
import { Socket } from "phoenix";



const MyStudentProjectsTab = ({
    auth,
    restricted,
    restrictedStudentProjects,
    refreshRooms,
    rooms,
    newRoom,
    showInviteModal,
    showAssignModal,
    handleSearch,
    searchValue,
    sortValue,
    setSortValue,
    page,
    setPage,
    refreshSingleRoom,
    isLoadingRooms,
    roomsError,
    showStudentRoomsModal
}) => {
    const [socket, setSocket] = useState(null);

    return (
        <>
            {isLoadingRooms && (
                <Row className="m-0 p-3 p-lg-4">
                    <Col>
                        <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                    </Col>
                </Row>
            )}

            {!isLoadingRooms && roomsError && (
                <Row className="m-0 p-3 p-lg-4">
                    <Col>
                        <p>{roomsError}</p>
                    </Col>
                </Row>
            )}

            {searchValue ||
            (!isLoadingRooms &&
                !roomsError &&
                rooms &&
                rooms?.results?.length > 0) ? (
                <Container className="p-0 m-0">
                    <Row className="g-3">
                        {rooms &&
                            rooms?.results?.length >= 0 &&
                            rooms?.results?.map((room) => (
                                <AssignmentCard
                                    refresh={refreshRooms}
                                    newRoom={newRoom === room.id}
                                    assignment={room}
                                    socket={socket}
                                    showInviteModal={showInviteModal}
                                    showStudentRoomsModal={
                                        showStudentRoomsModal
                                    }
                                    key={room.id}
                                    auth={auth}
                                />
                            ))}
                    </Row>
                    <Col sm={12} md={12} lg={12} xxl={12} className="mt-3">
                        {searchValue &&
                            rooms?.length >= 1 &&
                            (!rooms ||
                                (rooms?.results?.length <= 0 && (
                                    <Col xs={12}>
                                        <p>
                                            No Student Projects matched your
                                            filters.
                                        </p>
                                        <Button
                                            as={Link}
                                            to="/explore"
                                            variant="success"
                                            className="me-2"
                                        >
                                            Explore Content Library
                                        </Button>
                                        <Button
                                            variant="purple-dark"
                                            onClick={() => null}
                                        >
                                            Reset filters
                                        </Button>
                                    </Col>
                                )))}
                        {searchValue && rooms?.results?.length <= 0 && (
                            <Col xs={12} className="mt-3">
                                <p>No Student Projects matched your filters.</p>
                                <Button
                                    as={Link}
                                    to="/explore"
                                    variant="success"
                                    className="me-2"
                                >
                                    Explore Content Library
                                </Button>
                                <Button
                                    variant="purple-dark"
                                    onClick={() => handleSearch("")}
                                >
                                    Reset filters
                                </Button>
                            </Col>
                        )}
                    </Col>

                    {rooms?.count > 20 && (
                        <Row className="m-0 p-3 p-lg-4">
                            <Paginator
                                items={rooms}
                                page={page}
                                setPage={setPage}
                            />
                        </Row>
                    )}
                </Container>
            ) : (
                <EmptyAssignmentsPage isLoadingRooms={isLoadingRooms} />
            )}
        </>
    );
};

export default MyStudentProjectsTab;
