import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";

import { useAuth0 } from "@auth0/auth0-react";

function EditProfileCard(props) {
    const { logout, getAccessTokenSilently } = useAuth0();

    const [selectedImage, setSelectedImage] = useState(null);

    const [firstname, setFirstname] = useState(props.profile.first_name);
    const [lastName, setLastName] = useState(props.profile.last_name);
    const [isPosting, setIsPosting] = useState(false);

    const handleSubmit = () => {
        setIsPosting(true);

        let formdata = new FormData();
        if (selectedImage !== null) {
            formdata.append("image", selectedImage);
        }
        if (firstname !== props.profile.first_name) {
            formdata.append("first_name", firstname);
        }

        if (lastName !== props.profile.last_name) {
            formdata.append("last_name", lastName);
        }

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:teacher_profile",
        }).then((token) => {
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "teacherprofile/" +
                    props.profile.id +
                    "/",
                {
                    method: "PATCH",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formdata,
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    setIsPosting(false);
                    setSelectedImage(null);
                    toast.success("Succesfully updated.");
                    props.onUpdated();
                })
                .catch((error) => {
                    console.log(error);
                    toast.error("Something went wrong when editing profile.");
                    setIsPosting(false);
                });
        });
    };

    return (
        <Card.Body className="mb-5">
            <Row className="">
                <Form.Group
                    className="mb-4"
                    as={Col}
                    xs={6}
                    controlId="formBasicEmail"
                >
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                        onChange={(e) => setFirstname(e.target.value)}
                        type="text"
                        value={firstname}
                        placeholder=""
                    />
                </Form.Group>
                <Form.Group
                    className="mb-4"
                    as={Col}
                    xs={6}
                    controlId="formBasicEmail"
                >
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                        onChange={(e) => setLastName(e.target.value)}
                        type="text"
                        value={lastName}
                        placeholder=""
                    />
                </Form.Group>
                <Form.Group
                    as={Col}
                    xs={6}
                    className="mb-4"
                    controlId="formBasicEmail"
                >
                    <Form.Label>Change profile picture</Form.Label>
                    <Form.Control
                        onChange={(event) => {
                            setSelectedImage(event.target.files[0]);
                        }}
                        type="file"
                        placeholder="Profile pic"
                    />
                </Form.Group>
            </Row>
            <Button
                variant="success"
                disabled={isPosting ? true : false}
                onClick={handleSubmit}
            >
                {isPosting ? (
                    <>
                        <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                        ></span>
                        <span>Loading...</span>
                    </>
                ) : (
                    <span>Update profile</span>
                )}
            </Button>
            <Button className="ms-2" onClick={() => logout()}>
                Log out
            </Button>
        </Card.Body>
    );
}

export default EditProfileCard;
