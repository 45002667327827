import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import logo from "../Logo.png";

import { useAuth0 } from "@auth0/auth0-react";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { SidebarItem } from "./Sidebar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { useLocation } from "react-router-dom";

export function GuestSidebar(props) {
    const location = useLocation();
    const { pathname } = location;
    const { loginWithRedirect } = useAuth0();

    const openLink = (url) => {
        window.open(url, "_blank");
    };

    const dashIcon = <i className="bi text-purple-dark bi-house-fill"></i>;
    const resourcesIcon = <FontAwesomeIcon color="#6F6EC4" icon={faBook} />;
    const aboutIcon = (
        <i style={{ color: "#6F6EC4" }} className="bi bi-info-circle"></i>
    );
    const worldIcon = (
        <i style={{ color: "#6F6EC4" }} className="bi bi-search"></i>
    );

    return (
        <div
            style={{ width: "330px", listStyle: "none" }}
            className="vh-100 bg-white d-none d-sm-block border"
        >
            <Row className="bg-white pt-4 pb-4 border-bottom">
                <Col className="text-center">
                    <img
                        alt="MegaMinds Logo"
                        width={142}
                        height={80}
                        src={logo}
                    />
                </Col>
            </Row>
            <Row className="p-3 text-left bg-white border-bottom">
                <Col
                    xs={12}
                    className="d-flex justify-content-middle align-items-center"
                >
                    <Button
                        onClick={() =>
                            loginWithRedirect({
                                appState: {
                                    redirectUri: window.location.pathname,
                                    returnTo: window.location.pathname,
                                },
                            })
                        }
                        variant="purple-dark-outline"
                        className="w-100"
                    >
                        Sign in
                    </Button>
                </Col>
                <Col
                    xs={12}
                    className="d-flex justify-content-middle align-items-center mt-2"
                >
                    <Button
                        onClick={() =>
                            loginWithRedirect({
                                appState: {
                                    redirectUri: window.location.pathname,
                                    returnTo: window.location.pathname,
                                },
                                authorizationParams: {
                                    screen_hint: "signup",
                                },
                            })
                        }
                        variant="purple-dark-outline"
                        className="w-100"
                    >
                        Sign up
                    </Button>
                </Col>
            </Row>
            <div
                className="d-flex flex-column justify-content-between overflow-y-auto overflow-x-hidden"
                style={{ height: "calc(100% - 253px)" }}
            >
                <Row>
                    <Col>
                        <SidebarItem
                            activePath={pathname}
                            to="/"
                            text="Home"
                            icon="bi-house-fill"
                        />
                        <SidebarItem
                            activePath={pathname}
                            to="/explore"
                            text="Explore"
                            icon="bi-search"
                        />
                    </Col>
                </Row>
                <Row className="border-top">
                    <Col>
                        <SidebarItem
                            to="/whats-new"
                            icon="bi-rocket-takeoff-fill"
                            text="What's New?"
                            activePath={pathname}
                        />
                        <SidebarItem
                            onClick={() =>
                                openLink("https://megaminds.tawk.help/")
                            }
                            icon="bi-book"
                            text="Help & Resources"
                        />
                        <SidebarItem
                            onClick={() => openLink("https://gomegaminds.com/")}
                            icon="bi-info-circle"
                            text="About MegaMinds"
                        />
                    </Col>
                </Row>
            </div>
        </div>
    );
}
