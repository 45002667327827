import React, { useState } from "react";
import Container from "react-bootstrap/Container";
import logo from "../Logo.png";
import Button from "react-bootstrap/Button";
import { useAuth0 } from "@auth0/auth0-react";

import SelectType from "./onboarding/steps/SelectType";

import TeacherProfile from "./onboarding/steps/TeacherProfile";
import PersonalProfile from "./onboarding/steps/PersonalProfile";
import ProfessionalProfile from "./onboarding/steps/ProfessionalProfile";

import Navbar from "react-bootstrap/Navbar";

export default function OnboardingFlow(props) {
    const { getAccessTokenSilently, user, logout } = useAuth0();

    const [step, setStep] = useState("select_type");

    const [accountType, setAccountType] = useState("select_type");

    const onFinishedProfile = () => {
        window.location.reload();
    };

    return (
        <>
            <Navbar bg="white" className="shadow-sm" expand="lg">
                <Container className="d-flex">
                    <Navbar.Brand>
                        <img
                            alt="MegaMinds Logo"
                            height="50px"
                            className=""
                            src={logo}
                        />
                    </Navbar.Brand>
                    <Navbar.Collapse className="d-flex justify-content-end align-items-middle pe-3">
                        <Navbar.Text className="me-3">{user.email}</Navbar.Text>
                        <Button
                            onClick={() => logout()}
                            className="btn btn-light"
                        >
                            Log out
                        </Button>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
            <TeacherProfile
                user={user}
                getAccessTokenSilently={getAccessTokenSilently}
                onComplete={onFinishedProfile}
                setStep={setStep}
            />
        </>
    );
}
