import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import { Tooltip } from "react-tooltip";

const CreateSellerProfile = (props) => {
    const { getAccessTokenSilently } = useAuth0();

    const [name, setName] = useState("");
    const [bio, setBio] = useState("");
    const [selectedImage, setSelectedImage] = useState(null);

    const handleSubmit = (event) => {
        let payload = new FormData();

        if (selectedImage !== null) {
            payload.append("image", selectedImage);
        } else {
            return;
        }

        payload.append("public_name", name);
        payload.append("biography", bio);

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "create:classroom",
        }).then((token) => {
            fetch(
                process.env.REACT_APP_MM_API_URL + "templates/seller/create",
                {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: payload,
                }
            )
                .then((res) => res.json())
                .then((data) => {
                    console.log(data);
                    props.refreshProfile();
                })
                .catch((e) => {
                    console.error(e);
                });
        });
    };

    return (
        <Container className="p-5 bg-light">
            <Card className="dash-card ">
                <Tooltip id="info-form" />
                <Card.Header className="ps-4 bg-white">
                    <Card.Title className="pt-2">Public Profile</Card.Title>
                </Card.Header>
                <Card.Body className="ps-4 pe-4">
                    <Alert bg="success">
                        <p>
                            <i className="bi bi-info-circle me-2"></i>
                            In order to publish rooms on the Explore page, you
                            need to set up a public facing profile.
                        </p>
                        <p className="mb-0">
                            Only the information on your Public Profile will be
                            visible to those who browse the Explore page.
                        </p>
                    </Alert>
                </Card.Body>
                <Card.Body className="ps-4 pe-4">
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                        <Form.Label>Your Name</Form.Label>
                        <Form.Control
                            required
                            autoFocus
                            onChange={(e) => setName(e.target.value)}
                            value={name}
                            type="text"
                            placeholder="Jane Doe"
                        />
                        <Form.Control.Feedback>
                            Looks Good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            You need a title for your room
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="formBasicEmail">
                        <Form.Label>
                            A short bio
                            <i
                                data-tooltip-id="info-form"
                                data-tooltip-content="If people click to see more about your profile, this will be shown."
                                className="ms-2 bi bi-info-circle"
                            ></i>
                        </Form.Label>
                        <Form.Control
                            required
                            value={bio}
                            onChange={(e) => setBio(e.target.value)}
                            type="text"
                            placeholder="I am a teacher who loves math!"
                        />
                        <Form.Control.Feedback>
                            Looks Good!
                        </Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            You need a description for your room.
                        </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>
                            Profile Picture
                            <i
                                data-tooltip-id="info-form"
                                data-tooltip-content="Set an image to be shown next to your content."
                                className="ms-2 bi bi-info-circle"
                            ></i>
                        </Form.Label>
                        <Form.Control
                            onChange={(event) => {
                                setSelectedImage(event.target.files[0]);
                            }}
                            type="file"
                            placeholder="Profile pic"
                        />
                    </Form.Group>
                </Card.Body>
                <Card.Footer className="ps-4 pe-4 p-4">
                    <Row>
                        <Col className="d-flex justify-content-between">
                            {name !== "" && bio !== "" && selectedImage !== null ? (
                                <Button
                                    variant="success"
                                    onClick={handleSubmit}
                                >
                                    Continue
                                </Button>
                            ) : (
                                <Button disabled variant="success">
                                    You need to fill out the fields
                                </Button>
                            )}
                        </Col>
                    </Row>
                </Card.Footer>
            </Card>
        </Container>
    );
};

export default CreateSellerProfile;
