import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Table from "react-bootstrap/Table";
import Card from "react-bootstrap/Card";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "react-hot-toast";

function ManageUsersModal(props) {
    console.log("Checking props");
    if(!props.organisation) return;
    const [show, setShow] = useState(false);
    const [isPosting, setIsPosting] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const promoteUser = (user_id, newRole) => {
        console.log("Attempting to promtote", user_id);
        setIsPosting(true);
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:teacher_profile",
        }).then((token) => {
            let formdata = new FormData();
            formdata.append("newRole", newRole);

            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "organizations/memberships/" +
                    user_id,
                {
                    method: "PATCH",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formdata,
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log(data);
                    toast.success("Role change successful");
                    props.update();
                    setIsPosting(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    };

    const kickUser = (user_id) => {
        console.log("Attempting to kick", user_id);
        setIsPosting(true);
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:teacher_profile",
        }).then((token) => {
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "organizations/memberships/" +
                    user_id,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    toast.success("User removed from organization");
                    props.update();
                    setIsPosting(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    };

    const revokeInvite = (invite_id) => {
        setIsPosting(true);
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:teacher_profile",
        }).then((token) => {
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "organizations/invites/" +
                    invite_id,
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    toast.success("Revoked invite successfully.");
                    props.update();
                    setIsPosting(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        });
    };

    return (
        <>
            <Button
                className="btn-sm me-3"
                variant="outline-secondary"
                onClick={handleShow}
            >
                Manage Users
            </Button>

            <Modal show={show} size="xl" onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Manage {props.organization.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Card className="mt-5">
                        <Card.Header>Invitations</Card.Header>
                        <Card.Body className="p-3">
                            {typeof props.organizaiton.invites !== "string" && props.organization.invites.length > 0 ? (
                                <Table hover>
                                    <thead>
                                        <tr>
                                            <th>To</th>
                                            <th>From</th>
                                            <th>Role</th>
                                            <th>Status</th>
                                            <th>Uninvite</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {props.organization.invites &&
                                            props.organization.invites.map(
                                                (invite) => (
                                                    <>
                                                        {!invite.accepted && (
                                                            <tr
                                                                key={invite.id}
                                                                className="text-truncate"
                                                            >
                                                                <td>
                                                                    {
                                                                        invite.email
                                                                    }
                                                                </td>
                                                                <td className="text-truncate">
                                                                    {
                                                                        invite
                                                                            .inviter
                                                                            .first_name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {
                                                                        invite
                                                                            .role
                                                                            .name
                                                                    }
                                                                </td>
                                                                <td>
                                                                    {invite.accepted ? (
                                                                        <p>
                                                                            Accepted
                                                                        </p>
                                                                    ) : (
                                                                        <>
                                                                            {invite.rejected ? (
                                                                                <p>
                                                                                    Rejected
                                                                                </p>
                                                                            ) : (
                                                                                <p>
                                                                                    Pending
                                                                                </p>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </td>
                                                                <td>
                                                                    {(!invite.rejected ||
                                                                        !invite.accepted) && (
                                                                        <>
                                                                            {isPosting ? (
                                                                                <Button
                                                                                    variant="danger"
                                                                                    size="sm"
                                                                                    disabled
                                                                                >
                                                                                    Uninvite
                                                                                </Button>
                                                                            ) : (
                                                                                <Button
                                                                                    size="sm"
                                                                                    variant="danger"
                                                                                    onClick={() =>
                                                                                        revokeInvite(
                                                                                            invite.id
                                                                                        )
                                                                                    }
                                                                                >
                                                                                    Uninvite
                                                                                </Button>
                                                                            )}
                                                                        </>
                                                                    )}
                                                                </td>
                                                            </tr>
                                                        )}
                                                    </>
                                                )
                                            )}
                                    </tbody>
                                </Table>
                            ) : (
                                <p className="mb-0">No invites yet.</p>
                            )}
                        </Card.Body>
                    </Card>
                    <Card className="mt-5">
                        <Card.Header>Members</Card.Header>
                        <Card.Body className="p-3">
                            <Table hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Change role</th>
                                        <th>Kick</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {props.organization.members.map(
                                        (member) => (
                                            <tr key={member.id}>
                                                <td>
                                                    {member.user.first_name}
                                                </td>
                                                {member.is_owner ? (
                                                    <>
                                                        <td>
                                                            <small className="fw-light">
                                                                Cannot edit
                                                                owner
                                                            </small>
                                                        </td>
                                                        <td>
                                                            <small className="fw-light">
                                                                Cannot edit
                                                                owner
                                                            </small>
                                                        </td>
                                                    </>
                                                ) : (
                                                    <>
                                                        <td>
                                                            {member.role
                                                                .name !==
                                                            "admin" ? (
                                                                <Button
                                                                    variant="secondary"
                                                                    size="sm"
                                                                    onClick={() =>
                                                                        promoteUser(
                                                                            member.id,
                                                                            "admin"
                                                                        )
                                                                    }
                                                                >
                                                                    Promote to
                                                                    Administrator
                                                                </Button>
                                                            ) : (
                                                                <>
                                                                    <Button
                                                                        variant="secondary"
                                                                        size="sm"
                                                                        onClick={() =>
                                                                            promoteUser(
                                                                                member.id,
                                                                                "teacher"
                                                                            )
                                                                        }
                                                                    >
                                                                        Demote
                                                                        to
                                                                        Teacher
                                                                    </Button>
                                                                </>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {member.role
                                                                .name !==
                                                            "admin" ? (
                                                                <Button
                                                                    variant="danger"
                                                                    size="sm"
                                                                    onClick={() =>
                                                                        kickUser(
                                                                            member.id
                                                                        )
                                                                    }
                                                                >
                                                                    Kick from
                                                                    team
                                                                </Button>
                                                            ) : (
                                                                <small className="fw-light">
                                                                    Cannot kick
                                                                    admins
                                                                </small>
                                                            )}
                                                        </td>
                                                    </>
                                                )}
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default ManageUsersModal;
