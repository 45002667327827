import React, { useState, useEffect } from "react";

import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import SceneBrowserCard from "./cards/SceneBrowserCard";
import Paginator from "../../components/Paginator";
import useDashApi from "../../services/useDashApi";
import { useAuth } from "../../context/AuthContext";

const MyScenesBrowser = ({
    goBack,
    onFinished,
    setScene,
    selectedMode,
    show,
    selectedScene,
    setSelectedScene,
    setShow,
}) => {
    const auth = useAuth();
    const [page, setPage] = useState(1);

    const [scenes, isLoadingScenes, sceneError] = useDashApi("scenes/", auth);

    console.log(scenes);

    useEffect(() => {
        if (scenes && !selectedScene) {
            setSelectedScene(scenes[2]);
        }
    }, [scenes, setScene]);

    if (sceneError) {
        return <p>Error loading designs.</p>;
    }

    return (
        <Modal size="lg" fullscreen="md-down" show={show} scrollable onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>
                    <p>Select a design</p>
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mb-4 g-4">
                    {!isLoadingScenes &&
                        scenes.map((scene) => (
                            <Col xs={6} md={4}>
                                <SceneBrowserCard
                                    scene={scene}
                                    onSelect={() => onFinished(scene)}
                                />
                            </Col>
                        ))}
                </Row>
                {!isLoadingScenes && (
                    <Row className="mb-4 g-4">
                        <Col>
                            <Paginator
                                items={scenes}
                                page={page}
                                setPage={setPage}
                            />
                        </Col>
                    </Row>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default MyScenesBrowser;
