import React, { useState, useCallback, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";

import { useAuth0 } from "@auth0/auth0-react";

function RoomPermissionsModal(props) {
    const { getAccessTokenSilently } = useAuth0();

    const [voice, setVoice] = useState(
        props.organization.deny_microphone
    );
    const [react, setReact] = useState(props.organization.deny_reactions);
    const [chat, setChat] = useState(props.organization.deny_chat);
    const [hand, setHand] = useState(props.organization.deny_raise_hand);
    const [video, setVideo] = useState(
        props.organization.deny_video
    );

    const [isPosting, setIsPosting] = useState(false);
    const [newLink, setNewLink] = useState("");
    const [isPostingLink, setIsPostingLink] = useState(false);

    const handleClose = () => {
        props.setShow(false);
    };

    const handleSubmit = () => {
        setIsPosting(true);
        let payload = {
            deny_chat: chat,
            deny_reactions: react,
            deny_raise_hand: hand,
            deny_microphone: voice,
            deny_video: video,
        };

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "organizations/" +
                    props.organization.id,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                    props.setShow(false);
                    setIsPosting(false);
                    toast.success("Changes were saved.");
                })
                .catch((error) => {
                    toast.error("Something went wrong while saving.");
                    console.error(error);
                    setIsPosting(false);
                });
        });
    };

    return (
        <Modal centered show={props.show} scrollable onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Disable Features</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="info">
                    <p className="">
                        <i className="bi bi-info-circle me-2"></i>
                        <span className="fw-bold">Room Permissions</span> decide
                        what students can do inside of MegaMinds 3D Spaces.
                    </p>
                    <p className="mb-0">
                        This menu allows administrators to disable specific
                        features. If left enabled, teachers are able to decide
                        for themselves.
                    </p>
                </Alert>
                <h5 className="mt-4">Permissions</h5>
                <div className="">
                    <Form>
                        <Form.Check
                            type="switch"
                            id="chat"
                            label="Disable Student Text Chat Feature"
                            onChange={() => setChat(!chat)}
                            checked={chat}
                        />
                        <Form.Check
                            type="switch"
                            id="reactions"
                            label="Disable Student React with Emojis Feature"
                            onChange={() => setReact(!react)}
                            checked={react}
                        />
                        <Form.Check
                            type="switch"
                            id="hand"
                            label="Disable Raise Hand feature"
                            onChange={() => setHand(!hand)}
                            checked={hand}
                        />
                        <Form.Check
                            type="switch"
                            id="voice"
                            label="Disable Voice Chat over Microphone"
                            checked={voice}
                            onChange={() => setVoice(!voice)}
                        />
                        <Form.Check
                            type="switch"
                            id="share"
                            label="Disable Webcam and Screenshare Feature"
                            checked={video}
                            onChange={() => setVideo(!video)}
                        />
                    </Form>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Discard
                </Button>
                <Button
                    variant="success"
                    disabled={isPosting ? true : false}
                    onClick={handleSubmit}
                >
                    {isPosting ? (
                        <>
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                            <span>Loading...</span>
                        </>
                    ) : (
                        <span>Save changes</span>
                    )}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RoomPermissionsModal;
