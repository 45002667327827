import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const Search = ({ tempSearchValue, setTempSearchValue, setSearchValue }) => {
    return (
        <Row className="">
            <Col xs={12} className="d-flex justify-content-start">
                <InputGroup>
                    <Form.Control
                        type="text"
                        placeholder="Search Experiences"
                        aria-label="Filter results by name"
                        aria-describedby="btnGroupAddon"
                        className="border-0 border-start border-top border-bottom"
                        value={tempSearchValue}
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                setSearchValue(tempSearchValue);
                            }
                        }}
                        onChange={(e) => {
                            setTempSearchValue(e.target.value);
                        }}
                    />
                    {tempSearchValue && (
                        <Button
                            variant=""
                            className="border-0 border-top border-bottom text-secondary"
                            onClick={() => {
                                setTempSearchValue("");
                                setSearchValue("");
                            }}
                        >
                            <i class="bi bi-x-circle-fill"></i>
                        </Button>
                    )}
                    <Button
                        variant="outline-secondary"
                        className="border"
                        onClick={() => setSearchValue(tempSearchValue)}
                    >
                        <i className="bi bi-search"></i>
                    </Button>
                </InputGroup>
            </Col>
        </Row>
    );
};

export default Search;
