import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { contains } from "./utils";

const Grades = ({ grades, selectedGrades, setSelectedGrades }) => {
    const handleGradesCheck = (grade) => {
        const isChecked = contains(selectedGrades, grade);

        if (isChecked) {
            // Remove from list
            const cleanedGrades = selectedGrades.filter(
                (oldGrade) => oldGrade.id !== grade.id
            );
            setSelectedGrades(cleanedGrades);
        } else {
            // Add to list
            setSelectedGrades([...selectedGrades, grade]);
        }
    };

    let gradeArray = grades;
    let halfwayThrough = Math.floor(gradeArray.length / 2);

    let arrayFirstHalf = gradeArray.slice(0, halfwayThrough);
    let arraySecondHalf = gradeArray.slice(halfwayThrough, gradeArray.length);

    return (
        <Row className="mt-4">
            {/* Grades */}
            <Col xs={12}>
                <p className="fw-bold">Grades</p>
            </Col>
            <Col xs={12} className="d-flex justify-content-start">
                <Row>
                    <Col xs={6}>
                        {arrayFirstHalf.map((grade) => {
                            return (
                                <Col xs={12}>
                                    <Form.Check
                                        id={"grade-" + grade.title}
                                        onChange={() => {
                                            handleGradesCheck(grade);
                                        }}
                                        checked={contains(
                                            selectedGrades,
                                            grade
                                        )}
                                        label={grade.title}
                                    />
                                </Col>
                            );
                        })}
                    </Col>
                    <Col xs={6}>
                        {arraySecondHalf.map((grade) => {
                            return (
                                <Col xs={12}>
                                    <Form.Check
                                        onChange={() => {
                                            handleGradesCheck(grade);
                                        }}
                                        id={"grade-" + grade.title}
                                        checked={contains(
                                            selectedGrades,
                                            grade
                                        )}
                                        label={grade.title}
                                    />
                                </Col>
                            );
                        })}
                    </Col>
                </Row>
            </Col>
            <Col />
        </Row>
    );
};

export default Grades;
