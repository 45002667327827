import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import { toast } from "react-hot-toast";
import GoogleShareToClassRoom from "google-classroom-share";
import { useAuth0 } from "@auth0/auth0-react";
import QRCode from "qrcode";
import { QRCodeSVG } from "qrcode.react";

const InviteModal = (props) => {
    const handleClose = () => props.setShow(false);
    const [isPosting, setIsPosting] = useState("toggle");
    const [qrData, setQRData] = useState("");
    const [hugeQR, setHugeQR] = useState(false);
    const [isLocked, setIsLocked] = useState(false);
    const [showQR, setShowQR] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    useEffect(() => {
        if (props.selectedClassroom) {
            if (props.selectedClassroom.locked) {
                setIsLocked(true);
            }
        }
    }, [props.selectedClassroom]);

    const copyToClipboard = () => {
        const clipBoard = navigator.clipboard;
        clipBoard
            .writeText(
                process.env.REACT_APP_3D_ENDPOINT +
                    "/" +
                    props.selectedClassroom.hub_id
            )
            .then(() => {
                toast.success("Link was copied");
            });
    };

    const unlockRoom = () => {
        setIsPosting(true);
        let payload = {
            locked: false,
        };

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "classrooms/" +
                    props.selectedClassroom.hub_id +
                    "/",
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                    props.refresh();
                    setIsLocked(false);
                    setIsPosting(false);
                    toast.success("Lesson was unlocked");
                })
                .catch((error) => {
                    toast.error(
                        "Something went wrong while unlocking the Lesson."
                    );
                    console.error(error);
                    setIsPosting(false);
                });
        });
    };

    useEffect(() => {
        QRCode.toDataURL(
            process.env.REACT_APP_3D_ENDPOINT +
                "/" +
                props.selectedClassroom?.hub_id,
            function (err, url) {
                console.log(url);
                setQRData(url);
            }
        );
    }, []);

    if (!props.selectedClassroom) return null;

    return (
        <Modal
            size={hugeQR ? "xl" : "md"}
            fullscreen={hugeQR ? true : "md-down"}
            show={props.show}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>Invite</Modal.Title>
            </Modal.Header>
            {!showQR ? (
                <Modal.Body>
                    {!isLocked ? (
                        <Row className="d-flex justify-content-center align-items-center">
                            <Col xs={12} className="text-center mb-5">
                                <Alert variant="success" className="mb-0 p-3">
                                    <p className="fw-bold">Share this link</p>
                                    <a
                                        target="_blank"
                                        rel="noreferrer noopener"
                                        href={
                                            process.env.REACT_APP_3D_ENDPOINT +
                                            "/" +
                                            props.selectedClassroom?.hub_id
                                        }
                                        className="mb-0"
                                    >
                                        <span className="fw-bold">
                                            {process.env.REACT_APP_3D_ENDPOINT +
                                                "/" +
                                                props.selectedClassroom?.hub_id}
                                        </span>
                                    </a>
                                    <p className="mt-4 mb-0 fw-light">
                                        <small>
                                            When your users click the link, they
                                            will join your Lesson.
                                        </small>
                                    </p>
                                </Alert>
                            </Col>
                            <Col className="text-center">
                                <Button
                                    onClick={() => copyToClipboard()}
                                    variant="secondary"
                                >
                                    <i
                                        style={{
                                            fontSize: "1.2rem",
                                        }}
                                        className="bi bi-link-45deg"
                                    ></i>
                                </Button>
                                <p className="mt-2 fw-light">
                                    <small>Copy Link</small>
                                </p>
                            </Col>
                            <Col className="text-center">
                                <GoogleShareToClassRoom
                                    as={Button}
                                    size={50}
                                    body={
                                        "Join others in " +
                                        props.selectedClassroom.title
                                    }
                                    itemType={"announcement"}
                                    url={
                                        process.env.REACT_APP_3D_ENDPOINT +
                                        "/" +
                                        props.selectedClassroom?.hub_id
                                    }
                                    title={
                                        "Invitation to virtual lesson in MegaMinds"
                                    }
                                    theme="classic"
                                    onShare={(type) =>
                                        console.log(
                                            `GoogleShareToClassRoom:${type}`
                                        )
                                    }
                                    onShareComplete={() =>
                                        console.log(
                                            "GoogleShareToClassRoom:onShareComplete"
                                        )
                                    }
                                    onShareStart={() =>
                                        console.log(
                                            "GoogleShareToClassRoom:onShareStart"
                                        )
                                    }
                                />
                                <p className="mt-2 fw-light">
                                    <small>Google Classroom</small>
                                </p>
                            </Col>
                            <Col className="text-center">
                                <Button
                                    href={
                                        "mailto:someone@example.com?subject=Join%20a%20room%20in%20MegaMinds&body=Join%20a%20room%20in%20MegaMinds!%0D%0A%0D%0A" +
                                        process.env.REACT_APP_3D_ENDPOINT +
                                        "/" +
                                        props.selectedClassroom.hub_id
                                    }
                                    variant="secondary"
                                >
                                    <i
                                        style={{
                                            fontSize: "1.2rem",
                                        }}
                                        className="bi bi-envelope-at"
                                    ></i>
                                </Button>
                                <p className="mt-2 fw-light">
                                    <small>Send as Email</small>
                                </p>
                            </Col>
                            <Col className="text-center">
                                <Button
                                    variant="secondary"
                                    onClick={() => setShowQR(true)}
                                >
                                    <i
                                        style={{
                                            fontSize: "1.2rem",
                                        }}
                                        className="bi bi-qr-code"
                                    ></i>
                                </Button>
                                <p className="mt-2 fw-light">
                                    <small>Show QR Code</small>
                                </p>
                            </Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col>
                                <p>
                                    This Lesson is currently locked! Disable Lock
                                    to let other users join your Lesson.
                                </p>
                                <Button
                                    variant="success"
                                    disabled={!isPosting}
                                    onClick={() => unlockRoom()}
                                >
                                    Unlock
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Modal.Body>
            ) : (
                <Modal.Body className="d-flex justify-content-center align-items-center flex-column">
                    <Row className={hugeQR ? "p-0 m-0" : "mt-5 mb-5"}>
                        <Col>
                            <QRCodeSVG
                                size={hugeQR ? 512 : 128}
                                className="img-fluid"
                                value={
                                    process.env.REACT_APP_3D_ENDPOINT +
                                    "/" +
                                    props.selectedClassroom.hub_id
                                }
                            />
                        </Col>
                    </Row>
                    {!hugeQR && (
                        <>
                            <Row className="mt-2 mb-2">
                                <Col>
                                    <a
                                        download={
                                            props.selectedClassroom.title +
                                            ".png"
                                        }
                                        href={qrData}
                                    >
                                        Download as Image
                                    </a>
                                </Col>
                            </Row>
                            <Row className="mt-2 mb-2">
                                <Col>
                                    <Button
                                        onClick={() => setHugeQR(true)}
                                        variant="link"
                                    >
                                        Make Huge
                                    </Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </Modal.Body>
            )}
            <Modal.Footer>
                {hugeQR && (
                    <>
                        <a
                            download={props.selectedClassroom.title + ".png"}
                            href={qrData}
                        >
                            Download as Image
                        </a>
                        <Button onClick={() => setHugeQR(false)} variant="link">
                            Make Small
                        </Button>
                    </>
                )}
                {showQR ? (
                    <Button
                        variant="secondary"
                        onClick={() => {
                            setHugeQR(false);
                            setShowQR(false);
                        }}
                    >
                        Back
                    </Button>
                ) : (
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                )}
            </Modal.Footer>
        </Modal>
    );
};

export default InviteModal;
