import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { getPicture } from "../../utils/get-picture";

import { toast } from "react-hot-toast";
import TemplateCard from "./cards/TemplateCard";
import BundleOnlyTemplateCard from "./cards/BundleOnlyTemplateCard";
import CreateFromBundleModal from "./CreateFromBundleModal";
import useDashApiGuest from "../../services/useDashApiGuest";
import { useAuth } from "../../context/AuthContext";

const getFiletypeIcon = (filename) => {
    return <i className="bi bi-filetype-pdf"></i>;
};

const BundleDetail = (props) => {
    const { id } = useParams();

    const iframeRef = useRef(null);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [promoLink, setPromoLink] = useState(null);

    const auth = useAuth();
    const signedIn = !!(!auth.isLoading && auth.profile);

    const [bundle, isLoading, isError, refreshBundle] = useDashApiGuest(
        "templates/bundles/" + id
    );

    useEffect(() => {
        if (!isLoading && !isError && bundle?.youtube_promo_link) {
            fetch(
                "https://youtube.com/oembed?url=" +
                    bundle.youtube_promo_link +
                    "&format=json"
            )
                .then((resp) => resp.json())
                .then((data) => {
                    var src = data.html
                        .split("src=")[1]
                        .split(/[ >]/)[0]
                        .replace('"', "");
                    setPromoLink(src);
                });
        }
    }, [isLoading, isError, bundle]);

    if (isLoading) {
        return <p>Loading</p>;
    }

    if (isError) {
        console.error(isError);
        return <p>Error loading Lesson data</p>;
    }

    const copyToClipboard = () => {
        navigator.clipboard.writeText(window.location);
        toast.success("Link copied!");
    };

    return (
        <>
            <Container fluid className="g-0 bg-light">
                <Row className="bg-white p-3 p-lg-4 border-bottom m-0">
                    <Link to="/explore">
                        <i className="bi bi-arrow-left me-2"></i>Explore
                    </Link>
                </Row>
            </Container>
            <Container className="g-0 bg-light">
                <CreateFromBundleModal
                    show={showCreateModal}
                    exclusive={bundle.only_bundle}
                    guides={bundle.bundleattachment_set}
                    setShowModal={setShowCreateModal}
                    onUpdate={refreshBundle}
                    bundle={bundle}
                />
                <Row className="bg-light m-0 mt-0">
                    {bundle.image && (
                        <img
                            alt="Promotional for this collection"
                            className="m-0 p-0 img-fluid"
                            style={{ maxHeight: "350px" }}
                            src={getPicture(bundle.image)}
                        />
                    )}
                </Row>
                <Row className="bg-light g-3 mt-2 m-3">
                    <Col
                        xs={12}
                        md={6}
                        className="d-flex justify-content-start align-items-center"
                    >
                        <h2>{bundle.title}</h2>
                    </Col>
                    <Col md={12} className="">
                        <Button
                            variant="success"
                            className="text-start me-2 mt-2"
                            onClick={() => setShowCreateModal(true)}
                        >
                            <FontAwesomeIcon icon={faPlus} fixedWidth />
                            <span className="ms-3">
                                {signedIn
                                    ? bundle.only_bundle
                                        ? "Get started"
                                        : "Add Bundle to My Account"
                                    : "Get Started"}
                            </span>
                        </Button>
                        <Button
                            variant="purple-dark"
                            className="text-start me-2 mt-2"
                            onClick={() => copyToClipboard()}
                        >
                            <i className="bi bi-share-fill"></i>
                            <span className="ms-3">Share</span>
                        </Button>
                        {/*
                        <Button
                            variant="purple-dark"
                            className="text-start"
                            onClick={() => setShowEditModal(true)}
                        >
                            <i class="bi bi-pencil-fill"></i>
                            <span className="ms-3">Edit Listing</span>
                        </Button>
                        */}
                    </Col>
                </Row>
                <Row className="bg-light m-3">
                    <Col xs={12} md={6} className="p-3">
                        <h5 className="fw-bold">About</h5>
                        <p style={{ whiteSpace: "pre-wrap" }}>
                            {bundle.description}
                        </p>
                        {bundle.youtube_promo_link && (
                            <div className="mt-4">
                                <h5 className="fw-bold mb-4">Video</h5>
                                <iframe
                                    title="Promo video"
                                    className="embed-responsive embed-responsive16by9 w-100 mb-2"
                                    width="100%"
                                    height="400px"
                                    src={promoLink}
                                    ref={iframeRef}
                                />
                            </div>
                        )}
                    </Col>
                    <Col className="p-3">
                        <Row>
                            <Col xs={12}>
                                <h5 className="fw-bold">Details</h5>
                            </Col>
                            <Col>
                                <ListGroup>
                                    {bundle.grade && (
                                        <ListGroup.Item>
                                            <Row>
                                                <Col xs={1}>
                                                    <i className="bi bi-backpack"></i>
                                                </Col>
                                                <Col className="fw-light">
                                                    {bundle.grade}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    )}
                                    {bundle.resource_type && (
                                        <ListGroup.Item>
                                            <Row>
                                                <Col xs={1}>
                                                    <i className="bi bi-book"></i>
                                                </Col>
                                                <Col className="fw-light">
                                                    {bundle.resource_type}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    )}
                                    {bundle.recommended_users && (
                                        <ListGroup.Item>
                                            <Row>
                                                <Col xs={1}>
                                                    <i class="bi bi-people-fill"></i>
                                                </Col>
                                                <Col className="fw-light">
                                                    {bundle.recommended_users}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    )}
                                    {bundle.recommended_devices && (
                                        <ListGroup.Item>
                                            <Row>
                                                <Col xs={1}>
                                                    <i class="bi bi-laptop"></i>
                                                </Col>
                                                <Col className="fw-light">
                                                    {bundle.recommended_devices}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    )}
                                </ListGroup>
                            </Col>
                        </Row>
                        {bundle.bundleattachment_set.length !== 0 && (
                            <Row className="mt-4">
                                <Col xs={12}>
                                    <h5 className="fw-bold">Attachments</h5>
                                </Col>
                                <Col xs={12}>
                                    <ListGroup>
                                        {bundle.bundleattachment_set.map(
                                            (attachement) => (
                                                <ListGroup.Item>
                                                    <Row>
                                                        <Col xs={12}>
                                                            <span className="pe-3">
                                                                {getFiletypeIcon(
                                                                    attachement.filename
                                                                )}
                                                            </span>
                                                            <a
                                                                href={
                                                                    attachement.file
                                                                }
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                variant="link"
                                                                className="m-0 p-0"
                                                                download
                                                            >
                                                                {
                                                                    attachement.filename
                                                                }
                                                            </a>
                                                        </Col>
                                                    </Row>
                                                </ListGroup.Item>
                                            )
                                        )}
                                    </ListGroup>
                                </Col>
                            </Row>
                        )}
                        <Row className="bg-light mt-4">
                            <h5 className="fw-bold">Included Lessons:</h5>
                            {bundle.only_bundle &&
                                bundle.template_set.map((template) => (
                                    <BundleOnlyTemplateCard
                                        template={template}
                                        horizontal
                                        setShowCreateModal={setShowCreateModal}
                                    />
                                ))}
                            {!bundle.only_bundle &&
                                bundle.template_set.map((template) => (
                                    <TemplateCard
                                        template={template}
                                        horizontal
                                        setShowCreateModal={setShowCreateModal}
                                    />
                                ))}
                        </Row>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

export default BundleDetail;
