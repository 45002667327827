import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import Form from "react-bootstrap/Form";

import { toast } from "react-hot-toast";
import { useAuth0 } from "@auth0/auth0-react";
import {
    CardElement,
    Elements,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

const CardForm = (props) => {
    const stripe = useStripe();
    const elements = useElements();
    const { getAccessTokenSilently } = useAuth0();

    const [loadingText, setLoadingText] = useState("Update");
    const [errorText, setErrorText] = useState("");

    const getUpdateEndpoint = () => {
        if (props.organization) {
            return (
                process.env.REACT_APP_MM_API_URL +
                "payments/organization/update-card" +
                props.organization.id
            );
        } else {
            return (
                process.env.REACT_APP_MM_API_URL +
                "payments/subscription/update-card"
            );
        }
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoadingText("Creating payment method");
        let card = elements.getElement(CardElement);

        if (elements == null) {
            return;
        }

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: card,
        });

        setLoadingText("Updating card");

        if (error) {
            setLoadingText("Update");
            setErrorText(error);
        }

        if (!error) {
            try {
                const { id } = paymentMethod;

                const payload = {
                    paymentMethod: id,
                    subscription: props.subscription,
                    customer: props.customer,
                };

                console.log("PAYMENT METHOD OK");

                getAccessTokenSilently({
                    audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                    scope: "update:classroom",
                }).then((token) => {
                    fetch(getUpdateEndpoint(), {
                        method: "POST",
                        headers: {
                            "Content-Type": "application/json",
                            Authorization: `Bearer ${token}`,
                        },
                        body: JSON.stringify(payload),
                    })
                        .then((response) => {
                            return response.json();
                        })
                        .then((data) => {
                            stripe
                                .confirmSetup({
                                    clientSecret: data.client_secret,
                                    redirect: "if_required",
                                    confirmParams: {
                                        // Return URL where the customer should be redirected after the SetupIntent is confirmed.
                                        return_url: window.location.href,
                                    },
                                })
                                .then(function (result) {
                                    if (result.error) {
                                        // Inform the customer that there was an error.
                                        setErrorText(result.error);
                                        setLoadingText("Update");
                                        return;
                                    } else {
                                        toast.success(
                                            "Card was updated successfully"
                                        );
                                        setLoadingText("Update");
                                        props.onFinish();
                                    }
                                });
                        })
                        .catch((error) => {
                            setErrorText(error);
                            setLoadingText("Update");
                        });
                });
            } catch (error) {
                props.setError(true);
                console.log("Error", error);
            }
        } else {
            props.setError(true);
            console.log(error.message);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="form-control">
                <CardElement className="" />
            </div>
            <Row>
                <Form.Label className="mt-4">
                    <Col>
                        <p>{errorText.message}</p>
                    </Col>
                </Form.Label>
            </Row>
            <Button
                variant="success"
                className="mt-4"
                type="submit"
                disabled={loadingText !== "Update"}
            >
                {loadingText}
            </Button>
            <Button
                variant="secondary"
                className="mt-4 ms-2"
                onClick={() => props.goBack()}
                disabled={loadingText !== "Update"}
            >
                Back
            </Button>
        </form>
    );
};

const getRetrieveEndpoint = (organization) => {
    if (organization) {
        return (
            process.env.REACT_APP_MM_API_URL +
            "payments/organization/retrieve/" +
            organization.id
        );
    } else {
        return (
            process.env.REACT_APP_MM_API_URL + "payments/subscription/retrieve"
        );
    }
};

export const ManageSubscriptionModal = (props) => {
    const [subscription, setSubscription] = useState(null);
    const [isSubscriptionLoading, setSubscriptionLoading] = useState(true);
    const [isFutureInvoiceLoading, setIsFutureInvoiceLoading] = useState(true);
    const [futureInvoice, setFutureInvoice] = useState(
        props.organization ? true : false
    );

    const { getAccessTokenSilently } = useAuth0();
    const [showCancelMenu, setShowCancelMenu] = useState(false);
    const [newQuantity, setNewQuantity] = useState(
        props.organization?.members.length
    );
    const [quantityInfo, setQuantityInfo] = useState(null);
    const [showUpdateQuantityMenu, setShowUpdateQuantityMenu] = useState(false);
    const [quantityDisabled, setQuantityDisabled] = useState(false);
    const [showUpdateCycleMenu, setShowUpdateCycleMenu] = useState(false);
    const [showUpdateCardMenu, setShowUpdateCardMenu] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    const [isPostingReactivate, setIsPostingReactivate] = useState(false);
    const [isSubmittingIntervalUpdate, setIsSubmittingIntervalUpdate] =
        useState(false);

    useEffect(() => {
        if (subscription) {
            setNewQuantity(subscription.quantity);
        }
    }, [subscription]);

    useEffect(() => {
        if (!subscription) return;

        if (newQuantity > 20) {
            setQuantityInfo(
                "If you need more than 20 licences, please contact us at contact@gomegaminds.com for a quote."
            );
            setQuantityDisabled(true);
            return;
        } else if (newQuantity < props.organization?.members.length) {
            setQuantityInfo(
                "Your organization has " +
                    props.organization?.members.length +
                    " members."
            );
            setQuantityDisabled(true);
            return;
        } else if (newQuantity < 1) {
            setQuantityInfo("You need at least one licence.");
            setQuantityDisabled(true);
            return;
        } else {
            setQuantityInfo("");
            setQuantityDisabled(false);
        }
    }, [newQuantity, subscription, props.organization]);

    const getIntervalEndpoint = () => {
        if (props.organization) {
            return (
                process.env.REACT_APP_MM_API_URL +
                "payments/organization/update-interval/" +
                props.organization.id
            );
        } else {
            return (
                process.env.REACT_APP_MM_API_URL +
                "payments/subscription/update-interval"
            );
        }
    };

    const getReactivateEndpoint = () => {
        if (props.organization) {
            return (
                process.env.REACT_APP_MM_API_URL +
                "payments/organization/reactivate/" +
                props.organization.id
            );
        } else {
            return (
                process.env.REACT_APP_MM_API_URL +
                "payments/subscription/reactivate"
            );
        }
    };

    const getCancelEndpoint = () => {
        if (props.organization) {
            return (
                process.env.REACT_APP_MM_API_URL +
                "payments/organization/cancel/" +
                props.organization.id
            );
        } else {
            return (
                process.env.REACT_APP_MM_API_URL +
                "payments/subscription/cancel"
            );
        }
    };

    useEffect(() => {
        if (props.show) {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                scope: "update:classroom",
            }).then((token) => {
                fetch(getRetrieveEndpoint(props.organization), {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                })
                    .then((response) => {
                        return response.json();
                    })
                    .then((data) => {
                        setSubscriptionLoading(false);
                        console.log("Subscriptions", data);
                        if (props.organization) {
                            setSubscription(data.subscription);
                            setFutureInvoice(data.upcoming);
                            setIsFutureInvoiceLoading(false);
                        } else {
                            setSubscription(data);
                        }
                    })
                    .catch((error) => {
                        setSubscriptionLoading(false);
                        console.error(error);
                    });
            });
        }
    }, [props.show, getAccessTokenSilently, props.organization]);

    const handleIntervalUpdate = (newInterval, cancel = false) => {
        setIsSubmittingIntervalUpdate(true);
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            const payload = {
                newInterval,
                cancel,
            };

            console.log("Payload", payload);

            fetch(getIntervalEndpoint(), {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
                body: JSON.stringify(payload),
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                    setIsSubmittingIntervalUpdate(false);
                    window.location.reload();
                    props.setShow(false);
                })
                .catch((error) => {
                    console.error(error);
                    setIsSubmittingIntervalUpdate(false);
                    toast.error(
                        "Something went wrong while modifying the subscription."
                    );
                    props.update();
                    props.setShow(false);
                });
        });
    };

    const handleQuantityUpdate = () => {
        setIsPostingReactivate(true);
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            const payload = {
                newQuantity,
            };

            console.log("Payload", payload);

            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "payments/organization/update-quantity/" +
                    props.organization.id,

                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                    setIsPostingReactivate(false);
                    props.update();
                    window.location.reload();
                    props.setShow(false);
                })
                .catch((error) => {
                    console.error(error);
                    setIsPostingReactivate(false);
                    toast.success(
                        "Something went wrong while reactivating the subscription."
                    );
                    props.update();
                    props.setShow(false);
                });
        });
    };
    const handleReactivate = () => {
        setIsPostingReactivate(true);
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            fetch(getReactivateEndpoint(), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                    setIsPostingReactivate(false);
                    props.update();
                    window.location.reload();
                    props.setShow(false);
                })
                .catch((error) => {
                    console.error(error);
                    setIsPostingReactivate(false);
                    toast.success(
                        "Something went wrong while reactivating the subscription."
                    );
                    props.update();
                    props.setShow(false);
                });
        });
    };

    const handleCancel = () => {
        setIsPosting(true);
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            fetch(getCancelEndpoint(), {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token}`,
                },
            })
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                    setShowCancelMenu(false);
                    props.update();
                    window.location.reload();
                    toast.success("Subscription was cancelled");
                    setIsPosting(false);
                    props.setShow(false);
                })
                .catch((error) => {
                    console.error(error);
                    props.update();
                    setShowCancelMenu(false);
                    toast.success("Cancellation error");
                    setIsPosting(false);
                    props.setShow(false);
                });
        });
    };

    if (showUpdateCycleMenu) {
        return (
            <Modal
                show={props.show}
                size="md"
                onHide={() => props.setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Manage Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {subscription?.plan?.interval === "month" && (
                        <>
                            <p>
                                You are currently on a{" "}
                                <span className="fw-bold">monthly</span> billing
                                cycle.
                            </p>
                            <p>
                                Changing the plan will take effect at the end of
                                the current cycle (
                                {moment
                                    .unix(subscription?.current_period_end)
                                    .format("dddd, MMMM Do YYYY")}
                                ).
                            </p>
                            <Button
                                className="me-2"
                                variant="success"
                                onClick={() => handleIntervalUpdate("yearly")}
                                disabled={isSubmittingIntervalUpdate}
                            >
                                {isSubmittingIntervalUpdate ? (
                                    <>
                                        <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        <span>Loading...</span>
                                    </>
                                ) : (
                                    <span>Switch to yearly plan</span>
                                )}
                            </Button>
                        </>
                    )}
                    {subscription?.plan?.interval === "year" && (
                        <>
                            <p>
                                You are currently on a{" "}
                                <span className="fw-bold">Yearly</span> plan.
                            </p>
                            <p>
                                Changing the plan will take effect at the end of
                                the current cycle (
                                {moment
                                    .unix(subscription?.current_period_end)
                                    .format("dddd, MMMM Do YYYY")}
                                ).
                            </p>
                            <Button
                                onClick={() => handleIntervalUpdate("monthly")}
                                disabled={isSubmittingIntervalUpdate}
                                className="me-2"
                                variant="success"
                            >
                                {isSubmittingIntervalUpdate ? (
                                    <>
                                        <span
                                            className="spinner-border spinner-border-sm me-2"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                        <span>Loading...</span>
                                    </>
                                ) : (
                                    <span>Switch to monthly plan</span>
                                )}
                            </Button>
                        </>
                    )}
                    <Button
                        variant="secondary"
                        onClick={() => setShowUpdateCycleMenu(false)}
                    >
                        Back
                    </Button>
                </Modal.Body>
            </Modal>
        );
    }

    if (showUpdateQuantityMenu) {
        return (
            <Modal
                show={props.show}
                size="md"
                onHide={() => props.setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Manage Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h4>Changing licence quantity</h4>
                    <hr />
                    <p>
                        When{" "}
                        <span className="fw-bold">adding more licences</span>,
                        we will immediatley issue a payment for the extra
                        licences for the remaining time until next payment date.
                    </p>
                    <p>
                        When{" "}
                        <span className="fw-bold">reducing the quantity</span>,
                        we will credit the difference to your account, and your
                        next invoice(s) will be reduced.
                    </p>

                    <p>Update amount of licences</p>
                    <Form.Control
                        type="number"
                        value={newQuantity}
                        onChange={(e) => setNewQuantity(e.target.value)}
                    />
                    <p>{quantityInfo}</p>
                    <Button
                        disabled={quantityDisabled}
                        onClick={() => handleQuantityUpdate()}
                    >
                        Update
                    </Button>
                    <Button
                        variant="secondary"
                        onClick={() => setShowUpdateQuantityMenu(false)}
                    >
                        Back
                    </Button>
                </Modal.Body>
            </Modal>
        );
    }

    if (showUpdateCardMenu) {
        return (
            <Modal
                show={props.show}
                size="md"
                onHide={() => props.setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Manage Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>Update your card</p>
                    <Elements stripe={stripePromise}>
                        <CardForm
                            customer={subscription.customer}
                            organization={props.organization}
                            subscription={subscription.id}
                            goBack={() => setShowUpdateCardMenu(false)}
                            onFinish={() => {
                                props.update();
                                setShowUpdateCardMenu(false);
                                props.setShow(false);
                            }}
                        />
                    </Elements>
                </Modal.Body>
            </Modal>
        );
    }

    if (showCancelMenu) {
        return (
            <Modal
                show={props.show}
                size="md"
                onHide={() => props.setShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Manage Subscription</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to cancel your subscription?
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="secondary"
                        onClick={() => setShowCancelMenu(false)}
                    >
                        Back
                    </Button>
                    <Button
                        variant="danger"
                        disabled={isPosting ? true : false}
                        onClick={handleCancel}
                    >
                        {isPosting ? (
                            <>
                                <span
                                    className="spinner-border spinner-border-sm me-2"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                <span>Loading...</span>
                            </>
                        ) : (
                            <span>Yes, cancel my subscription</span>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        );
    }

    return (
        <Modal show={props.show} size="md" onHide={() => props.setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Manage Subscription</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {isSubscriptionLoading && <p>Loading</p>}
                {!isSubscriptionLoading && !subscription && (
                    <p>No subscription found</p>
                )}
                {!isFutureInvoiceLoading && futureInvoice && (
                    <Row className="mb-4">
                        <Col>
                            <Card>
                                <Card.Body>
                                    <Row>
                                        <Col className="fw-bold">
                                            <p className="mb-2">
                                                Your next payment
                                            </p>
                                            {subscription?.cancel_at_period_end ? (
                                                <>
                                                    <h4 className="fw-bold">
                                                        ${0}
                                                    </h4>
                                                    <small className="fw-light">
                                                        Subscription will end at{" "}
                                                        {moment
                                                            .unix(
                                                                subscription.cancel_at
                                                            )
                                                            .format(
                                                                "dddd, MMMM Do, YYYY"
                                                            )}
                                                    </small>
                                                </>
                                            ) : (
                                                <>
                                                    <h4 className="fw-bold">
                                                        $
                                                        {futureInvoice?.amount_due /
                                                            100}
                                                    </h4>
                                                    <br />
                                                    <small>
                                                        {subscription?.quantity}{" "}
                                                        licences
                                                    </small>
                                                    <br />
                                                    {futureInvoice?.discount && (
                                                        <span className="text-success">
                                                            Discount applied
                                                            through coupon
                                                            expires{" "}
                                                            {moment
                                                                .unix(
                                                                    futureInvoice
                                                                        .discount
                                                                        .end
                                                                )
                                                                .format(
                                                                    "MMMM Do, YYYY"
                                                                )}
                                                        </span>
                                                    )}
                                                    <small className="fw-light">
                                                        {moment
                                                            .unix(
                                                                subscription?.current_period_end
                                                            )
                                                            .format(
                                                                "dddd, MMMM Do, YYYY"
                                                            )}
                                                    </small>
                                                </>
                                            )}
                                        </Col>
                                        <Col>
                                            <small className="fw-light">
                                                {futureInvoice?.amount_due <=
                                                    0 && (
                                                    <>
                                                        <p>
                                                            The next invoice is
                                                            <span className="fw-bold">
                                                                {" $"}
                                                                {Math.abs(
                                                                    futureInvoice.total /
                                                                        100
                                                                )}
                                                            </span>
                                                        </p>
                                                        <p>
                                                            You have{" $"}
                                                            {Math.abs(
                                                                futureInvoice.starting_balance /
                                                                    100
                                                            )}{" "}
                                                            credit from a
                                                            previous downgrade.
                                                        </p>
                                                        <p>
                                                            After next invoice
                                                            you will have{" $"}
                                                            {Math.abs(
                                                                futureInvoice.ending_balance /
                                                                    100
                                                            )}{" "}
                                                            remaining credit.
                                                        </p>
                                                    </>
                                                )}
                                            </small>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                )}
                {subscription && (
                    <Row className="">
                        <Col xs={12}>
                            <Row className="">
                                <Col>
                                    <ListGroup>
                                        {subscription?.cancel_at_period_end && (
                                            <ListGroup.Item className="bg-warning">
                                                <Row>
                                                    <Col>
                                                        <small className="fw-light">
                                                            Subscription will
                                                            end at{" "}
                                                            {moment
                                                                .unix(
                                                                    subscription.cancel_at
                                                                )
                                                                .format(
                                                                    "dddd, MMMM Do, YYYY"
                                                                )}
                                                        </small>
                                                    </Col>
                                                </Row>
                                            </ListGroup.Item>
                                        )}
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <p className="mb-0">
                                                        Status
                                                    </p>
                                                </Col>
                                                <Col>
                                                    {subscription.status ===
                                                        "canceled" && (
                                                        <Badge bg="warning">
                                                            Subscription was
                                                            cancelled and will
                                                            end at
                                                        </Badge>
                                                    )}
                                                    {subscription.status ===
                                                        "incomplete" && (
                                                        <Badge bg="warning">
                                                            Incomplete
                                                        </Badge>
                                                    )}
                                                    {subscription.status ===
                                                        "active" && (
                                                        <Badge bg="success">
                                                            Active
                                                        </Badge>
                                                    )}
                                                    {subscription.status ===
                                                        "trialing" && (
                                                        <Badge bg="warning">
                                                            Trail
                                                        </Badge>
                                                    )}
                                                    {subscription.status ===
                                                        "incomplete_expired" && (
                                                        <Alert variant="warning">
                                                            <p className="mb-0">
                                                                The initial
                                                                payment on the
                                                                subscription
                                                                failed and no
                                                                successful
                                                                payment was made
                                                                within 23 hours
                                                                of creating the
                                                                subscription.
                                                            </p>
                                                        </Alert>
                                                    )}
                                                    {subscription.status ===
                                                        "unpaid" && (
                                                        <Alert variant="warning">
                                                            <p className="mb-0">
                                                                The latest
                                                                invoice hasn’t
                                                                been paid
                                                            </p>
                                                        </Alert>
                                                    )}
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <p className="mb-0">
                                                        Default Payment Method
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <span className="badge bg-secondary">
                                                        Ends with{" "}
                                                        {
                                                            subscription
                                                                ?.default_payment_method
                                                                ?.card.last4
                                                        }
                                                    </span>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col>
                                                    <p className="mb-0">
                                                        Billing cycle
                                                    </p>
                                                </Col>
                                                <Col>
                                                    <span className="badge bg-secondary">
                                                        {!subscription?.schedule &&
                                                            subscription?.plan
                                                                ?.interval ===
                                                                "month" &&
                                                            "Monthly"}
                                                        {!subscription?.schedule &&
                                                            subscription?.plan
                                                                ?.interval ===
                                                                "year" &&
                                                            "Yearly"}
                                                    </span>
                                                    <span className="badge bg-secondary">
                                                        {subscription?.schedule &&
                                                            subscription?.plan
                                                                .interval ===
                                                                "month" &&
                                                            "Changing to Yearly"}
                                                        {subscription?.schedule &&
                                                            subscription?.plan
                                                                .interval ===
                                                                "year" &&
                                                            "Changing to Monthly"}
                                                    </span>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    </ListGroup>
                                </Col>
                            </Row>
                            <Row className="mt-5">
                                <Col xs={12}>
                                    {!subscription?.cancel_at_period_end ? (
                                        <>
                                            {subscription?.schedule ? (
                                                <>
                                                    <Alert variant="info">
                                                        <p>
                                                            You have an upcoming
                                                            change in billing
                                                            cycle from{" "}
                                                            <span className="fw-bold">
                                                                {subscription
                                                                    ?.plan
                                                                    .interval ===
                                                                    "month" &&
                                                                    "monthly to yearly"}
                                                                {subscription
                                                                    ?.plan
                                                                    .interval ===
                                                                    "yearly" &&
                                                                    "monthly to yearly"}
                                                            </span>{" "}
                                                            that will become
                                                            active on next
                                                            billing date.
                                                        </p>
                                                        {props.organization && (
                                                            <p className="fw-light mb-0">
                                                                You will not be
                                                                able to change
                                                                quantity until
                                                                the new interval
                                                                is active.
                                                            </p>
                                                        )}
                                                    </Alert>
                                                    <Button
                                                        variant="outline-secondary"
                                                        className="mt-2 w-100"
                                                        onClick={() =>
                                                            handleIntervalUpdate(
                                                                "cancel"
                                                            )
                                                        }
                                                    >
                                                        <i className="bi bi-clock me-2"></i>
                                                        Cancel billing cycle
                                                        change
                                                    </Button>
                                                </>
                                            ) : (
                                                <Button
                                                    variant="outline-secondary"
                                                    className="mt-2 w-100"
                                                    onClick={() =>
                                                        setShowUpdateCycleMenu(
                                                            true
                                                        )
                                                    }
                                                >
                                                    <i className="bi bi-clock me-2"></i>
                                                    {subscription?.plan
                                                        .interval === "month" &&
                                                        "Change to Yearly billing cycle"}
                                                    {subscription?.plan
                                                        .interval === "year" &&
                                                        "Change to Monthly billing cycle"}
                                                </Button>
                                            )}
                                            {props.organization && (
                                                <>
                                                    {subscription?.schedule ? (
                                                        <Button
                                                            variant="outline-secondary"
                                                            className="mt-2 w-100"
                                                            disabled
                                                        >
                                                            <i className="bi bi-people-fill me-2"></i>
                                                            Update amount of
                                                            licences
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="outline-secondary"
                                                            className="mt-2 w-100"
                                                            onClick={() =>
                                                                setShowUpdateQuantityMenu(
                                                                    true
                                                                )
                                                            }
                                                        >
                                                            <i className="bi bi-people-fill me-2"></i>
                                                            Update amount of
                                                            licences
                                                        </Button>
                                                    )}
                                                </>
                                            )}
                                            <Button
                                                variant="outline-secondary"
                                                className="mt-2 w-100"
                                                onClick={() =>
                                                    setShowUpdateCardMenu(true)
                                                }
                                            >
                                                <i className="bi bi-credit-card me-2"></i>
                                                Update payment method
                                            </Button>
                                            {subscription.cancel_at_period_end ? (
                                                <Button
                                                    variant="outline-success"
                                                    disabled={
                                                        isPostingReactivate
                                                    }
                                                    onClick={() =>
                                                        handleReactivate()
                                                    }
                                                    className="mt-2 w-100"
                                                >
                                                    Reactivate subscription
                                                </Button>
                                            ) : (
                                                <Button
                                                    variant="outline-danger"
                                                    onClick={() =>
                                                        setShowCancelMenu(true)
                                                    }
                                                    className="mt-2 w-100"
                                                >
                                                    Cancel subscription
                                                </Button>
                                            )}
                                        </>
                                    ) : (
                                        <Button
                                            variant="outline-success"
                                            disabled={isPostingReactivate}
                                            onClick={() => handleReactivate()}
                                            className="mt-2 w-100"
                                        >
                                            Reactivate subscription
                                        </Button>
                                    )}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                )}
            </Modal.Body>
        </Modal>
    );
};
