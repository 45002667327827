import React, { useState, useEffect } from "react";

import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ListGroup from "react-bootstrap/ListGroup";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import Form from "react-bootstrap/Form";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { createRoom } from "../../utils/create-room";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useAuth0 } from "@auth0/auth0-react";
import { states } from "./states";

function NewRoomModal({ setShow, show, onFinish }) {
    const { getAccessTokenSilently } = useAuth0();

    const [showGuideMenu, setShowGuideMenu] = useState(false);
    const [standardsAligned, setStandardsAligned] = useState(false);
    const [isPosting, setIsPosting] = useState(false);
    const [validated, setValidated] = useState(false);

    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState(false);
    const [subject, setSubject] = useState("");
    const [grade, setGrade] = useState("");
    const [activity, setActivity] = useState("Nothing Specific");
    const [selectedState, setSelectedState] = useState("");

    const handleSubmit = () => {
        setIsPosting(true);

        if (!title) {
            setTitleError(true);
            setIsPosting(false);
            return;
        } else {
            setTitleError(false);
        }

        let classroom = {
            title: title,
            description: "",
            scene: null,
            is_fresh: true,
            shared_to: null,
            team: null,
            guests_can_move: false,
            guests_can_create: false,
            guests_can_chat: true,
            guests_can_draw: false,
            guests_can_raise_hand: true,
            guests_can_react: true,
            guests_can_share_video: true,
            guests_can_share_microphone: true,
            guests_can_see_student_menu: true,
            guests_can_spawn_stickynotes: false,
            ai_subject: subject,
            ai_grade: grade,
            ai_activity: activity,
            ai_state: selectedState,
        };

        const myPromise = new Promise((resolve, error) => {
            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                scope: "create:classroom",
            }).then((token) => {
                createRoom(classroom, token)
                    .then((resp) => {
                        console.log(resp);
                        setIsPosting(false);
                        window.open(
                            process.env.REACT_APP_3D_ENDPOINT +
                                "/" +
                                resp.hub_id,
                            "_blank"
                        );

                        setShow(false);
                        onFinish(resp.id);
                        resolve();
                    })
                    .catch((e) => {
                        setIsPosting(false);
                        onFinish();
                        error();
                    });
            });
        });

        toast.promise(myPromise, {
            loading: "Creating...",
            success: "Lesson created!",
            error: "Error when creating",
        });
    };

    return (
        <Modal show={show} fullscreen="md-down" onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>New Lesson</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group className="mb-3">
                    <Form.Label>Lesson Name *</Form.Label>
                    <Form.Control
                        value={title}
                        required
                        onChange={(e) => setTitle(e.target.value)}
                        type="text"
                        isInvalid={titleError}
                        placeholder="My Lesson"
                    />
                    <Form.Control.Feedback type="invalid">
                        Please give your Lesson a name.
                    </Form.Control.Feedback>
                </Form.Group>
                <Form.Check // prettier-ignore
                    type="switch"
                    id="menuGuide"
                    label={
                        <span className="fw-light">
                            <small>
                                Generate a helpful step-by-step guide based on subject,
                                grade and activity.
                            </small>
                        </span>
                    }
                    onChange={(e) => setShowGuideMenu(e.target.checked)}
                />
                {showGuideMenu && (
                    <>
                        <hr />
                        <Form.Group className="mb-3">
                            <Form.Label>Subject</Form.Label>
                            <Form.Control
                                value={subject}
                                onChange={(e) => setSubject(e.target.value)}
                                type="text"
                                placeholder="Solar System"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Grade Level</Form.Label>
                            <Form.Control
                                value={grade}
                                onChange={(e) => setGrade(e.target.value)}
                                type="text"
                                placeholder="Any"
                            />
                        </Form.Group>
                        <Form.Group className="mb-3">
                            <Form.Label>Activity</Form.Label>
                            <Form.Select
                                value={activity}
                                onChange={(e) => setActivity(e.target.value)}
                                aria-label="Activity"
                            >
                                <option>Nothing Specific</option>
                                <option>Hook</option>
                                <option>Check for Understanding</option>
                                <option>Discussion</option>
                                <option>Project Based Learning</option>
                                <option>Reflection</option>
                                <option>Discovery Learning</option>
                            </Form.Select>
                        </Form.Group>
                        <Form.Group>
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                label="It is important that my Lesson is standards aligned "
                                checked={standardsAligned}
                                onChange={(e) =>
                                    setStandardsAligned(e.target.checked)
                                }
                            />
                            {standardsAligned && (
                                <>
                                    <p className="mt-3 mb-2">My State is</p>
                                    <Form.Select
                                        onChange={(e) =>
                                            setSelectedState(e.target.value)
                                        }
                                        aria-label="State"
                                    >
                                        {states.map((state) => (
                                            <option value={state.name}>
                                                {state.name}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </>
                            )}
                        </Form.Group>
                    </>
                )}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                </Button>
                <Button
                    disabled={isPosting}
                    variant="success"
                    onClick={() => handleSubmit()}
                >
                    Create and Open Lesson
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default function NewRoomButton(props) {
    const [showModal, setShowModal] = useState(false);

    return (
        <>
            <NewRoomModal
                onFinish={props.onFinish}
                show={showModal}
                setShow={setShowModal}
            />
            <Button
                onClick={() => setShowModal(true)}
                variant="purple-dark"
                className={props.fullWidth ? "w-100 mt-2 text-start" : ""}
            >
                <i className="bi bi-plus-lg me-2"></i>
                <span className="">New Lesson</span>
            </Button>
        </>
    );
}
