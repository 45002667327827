import React, { useState } from "react";
import Card from "react-bootstrap/Card";
import { getPicture } from "../../../utils/get-picture";

function RoomBrowserCard({ room, onSelect, noAction }) {
    const [showOverlay, setShowOverlay] = useState(false);

    return (
        <Card
            onMouseEnter={() => setShowOverlay(true)}
            onMouseLeave={() => setShowOverlay(false)}
            onClick={() => (noAction ? null : onSelect(room))}
            className="h-100"
        >
            <div
                className={
                    showOverlay && !noAction
                        ? "dimmed-background cursor-pointer position-absolute w-100 h-100 d-flex justify-content-center align-items-center"
                        : "d-none"
                }
                style={{ zIndex: 5 }}
            >
                <div className="w-100 text-center text-white">
                    <p className="mb-0">Select</p>
                </div>
            </div>
            <Card.Img
                height="100%"
                style={{ maxHeight: "" }}
                className="dim"
                variant=""
                src={getPicture(room.scene.photo)}
            />
            <Card.ImgOverlay
                style={{ maxHeight: "150px !important" }}
                className="p-4"
            >
                <Card.Title className="text-white mb-0">
                    {room.title}
                </Card.Title>
            </Card.ImgOverlay>
        </Card>
    );
}

export default RoomBrowserCard;
