import React from "react";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";

export function OrganizationSubscription(props) {
    return (
        <>
            <Row>
                <Col>
                    <h2>Billing Summary</h2>
                    <Alert variant="success">
                        <p className="mb-0">
                            Your subscription is managed through an
                            organization.
                        </p>
                    </Alert>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col>
                    <h2>Current Plan</h2>
                </Col>
            </Row>
            <Row className="mt-4">
                <Col>
                    <Alert variant="success">
                        <p className="mb-0">
                            Your subscription is managed through an
                            organization.
                        </p>
                    </Alert>
                </Col>
            </Row>
        </>
    );
}
