import { useEffect, useState } from "react";

const useBannerAPI = () => {
    const [banners, setBanners] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(0);

    const refresh = () => {
        setRefreshIndex(refreshIndex + 1);
    };

    useEffect(() => {
        setLoading(true);
        try {
            fetch(process.env.REACT_APP_MM_API_URL + "banners")
                .then((resp) => {
                    return resp.json();
                })
                .then((completedResponse) => {
                    setBanners(completedResponse);
                    setLoading(false);
                });
        } catch (e) {
            console.error(e);
            setLoading(false);
            setError(e);
        }
    }, [refreshIndex]);

    return [banners, loading, error, refresh];
};

export default useBannerAPI;
