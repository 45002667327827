import React from "react";
import Alert from "react-bootstrap/Alert";

import moment from "moment";
import "moment-timezone";

export default function IncompleteSubscriptionCard({
    organization,
    subscription,
    update,
}) {
    return (
        <Alert variant="warning mt-2 mb-5">
            <h2>Action Required</h2>
            <p className="mb-0">
                We were unable to complete the payment of your latest invoice.
            </p>
            <p>
                You will be put back on Free Plan on{" "}
                {moment
                    .unix(subscription.latest_invoice.period_end)
                    .format("dddd, MMMM Do, YYYY, HH:MM")}
            </p>
            <a
                target="_blank"
                rel="noopener noreferrer"
                href={subscription.latest_invoice.hosted_invoice_url}
                className="me-2"
            >
                Click here to resolve
            </a>
        </Alert>
    );
}
