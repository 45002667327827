import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import SharedClassRoomCard from "../cards/SharedClassRoomCard";
import Paginator from "../../../components/Paginator";
import useSharedRoomAPI from "../../../services/useSharedRoomAPI";
import EmptySharedRoomsPage from "../EmptySharedRoomsPage";
import { Socket } from "phoenix";

function SharedRoomsTab({
    auth,
    restricted,
    restrictedStudentProjects,
    newRoom,
    showInviteModal,
    showAssignModal,
    handleSearch,
    searchValue,
    sortValue,
    setSortValue,
    page,
    setPage,
    refreshSingleRoom,
    shared_rooms,
    isLoadingShared,
    sharedError,
    refreshShared,
    refreshRooms
}) {
    const [socket, setSocket] = useState(null);

    useEffect(() => {
        const socketSettings = {};
        socketSettings.logger = (kind, msg, data) => {
            // console.log(kind, msg, data);
        };

        const socket = new Socket(
            process.env.REACT_APP_PHOENIX_ENDPOINT,
            socketSettings
        );
        socket.onError(async (error) => {
            // console.log("Socket errored", error);
        });

        socket.connect();
        setSocket(socket);
    }, [shared_rooms]);
    
    return (
    <>
    {isLoadingShared && (
                    <Row className="m-0 p-3 p-lg-4">
                        <Col>
                            <span
                                className="spinner-border spinner-border-sm"
                                role="status"
                                aria-hidden="true"
                            ></span>
                        </Col>
                    </Row>
                )}

                {!isLoadingShared && sharedError && (
                    <Row className="m-0 p-3 p-lg-4">
                        <Col>
                            <p>{sharedError}</p>
                        </Col>
                    </Row>
                )}
    {searchValue ||
        (!isLoadingShared &&
            !sharedError &&
            shared_rooms &&
            shared_rooms?.results?.length > 0) ? (
        <Container className="p-0 m-0">
            <Row className="g-3">
                {shared_rooms &&
                    shared_rooms?.results?.length >= 0 &&
                    shared_rooms?.results?.map((room) => (
                        <SharedClassRoomCard
                            refreshSingleRoom={refreshSingleRoom}
                            refresh={refreshRooms}
                            newRoom={newRoom === room.id}
                            classroom={room}
                            disabled={
                                auth.profile?.has_active_subscription &&
                                shared_rooms?.results?.length > 3
                            }
                            profile={auth.profile}
                            auth={auth}
                            showInviteModal={showInviteModal}
                            showAssignModal={showAssignModal}
                            socket={socket}
                            key={room.id}
                            restricted={restricted}
                            restrictedStudentProjects={
                                restrictedStudentProjects
                            }
                        />
                    ))}
            </Row>
            <Col sm={12} md={12} lg={12} xxl={12} className="mt-3">
                {searchValue &&
                    shared_rooms?.length >= 1 &&
                    (!shared_rooms ||
                        (shared_rooms?.results?.length <= 0 && (
                            <Col xs={3}>
                                <p>No rooms matched your filters.</p>
                                <Button
                                    variant="purple-dark"
                                    onClick={() => null}
                                >
                                    Reset filters
                                </Button>
                            </Col>
                        )))}
                {searchValue && shared_rooms?.results?.length <= 0 && (
                    <Col>
                        <p>No rooms matched your search.</p>
                        <Button
                            variant="purple-dark"
                            onClick={() => handleSearch("")}
                        >
                            Reset filters
                        </Button>
                    </Col>
                )}
            </Col>

            {shared_rooms?.count > 20 && (
                <Row className="m-0 p-3 p-lg-4">
                    <Paginator
                        items={shared_rooms}
                        page={page}
                        setPage={setPage}
                    />
                </Row>
            )}
        </Container>
    ) : (
        <EmptySharedRoomsPage 
        isLoadingShared={isLoadingShared} 
        auth={auth}
        />
    )}
    </>
)}
export default SharedRoomsTab;
