import Pagination from "react-bootstrap/Pagination";
import React from "react";

export default function Paginator({ items, page, setPage }) {
    const handleNext = () => {
        document.getElementsByTagName("main")[0].scrollTo(0, 0);
        setPage(page + 1);
    };

    const handlePrevious = () => {
        document.getElementsByTagName("main")[0].scrollTo(0, 0);
        setPage(page - 1);
    };

    const handleChangePage = (page) => {
        document.getElementsByTagName("main")[0].scrollTo(0, 0);
        setPage(page);
    };

    if (!items?.next && !items.previous) return null;

    return (
        <Pagination>
            {items?.previous && (
                <Pagination.Item onClick={handlePrevious} key={page - 1}>
                    Previous Page
                </Pagination.Item>
            )}
            {Array.from(Array(Math.ceil(items.count / 20))).map(
                (listPage, index) => (
                    <Pagination.Item
                        onClick={() => handleChangePage(index + 1)}
                        key={index}
                        active={page === index + 1}
                    >
                        {index + 1}
                    </Pagination.Item>
                )
            )}
            {items?.next && (
                <Pagination.Item onClick={handleNext} key={page + 1}>
                    Next Page
                </Pagination.Item>
            )}
        </Pagination>
    );
}
