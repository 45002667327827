import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";

import Categories from "./sidebar/Categories";
import Search from "./sidebar/Search";
import Grades from "./sidebar/Grades";
import Format from "./sidebar/Format";
import ResourceTypes from "./sidebar/ResourceTypes";
import ClearBadges from "./sidebar/ClearBadges";

import useFacetFilterAPI from "../../services/useFacetFilterAPI";

const MobileFilter = ({
    resetFilters,
    showFeatured,
    tempSearchValue,
    setTempSearchValue,
    setSearchValue,
    selectedCategories,
    setSelectedCategories,
    selectedGrades,
    setSelectedGrades,
    selectedResourceTypes,
    setSelectedResourceTypes,
    selectedFormats,
    setSelectedFormats,
}) => {
    const [facet, facetLoading, facetError] =
        useFacetFilterAPI("templates/facet/");

    const [show, setShow] = useState(false);

    if (facetLoading) {
        return <p>Loading...</p>;
    }

    if (facetError) {
        return <p>Could not load Filter Sidebar</p>;
    }

    return (
        <div className="mt-4 mb-4 d-sm-none">
            <Search
                tempSearchValue={tempSearchValue}
                setTempSearchValue={setTempSearchValue}
                setSearchValue={setSearchValue}
            />
            <Button
                variant="outline-secondary"
                onClick={() => setShow(true)}
                size="sm"
                className="d-block w-100 mt-2"
            >
                <i className="bi bi-funnel-fill"></i>Filter
            </Button>
            {!showFeatured && (
                <Button
                    variant="outline-secondary"
                    size="sm"
                    className="mt-2 w-100 text-start"
                    onClick={() => resetFilters()}
                >
                    <i className="bi bi-x-lg me-2"></i>
                    Reset all filters
                </Button>
            )}
            <ClearBadges
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                selectedGrades={selectedGrades}
                setSelectedGrades={setSelectedGrades}
                selectedResourceTypes={selectedResourceTypes}
                setSelectedResourceTypes={setSelectedResourceTypes}
                setSelectedFormats={setSelectedFormats}
                selectedFormats={selectedFormats}
            />
            <Modal
                close
                className="border-bottom"
                show={show}
                onHide={() => setShow(false)}
                scroll
                fullscreen
            >
                <Modal.Header closeButton>
                    <Modal.Title>Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {!showFeatured && (
                        <Button
                            variant="outline-secondary"
                            size="sm"
                            className="mt-2 w-100 text-start"
                            onClick={() => resetFilters()}
                        >
                            <i className="bi bi-x-lg me-2"></i>
                            Reset all filters
                        </Button>
                    )}

                    <ClearBadges
                        selectedCategories={selectedCategories}
                        setSelectedCategories={setSelectedCategories}
                        selectedGrades={selectedGrades}
                        setSelectedGrades={setSelectedGrades}
                        selectedResourceTypes={selectedResourceTypes}
                        setSelectedResourceTypes={setSelectedResourceTypes}
                        setSelectedFormats={setSelectedFormats}
                        selectedFormats={selectedFormats}
                    />
                    <Format
                        setSelectedFormats={setSelectedFormats}
                        selectedFormats={selectedFormats}
                    />
                    {facet.categories.length > 0 && (
                        <Categories
                            categories={facet.categories}
                            selectedCategories={selectedCategories}
                            setSelectedCategories={setSelectedCategories}
                        />
                    )}
                    {facet.grades.length > 0 && (
                        <Grades
                            grades={facet.grades}
                            selectedGrades={selectedGrades}
                            setSelectedGrades={setSelectedGrades}
                        />
                    )}
                    {facet.resource_types.length > 0 && (
                        <ResourceTypes
                            resourceTypes={facet.resource_types}
                            selectedResourceTypes={selectedResourceTypes}
                            setSelectedResourceTypes={setSelectedResourceTypes}
                        />
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        variant="outline-success"
                        onClick={() => setShow(false)}
                        className="d-block w-100 mt-2"
                    >
                        Show Results
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};

export default MobileFilter;
