import React from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import OrganizationCard from "./organizations/OrganizationCard";

import useOrganizations from "../services/useOrganizations";
import { useAuth } from "../context/AuthContext";

const Organization = (props) => {
    const auth = useAuth();

    const [
        organizations,
        isOrganizationsLoading,
        organizationsError,
        refreshOrganizations,
    ] = useOrganizations(auth);

    return (
        <>
            <Container fluid className="g-0 bg-light">
                <Row className="bg-white p-3 p-lg-4 border-bottom m-0">
                    <Col md={12} lg={5} className="d-flex align-items-center">
                        <h3 className="mb-0">My Organizations</h3>
                    </Col>
                </Row>
            </Container>
            <Container className="g-0 bg-light">
                <Row className="m-0 p-3 p-lg-4">
                    {organizationsError && <p>{organizationsError}</p>}
                    {isOrganizationsLoading && <p>Loading organizations...</p>}
                    {!organizationsError &&
                        !isOrganizationsLoading &&
                        organizations.map((org) => (
                            <OrganizationCard
                                key={org.id}
                                update={refreshOrganizations}
                                organization={org}
                            />
                        ))}
                </Row>
            </Container>
        </>
    );
};

export default Organization;
