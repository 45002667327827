import React, { useState } from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

function PreviewModal(props) {
    const [show, setShow] = useState(false);

    const handleClose = () =>
        props.setShow ? props.setShow(false) : setShow(false);
    const handleShow = () =>
        props.setShow ? props.setShow(true) : setShow(true);

    const openModal = () => {
        handleShow();
    };

    const handleAdd = (e) => {
        // Force focus on iframe load so that key input works
        props.setShowCreateModal();
    };

    const handleLoad = (e) => {
        // Force focus on iframe load so that key input works
        e.target.focus();
    };

    return (
        <>
            {!props.hideButton && (
                <Button
                    variant="purple-dark"
                    className="text-start w-100"
                    onClick={() => openModal()}
                >
                    <FontAwesomeIcon icon={faEye} fixedWidth />
                    <span className="ms-3">Preview</span>
                </Button>
            )}

            <Modal
                show={props.show ? props.show : show}
                autoFocus={false}
                fullscreen="md-down"
                dialogClassName="modal-vw80"
                onHide={handleClose}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Preview - {props.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="p-0" style={{ minHeight: "70vh" }}>
                    <iframe
                        style={{ position: "absolute" }}
                        className="w-100 h-100"
                        title="preview"
                        src={props.link}
                        autoFocus
                        onLoad={(e) => handleLoad(e)}
                    />
                </Modal.Body>
                <Modal.Footer>
                    {props.studentProject ? (
                        <Button variant="success" onClick={handleAdd}>
                            Add to Student Projects
                        </Button>
                    ) : (
                        <Button variant="success" onClick={handleAdd}>
                            Add to My Lessons
                        </Button>
                    )}
                    <Button variant="secondary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default PreviewModal;
