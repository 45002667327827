import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { contains } from "./utils";

const ResourceTypes = ({
    setSelectedResourceTypes,
    selectedResourceTypes,
    resourceTypes,
}) => {
    const handleResourceTypeCheck = (resource_type) => {
        const isChecked = contains(selectedResourceTypes, resource_type);

        if (isChecked) {
            // Remove from list
            const cleanedResourceTypes = selectedResourceTypes.filter(
                (old_resource_type) => old_resource_type.id !== resource_type.id
            );
            setSelectedResourceTypes(cleanedResourceTypes);
        } else {
            // Add to list
            setSelectedResourceTypes([...selectedResourceTypes, resource_type]);
        }
    };
    return (
        <Row className="mt-4">
            <Col xs={12}>
                <p className="fw-bold">Activity Types</p>
            </Col>
            <Col xs={12} className="d-flex justify-content-start">
                <Row>
                    {resourceTypes &&
                        resourceTypes.length &&
                        resourceTypes.map((resource_type) => {
                            return (
                                <Col xs={12}>
                                    <Form.Check
                                        id={"type-" + resource_type.title}
                                        onClick={() => {
                                            handleResourceTypeCheck(
                                                resource_type
                                            );
                                        }}
                                        checked={contains(
                                            selectedResourceTypes,
                                            resource_type
                                        )}
                                        label={resource_type.title}
                                    />
                                </Col>
                            );
                        })}
                </Row>
            </Col>
            <Col />
        </Row>
    );
};

export default ResourceTypes;
