import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";

import useAdminAPI from "../services/useAdminAPI";

const Admin = () => {
    const [stats, useStats] = useAdminAPI();

    return (
        <Container className="p-5">
            <p>Admin</p>
        </Container>
    );
};

export default Admin;
