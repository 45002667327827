import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { getPicture } from "../../../utils/get-picture";
import { useAuth0 } from "@auth0/auth0-react";
import toast from "react-hot-toast";
import ReviewModal from "../modals/ReviewModal";
import Button from "react-bootstrap/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
// Pending Review Card should have the ability to accept room or reject room
// On-click reject will prompt rejection reason

function PendingReviewCard({ template, refresh }) {
    const { getAccessTokenSilently } = useAuth0();
    console.log("My current template", template);
    const [showModal, setShowModal] = useState(false);

    const onHide = () => {
        setShowModal(false);
    };

    const handleApprove = () => {
        const payload = { pending_review: false, published: true };

        // This fetch method still needs a getAccessTokenSilently first. And use "then"
        // to update the page after its done. See EditClassroomModal for more examples.
        // Pending review field doesn't change, possibly because this is a clone
        // Refresh the page after approving
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "create_classroom",
        }).then((token) => {
            console.log("Approving template id", template.id);
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "templates/review/" +
                    template.id,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                    refresh();
                })
                .catch((error) => {
                    toast.error("Something went wrong while saving.");
                    console.error(error);
                });
        });
    };

    const handleReject = (rejectedReason) => {
        console.log(rejectedReason);
        const payload = {
            pending_review: false,
            rejected_reason: rejectedReason,
        };

        // This fetch method still needs a getAccessTokenSilently first. And use "then"
        // to update the page after its done. See EditClassroomModal for more examples.
        // Pending review field doesn't change, possibly because this is a clone
        // Refresh the page after approving
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "create_classroom",
        }).then((token) => {
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "templates/review/" +
                    template.id,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                    refresh();
                })
                .catch((error) => {
                    toast.error("Something went wrong while saving.");
                    console.error(error);
                });
        });
    };

    return (
        <Col sm={12} md={12} lg={12} xxl={12}>
            <Card className="mt-3">
                <Row className="p-0 m-0 g-0">
                    <Col
                        xs={12}
                        md={3}
                        className="p-3 m-0 d-flex justify-content-start align-content-start"
                        style={{
                            background:
                                "linear-gradient(119deg, rgba(52,232,27,0) 0%, rgba(0,0,0,0.4247898988697041) 58%, rgba(0,0,0,0.6516806551722252) 100%), url(" +
                                getPicture(template.image) +
                                ")",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                        }}
                    ></Col>
                    <Col className="">
                        <Row className="border-bottom m-0">
                            <Col
                                xs={12}
                                md={12}
                                className="d-flex justify-content-start align-items-center"
                            >
                                <Card.Title className="text-dark text-truncate mb-0 mt-3 mb-3">
                                    {template.title}
                                </Card.Title>
                            </Col>
                        </Row>

                        <Row className="border-bottom m-0 pb-2 pt-2">
                            <Col xs={12} sm={12} md={12} lg={12} xxl={12}>
                                <div className="d-flex flex-row flex-nowrap">
                                    {template.categories.map((category) => {
                                        if (category.hidden) return null;
                                        return (
                                            <span
                                                key={
                                                    template.id +
                                                    "-" +
                                                    category.id
                                                }
                                                className="badge pill-badge me-1"
                                                style={{
                                                    backgroundColor:
                                                        "rgb(169, 169, 169)",
                                                }}
                                            >
                                                {category.title}
                                            </span>
                                        );
                                    })}
                                </div>
                            </Col>
                        </Row>

                        <Row className="m-0">
                            <ReviewModal
                                show={showModal}
                                onHide={onHide}
                                handleReject={handleReject}
                                handleApprove={handleApprove}
                                template={template}
                            />
                            {/* Need a cleaner way to do this 
                                img-fluid makes picture tiny
                                reject button stays highlighted when exit modal
                            */}
                            <Col
                                xs={8}
                                sm={8}
                                className="d-flex justify-content-start align-items-center"
                            >
                                <Row className="">
                                    <Col xs={4} className="">
                                        <img
                                            className="pt-3 pb-3 me-3"
                                            alt="Profile"
                                            style={{
                                                maxWidth: "70px",
                                                maxHeight: "70px",
                                            }}
                                            src={getPicture(
                                                template.creator.image
                                            )}
                                        />
                                    </Col>
                                    <Col className="d-flex align-items-center">
                                        <p className="mb-0">
                                            {template.creator.public_name}
                                        </p>
                                    </Col>
                                </Row>
                            </Col>
                            <Col
                                xs={4}
                                sm={4}
                                className="d-flex justify-content-end align-items-center"
                            >
                                <Button
                                    variant="purple-dark"
                                    onClick={() => setShowModal(true)}
                                    className="me-3"
                                >
                                    <FontAwesomeIcon
                                        icon={faEye}
                                        fixedWidth
                                        className="me-2"
                                    />
                                    Review Lesson
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
}

export default PendingReviewCard;
