import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Modal from "react-bootstrap/Modal";
import { useState } from "react";
import Form from 'react-bootstrap/Form';

function RejectModal(props) {
    const [rejectedReason, setRejectedReason] = useState('');
    return (
        <Modal show={props.show} onHide={() => props.onHide()}>
            <Modal.Header closeButton>
                <Modal.Title>Reject Reason</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="p-4">
                    <Form.Label>Briefly explain why you reject this room</Form.Label>
                    <Form.Control
                        placeholder="Enter here"
                        as="textarea" rows={3}
                        onChange={e => setRejectedReason(e.target.value)}
                        />
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="warning" onClick={() => props.handleReject(rejectedReason)}>
                    Save
                    </Button>
                <Button variant="secondary" onClick={() => props.onHide()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RejectModal;
