import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Col from "react-bootstrap/Col";
import { toast } from "react-hot-toast";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";

function RoomGuideModal(props) {
    const handleClose = () => props.setShow(false);

    const handleLoad = (e) => {
        // Force focus on iframe load so that key input works
        e.target.focus();
    };

    const copyToClipboard = () => {
        const clipBoard = navigator.clipboard;
        clipBoard.writeText("contact@gomegaminds.com").then(() => {
            toast.success("Email address was copied to your clipboard.");
        });
    };

    return (
        <Modal
            animation={false}
            autoFocus={false}
            show={props.show}
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>Contact</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
                <Row className="d-grid">
                    <p>
                        Our team is always here to help with anything from
                        technical issues to creating your first 3D lesson or
                        sales.
                    </p>
                    <Col xs={12} className="mt-2">
                        <InputGroup>
                            <Form.Control
                                type="text"
                                value="contact@gomegaminds.com"
                            ></Form.Control>
                            <Button
                                href="mailto:contact@gomegaminds.com"
                                variant="outline-secondary"
                            >
                                <i className="bi bi-envelope-at"></i>
                            </Button>
                            <Button
                                onClick={() => copyToClipboard()}
                                variant="outline-secondary"
                            >
                                <i className="bi bi-clipboard"></i>
                            </Button>
                        </InputGroup>
                    </Col>
                    <Col xs={12} className="mt-3">
                        <Button
                            className="me-2"
                            variant="outline-secondary"
                            onClick={() => {
                                handleClose();
                                props.maximizeTawk();
                            }}
                        >
                            <i className="bi bi-chat me-2"></i> Start Chat
                        </Button>
                        <Button
                            href="https://megaminds.tawk.help"
                            target="_blank"
                            variant="outline-secondary"
                        >
                            <i className="bi bi-info-circle-fill"></i> Help
                            Center
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer className="">
                <Button onClick={handleClose} variant="secondary">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RoomGuideModal;
