import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { getPicture } from "../../../utils/get-picture";

function BundleOnlyTemplateCard({ template, horizontal, setShowCreateModal }) {
    return (
        <Col
            key={template.id}
            sm={horizontal ? 12 : 6}
            md={horizontal ? 12 : 4}
            lg={horizontal ? 12 : 3}
            className={horizontal ? "mb-3" : "mb-5"}
        >
            <Card className="">
                <Row>
                    <Col xs={horizontal ? 4 : 12}>
                        <Card.Img
                            height={horizontal ? "100%" : "100px"}
                            className={horizontal ? "dim rounded-0" : "dim"}
                            variant="top"
                            src={getPicture(template.image)}
                        />
                        <Card.ImgOverlay className="d-flex justify-content-between align-items-center p-4"></Card.ImgOverlay>
                    </Col>
                    <Col
                        className={
                            horizontal ? "d-flex align-items-center me-2" : ""
                        }
                    >
                        <Card.Body
                            className={horizontal ? "" : "border-bottom"}
                        >
                            <Row>
                                <Col xs={12}>
                                    <Card.Title className="text-dark mb-3">
                                        {template.title}
                                    </Card.Title>
                                </Col>
                            </Row>
                        </Card.Body>
                    </Col>
                </Row>
            </Card>
        </Col>
    );
}

export default BundleOnlyTemplateCard;
