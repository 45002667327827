import React from "react";

function ErrorScreen(props) {
    if (props.error === "Is not authenticated") {
        return (
            <div className="position-relative vh-100 vw-100">
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div className="text-center">
                        <p>Your session has expired.</p>
                        <button
                            className="btn btn-purple-dark"
                            onClick={() => props.loginWithRedirect()}
                        >
                            Log back in
                        </button>
                    </div>
                </div>
            </div>
        );
    } else if (props.error) {
        return (
            <div className="position-relative vh-100 vw-100">
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div className="text-center">
                        {props.errorTitle && (
                            <h2 className="mb-5">{props.errorTitle}</h2>
                        )}
                        <p>{props.error}</p>
                        {props.showLoginButton && (
                            <button
                                className="btn btn-purple-dark"
                                onClick={() => props.loginWithRedirect()}
                            >
                                Log back in
                            </button>
                        )}
                    </div>
                </div>
                <div className="position-absolute sticky-bottom d-flex w-100 justify-content-center">
                    <div className="text-center">
                        <p>
                            If the error persists, feel free to contact support:
                        </p>
                        <p>
                            <a href="mailto:contact@gomegaminds.com">
                                contact@gomegaminds.com
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        );
    } else {
        return (
            <div className="position-relative vh-100 vw-100">
                <div className="position-absolute top-50 start-50 translate-middle">
                    <div className="text-center">
                        <p>
                            There was an issue on our backend and we could not
                            fetch your profile.
                        </p>
                        <p>
                            Our development team has been notified through
                            automatic measures.
                        </p>
                    </div>
                </div>
                <div className="position-absolute sticky-bottom d-flex w-100 justify-content-center">
                    <div className="text-center">
                        <p>
                            If the error persists, feel free to contact support:
                        </p>
                        <p>
                            <a href="mailto:contact@gomegaminds.com">
                                contact@gomegaminds.com
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export default ErrorScreen;
