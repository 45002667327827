import React from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

// Function to generate random color
const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

const UsersOverTimeChart = () => {
    const jsonData = `{
    "id": 21,
    "title": "fresh (copy)",
    "last_active": "2024-01-22T08:24:22.512772Z",
    "is_fresh": false,
    "is_distributed": false,
    "locked": false,
    "guest_has_joined": false,
    "guests_can_move": false,
    "guests_can_create": false,
    "guests_can_chat": true,
    "guests_can_draw": false,
    "guests_can_raise_hand": true,
    "guests_can_react": true,
    "guests_can_share_video": true,
    "guests_can_share_microphone": true,
    "guests_can_see_student_menu": true,
    "guests_can_spawn_stickynotes": false,
    "session_set": [
        {
            "id": 30,
            "name": "bob",
            "time_spent_minutes": 0.011256016666666667,
            "date_start": "2023-08-22T08:24:21.830409Z",
            "date_end": "2023-08-22T08:24:22.505770Z",
            "logevent_set": []
        },
        {
            "id": 29,
            "name": "bob",
            "time_spent_minutes": 0.38365343333333335,
            "date_start": "2023-09-22T08:23:56.701471Z",
            "date_end": "2023-09-22T08:24:19.720677Z",
            "logevent_set": []
        }
    ],
    "distributed_children": [],
    "hub_id": "mmxPGPn"
  }`;

    // Parse JSON
    const data = JSON.parse(jsonData);

    // Create a dictionary to hold total time for each user per month
    const totalTimePerUserPerMonth = {};

    // Process each session
    data.session_set.forEach((session) => {
        const monthYear = session.date_start.slice(0, 7); // Extracting YYYY-MM from the date
        const key = `${session.name}_${monthYear}`;

        if (!totalTimePerUserPerMonth[key]) {
            totalTimePerUserPerMonth[key] = 0;
        }
        totalTimePerUserPerMonth[key] += session.time_spent_minutes;
    });

    // Extract unique months for labels
    const uniqueMonths = [
        ...new Set(
            Object.keys(totalTimePerUserPerMonth).map(
                (key) => key.split("_")[1]
            )
        ),
    ];

    // Create datasets for each user
    const datasets = Object.keys(totalTimePerUserPerMonth).reduce(
        (acc, key) => {
            const [userName, monthYear] = key.split("_");
            const monthIndex = uniqueMonths.indexOf(monthYear);

            if (!acc[userName]) {
                acc[userName] = new Array(uniqueMonths.length).fill(0);
            }

            acc[userName][monthIndex] = totalTimePerUserPerMonth[key];
            return acc;
        },
        {}
    );

    // Prepare data for react-chartjs-2
    const chartData = {
        labels: uniqueMonths,
        datasets: Object.keys(datasets).map((userName) => ({
            label: userName,
            data: datasets[userName],
            fill: false,
            borderColor: getRandomColor(), // Generate random color for each user
        })),
    };

    // Chart.js configuration
    const chartOptions = {
        responsive: true,
        scales: {
            x: {
                type: "category",
                labels: uniqueMonths,
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div>
            <Line data={chartData} options={chartOptions} />
        </div>
    );
};

export default UsersOverTimeChart;
