import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth } from "../context/AuthContext";
import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons";
import HomeGuest from "./HomeGuest";
import NewRoomButton from "./rooms/NewRoomButton";
import NewAssignmentButton from "./assignments/NewAssignmentButton";
import RoomGuideModal from "./home/modals/RoomGuideModal";
import ContactModal from "./home/modals/ContactModal";

import useDashApi from "../services/useDashApi";
import useDashApiGuest from "../services/useDashApiGuest";
import useFeaturedAPI from "../services/useFeaturedAPI";
import useTemplateAPI from "../services/useTemplateAPI";
import RecommendedGuide from "./home/RecommendedGuide";
import zenImage from "../img/zenImage.png";
import avi1img from "../img/1.png";
import pythagoras from "../img/class_2.png";
import edTech from "../img/edtech_cluster.png";
import supportImg from "../img/support.png";

import TemplateCard from "./explore/cards/TemplateCard";
import BundleCard from "./explore/cards/BundleCard";
import { useAuth0 } from "@auth0/auth0-react";
import { rejectInvite, acceptInvite } from "../utils/invite-utils";

function HomeCard(props) {
    return (
        <Col onClick={props.onClick} xs={6} md={4} lg={3} xl={3} xxl={2}>
            <a
                className="text-decoration-none"
                href={props.comingSoon ? null : props.href}
                target="_blank"
            >
                <Card
                    className={
                        props.comingSoon
                            ? "h-100 disabled-card-home"
                            : "h-100 cursor-pointer hover-shadow"
                    }
                >
                    {props.comingSoon && (
                        <div class="position-absolute top-100 start-50 translate-middle">
                            {" "}
                            <Badge bg="purple-dark">Coming Soon</Badge>
                        </div>
                    )}
                    <Card.Body>
                        <i
                            style={{ fontSize: "1.2rem" }}
                            className={"bi " + props.icon + " me-3"}
                        ></i>
                        <p className="fw-bold mb-2">{props.title}</p>
                        <p>
                            <small>{props.body}</small>
                        </p>
                    </Card.Body>
                </Card>
            </a>
        </Col>
    );
}

const Home = (props) => {
    const auth = useAuth();
    const navigate = useNavigate();
    const { getAccessTokenSilently } = useAuth0();

    const [invites, refreshInvites] = useDashApi("organizations/invites", auth);

    const [showRoomModal, setShowRoomModal] = useState(false);
    const [showContactModal, setShowContactModal] = useState(false);

    const [featured, isFeaturedLoading, featuredError, refresh] =
        useFeaturedAPI();

    const handleInvite = (variant, inviteid) => {
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "create:teacher_profile",
        }).then((token) => {
            if (variant === "accept") {
                acceptInvite(token, inviteid, refreshInvites);
            } else {
                rejectInvite(token, inviteid, refreshInvites);
            }
        });
    };

    if (!auth.profile || !auth.user) {
        return <HomeGuest />;
    }

    return (
        <>
            <RoomGuideModal show={showRoomModal} setShow={setShowRoomModal} />
            <ContactModal
                show={showContactModal}
                setShow={setShowContactModal}
                maximizeTawk={props.maximizeTawk}
            />
            <Container
                fluid
                className="bg-light pt-5 pb-5"
                style={{
                    backgroundImage: "url(" + zenImage + ")",
                    backgroundPosition: "center",
                    backgroundSize: "cover",
                }}
            >
                <Container className="bg-transparent mt-5 mb-5 p-md-5">
                    <Row className="">
                        <Col md={8} xs={12} lg={7} xl={4}>
                            <Card>
                                <Card.Body>
                                    <h3 className="mb-3">
                                        Welcome back, {auth.profile?.first_name}
                                    </h3>
                                    <Row className="mt-2">
                                        <Col xs={12} md={12} lg={12} xl={12}>
                                            <NewAssignmentButton fullWidth />
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12} lg={12} xl={12}>
                                            <NewRoomButton
                                                fullWidth
                                                onFinish={(id) =>
                                                    navigate("/lessons", {
                                                        state: {
                                                            newRoom: id,
                                                        },
                                                    })
                                                }
                                            />
                                        </Col>
                                    </Row>
                                    <Row className="mt-2">
                                        <Col xs={12} md={12} lg={12} xl={12}>
                                            <Button
                                                variant="purple-dark-outline"
                                                className="m-0 text-purple-dark w-100 text-start"
                                                as={Link}
                                                to="/explore"
                                            >
                                                <i className="bi bi-search me-2"></i>
                                                Explore Content Library
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container className="bg-light-2">
                <Helmet>
                    <title>Home | MegaMinds</title>
                    <meta
                        name="description"
                        content="MegaMinds turns your existing lessons and content into 3D interactive experiences that students explore in a virtual world."
                    />
                </Helmet>

                {auth.profile &&
                    invites?.length > 0 &&
                    invites.map((invite) => (
                        <Row className="p-0 p-md-5 pb-0 pt-3" key={invite.id}>
                            <Col xs={12} md={6}>
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col className="d-flex justify-content-start align-content-center">
                                                <p className="mb-0">
                                                    {invite.inviter.first_name}{" "}
                                                    has invited you to join{" "}
                                                    <span className="fw-bold">
                                                        {
                                                            invite.organization
                                                                .name
                                                        }
                                                    </span>
                                                </p>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <Button
                                                    onClick={() =>
                                                        handleInvite(
                                                            "accept",
                                                            invite.id
                                                        )
                                                    }
                                                    variant="success"
                                                >
                                                    Accept
                                                </Button>
                                                <Button
                                                    onClick={() =>
                                                        handleInvite(
                                                            "reject",
                                                            invite.id
                                                        )
                                                    }
                                                    variant="danger"
                                                    className="ms-2"
                                                >
                                                    Reject
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    ))}
            </Container>
            <Container fluid className="p-0 p-md-5">
                <Container>
                    <Row>
                        <Col xs={12}>
                            <h3 className="mt-3 mb-3">Getting started</h3>
                        </Col>
                    </Row>
                    <Row className="g-3">
                        <HomeCard
                            onClick={() => setShowRoomModal(true)}
                            title="Mini Tour"
                            body="Open a 3D Lesson and learn how to present, teach and collaborate with MegaMinds
"
                            icon="bi-badge-3d"
                        />
                        <HomeCard
                            title="Video tutorials"
                            href="https://www.youtube.com/playlist?list=PLx_SQK3htPb-JsBzD9aotCduqIQLJX5A7"
                            body="Learn about MegaMinds through our video series"
                            icon="bi-camera-video"
                        />
                        <HomeCard
                            href="https://gomegaminds.com/teacher-resources"
                            title="Teacher Resources"
                            body="Techniques, activities, ideas and more to get the most out of MegaMinds"
                            icon="bi-book"
                        />
                        <HomeCard
                            title="Student Creation"
                            href="https://megaminds.tawk.help/article/guide-to-student-projects"
                            body="Let your students create their own 3D projects with Student Projects"
                            icon="bi-backpack"
                        />
                        <HomeCard
                            onClick={() => setShowContactModal(true)}
                            title="Contact us"
                            body="Our support staff will help you with any techincal or experience questions"
                            icon="bi-envelope"
                        />
                        <HomeCard
                            href="https://gomegaminds.com/schools-districts/"
                            title="Schools & Districts"
                            body="Explore what MegaMinds can do for your School or District"
                            icon="bi-buildings"
                        />
                    </Row>
                    <Row className="">
                        <Col xs={12}>
                            <h3 className="mt-3 mb-3 mt-md-5">
                                Featured Content
                            </h3>
                        </Col>
                    </Row>
                    <Row className="g-3">
                        {featured &&
                            featured.map((listing) => {
                                if (!listing.bundleattachment_set) {
                                    return (
                                        <TemplateCard
                                            home={true}
                                            key={listing.id}
                                            bundle={
                                                !!listing.bundleattachment_set
                                            }
                                            refresh={refresh}
                                            template={listing}
                                        />
                                    );
                                } else {
                                    return (
                                        <BundleCard
                                            home={true}
                                            key={listing.id}
                                            bundle={listing}
                                            refresh={refresh}
                                        />
                                    );
                                }
                            })}
                    </Row>
                    <Row>
                        <Col>
                            <Button
                                variant="link"
                                className="m-0 p-0 mt-3 mb-3"
                                as={Link}
                                to={"/explore"}
                            >
                                <small className="fw-light">
                                    Explore the Content Library
                                </small>
                            </Button>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="home-darker">
                <Container className="pt-4 pb-4">
                    <Row className="bg-transparent p-0 p-md-5">
                        <Col xs={12} md={6} className="">
                            <Row>
                                <Col xs={12} md={6}>
                                    <h4 className="">
                                        Tap into student creativity with Student
                                        Projects
                                    </h4>
                                    <p>
                                        Let your students take over the room
                                        building. Students can demonstrate
                                        knowledge in fun, creative ways and can
                                        collaborate with each other in
                                        Project-Based Learning.
                                    </p>
                                    <Button
                                        href="https://megaminds.tawk.help/article/guide-to-student-projects"
                                        target="_blank"
                                        variant="purple-dark"
                                    >
                                        Learn More
                                    </Button>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center d-none d-md-flex">
                                    <img
                                        src={avi1img}
                                        style={{ maxHeight: "100px" }}
                                        className="img-fluid"
                                    />
                                </Col>
                            </Row>
                        </Col>
                        <Col>
                            <Row>
                                <Col xs={12} md={6}>
                                    <h4 className="mt-3">
                                        Present the content you already have
                                    </h4>
                                    <p>
                                        Build engaging lessons by dragging and
                                        dropping the content you already have.
                                        Our Lesson Guides will also suggest
                                        standards-aligned content that make
                                        creating 3D Lessons effortless.
                                    </p>
                                    <Button
                                        href="https://megaminds.tawk.help/article/supported-files-and-material"
                                        target="_blank"
                                        variant="purple-dark"
                                    >
                                        Learn More
                                    </Button>
                                </Col>
                                <Col className="d-flex justify-content-center align-items-center d-none d-md-flex">
                                    <img
                                        src={pythagoras}
                                        className="img-fluid"
                                    />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="home-purple border-top pt-4 pb-4">
                <Container>
                    <Row className="bg-transparent p-0 p-md-5 d-flex align-items-center">
                        <Col xs={12} md={6}>
                            <h4>Include the tools you already use</h4>
                            <p>
                                Did you know you can bring your existing
                                Mathigon formulas, Kahoot! assessments, Pear
                                Deck presentations and more into your 3D Lesson
                                in MegaMinds? Enhance, not rebuild, your
                                curriculum.
                            </p>
                            <Button
                                href="https://megaminds.tawk.help/article/use-edtech-in-your-room"
                                target="_blank"
                                variant="purple-dark"
                            >
                                Learn more
                            </Button>
                        </Col>
                        <Col className="p-5 d-flex justify-content-center align-items-center">
                            <img
                                src={edTech}
                                className="img-fluid"
                                style={{ maxWidth: "70%" }}
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container fluid className="border-top" id="support">
                <Container className="pt-4 pb-4">
                    <Row className="bg-transparent p-0 p-md-5 d-flex align-items-center">
                        <Col xs={12} md={6}>
                            <h4>Support</h4>
                            <p>
                                No robots - talk to your human support staff and
                                get help with anything from technical issues to
                                creating your first 3D lesson.
                            </p>
                            <Button
                                variant="purple-dark"
                                onClick={props.maximizeTawk}
                            >
                                Start Chat
                            </Button>
                            <Button
                                className="ms-2"
                                href="mailto:contact@gomegaminds.com"
                                variant="outline-secondary bg-white text-dark"
                            >
                                Email
                            </Button>
                            <p className="fw-light mb-0 mt-2">
                                <small>contact@gomegaminds.com</small>
                            </p>
                        </Col>
                        <Col className="p-5 d-none justify-content-center align-items-center d-sm-flex">
                            <img
                                src={supportImg}
                                className="img-fluid"
                                style={{ maxWidth: "60%" }}
                            />
                        </Col>
                    </Row>
                </Container>
            </Container>
        </>
    );
};

export default Home;
