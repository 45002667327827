import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { contains } from "./utils";

const Categories = ({
    selectedCategories,
    setSelectedCategories,
    categories,
}) => {
    const handleCategoryCheck = (category) => {
        const isChecked = contains(selectedCategories, category);

        if (isChecked) {
            // Remove from list
            const cleanedCategories = selectedCategories.filter(
                (oldCategory) => oldCategory.id !== category.id
            );
            setSelectedCategories(cleanedCategories);
        } else {
            // Add to list
            setSelectedCategories([...selectedCategories, category]);
        }
    };

    return (
        <Row className="mt-4">
            {/* Categories */}
            <Col xs={12}>
                <p className="fw-bold">Subjects</p>
            </Col>
            <Col xs={12} className="d-flex justify-content-start">
                <Row>
                    {categories &&
                        categories.length &&
                        categories.map((category) => {
                            if (category.hidden) return null;
                            if (!category.featured) return null;
                            return (
                                <Col xs={12}>
                                    <Form.Check
                                        id={"category-" + category.title}
                                        onClick={() => {
                                            handleCategoryCheck(category);
                                        }}
                                        checked={contains(
                                            selectedCategories,
                                            category
                                        )}
                                        label={category.title}
                                    />
                                </Col>
                            );
                        })}
                </Row>
            </Col>
            <Col />
        </Row>
    );
};

export default Categories;
