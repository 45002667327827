import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Alert from "react-bootstrap/Alert";
import Col from "react-bootstrap/Col";
import RoomsHeader from "./rooms/RoomsHeader";
import RoomsContent from "./rooms/RoomsContent";
import EmptyRoomsPage from "./rooms/EmptyRoomsPage";
import InviteModal from "./rooms/modals/InviteModal";
import { AssignFromRoomModal } from "./rooms/modals/AssignFromRoomModal";

import { Helmet } from "react-helmet";
import useRoomAPI from "../services/useRoomAPI";
import useAssignmentAPI from "../services/useAssignmentAPI";
import useSingleRoomAPI from "../services/useSingleRoomAPI";

import { useAuth } from "../context/AuthContext";
import useSharedRoomAPI from "../services/useSharedRoomAPI";

const Rooms = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const auth = useAuth();

    const [newRoom, setNewRoom] = useState();

    useEffect(() => {
        if (location.state?.newRoom) {
            setNewRoom(location.state?.newRoom);
            console.log("Setting new room", location.state?.newRoom);
        }
    }, [location.state]);

    console.log("State", location.state);

    const [showInviteModalState, setShowInviteModalState] = useState(false);
    const [showAssignModalState, setShowAssignModalState] = useState(
        location.state?.showAssignmentModal
    );
    const [page, setPage] = useState(1);
    const [searchValue, setSearchValue] = useState("");
    const [sortValue, setSortValue] = useState("-id");

    const [selectedId, setSelectedId] = useState(
        location.state?.selectedClassroom
    );
    const [
        selectedClassroom,
        singleRoomLoading,
        singleRoomError,
        refreshSingleRoom,
    ] = useSingleRoomAPI(selectedId);

    const [rooms, isLoadingRooms, roomsError, refreshRooms] = useRoomAPI(
        page,
        searchValue,
        sortValue,
        auth
    );


    const [
        studentProjects,
        isLoadingStudentProjects,
        studentProjectsError,
        refreshStudentProjects,
    ] = useAssignmentAPI(1, "", "-id", auth);

    useEffect(() => {
        if (location.state) {
            navigate(location.pathname, { replace: true });
        }
    }, [location.pathname, location.state, navigate]);

    const handleSearch = (value) => {
        setSearchValue(value);
    };

    const hasInvalidPlan =
        auth.profile?.subscription_plan === "Free Plan" ||
        auth.profile?.subscription_plan === "Action Required";

    const shouldDelete =
        hasInvalidPlan && !isLoadingRooms && rooms?.results?.length > 3;

    const restricted =
        auth.profile?.subscription_plan === "Free Plan" &&
        rooms?.results?.length === 3;

    const restrictedStudentProjects =
        auth.profile?.subscription_plan === "Free Plan" &&
        studentProjects?.results?.length === 3;

    const showInviteModal = (classroom) => {
        setSelectedId(classroom.id);
        setShowInviteModalState(true);
    };

    const showAssignModal = (classroom) => {
        setSelectedId(classroom.id);
        setShowAssignModalState(true);
    };

    const hideAndShowStudentProject = () => {
        setShowInviteModalState(false);
    };

    return (
        <>
            <Helmet>
                <title>Lessons | MegaMinds</title>
            </Helmet>
            <Container fluid className="g-0 bg-light">
                <InviteModal
                    show={showInviteModalState}
                    setShow={setShowInviteModalState}
                    selectedClassroom={selectedClassroom}
                    selectedClassroomLoading={singleRoomLoading}
                    selectedClassroomError={singleRoomError}
                    hideAndShowStudentProject={hideAndShowStudentProject}
                    refresh={refreshRooms}
                    onFinished={() => refreshRooms()}
                />
                <AssignFromRoomModal
                    show={showAssignModalState}
                    setShow={setShowAssignModalState}
                    selectedRoom={selectedClassroom}
                    selectedClassroomLoading={singleRoomLoading}
                    selectedClassroomError={singleRoomError}
                    hideAndShowStudentProject={hideAndShowStudentProject}
                    refresh={refreshRooms}
                    onFinished={() => refreshRooms()}
                />
                <RoomsHeader
                    refreshRooms={refreshRooms}
                    restricted={restricted}
                />
            </Container>

            
            <RoomsContent
                refreshSingleRoom={refreshSingleRoom}
                searchValue={searchValue}
                setSortValue={setSortValue}
                sortValue={sortValue}
                page={page}
                setPage={setPage}
                auth={auth}
                restricted={restricted}
                restrictedStudentProjects={restrictedStudentProjects}
                refreshRooms={refreshRooms}
                rooms={rooms}
                newRoom={newRoom}
                handleSearch={handleSearch}
                showInviteModal={showInviteModal}
                showAssignModal={showAssignModal}
                isLoadingRooms={isLoadingRooms}
                roomsError={roomsError}
            />
    

            {shouldDelete && (
                <Row className="m-0 p-0 p-md-4">
                    <Col>
                        <Alert variant="warning">
                            <p>
                                The free plan limits your capacity to{" "}
                                <span className="fw-bold">3</span> Lessons.
                            </p>
                            <p className="mb-0">
                                <Link to="/buy">Upgrade your account</Link> or
                                delete some rooms to continue.
                            </p>
                        </Alert>
                    </Col>
                </Row>
            )}
        </>
    );
};

export default Rooms;
