import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "../img/Spinner.svg";

const SignupRedirect = (props) => {
    const { loginWithRedirect } = useAuth0();

    useEffect(() => {
        props.setHideSidebar(true);
        loginWithRedirect({
            authorizationParams: {
                screen_hint: "signup",
            },
        });
    }, []);

    return (
        <div className="position-relative vh-100 vw-100">
            <div className="position-absolute top-50 start-50 translate-middle">
                <img alt="Loading spinning figure" src={Spinner} />
                <p className="text-center">Redirecting...</p>
            </div>
        </div>
    );
};

export default SignupRedirect;
