export default function getAvatarPicture(avatarId) {
    let avatarImage;

    try {
        avatarImage = require("../img/avatarthumbnails/" + avatarId + ".png");
    } catch (e) {
        avatarImage = null;
    }

    if (avatarImage) {
        return (
            <img
                style={{
                    width: "50px",
                    height: "50px",
                    objectFit: "cover",
                    objectPosition: "0% 0%",
                }}
                alt="The avatar currently used by the user"
                className="rounded rounded-circle img-fluid"
                src={avatarImage}
            />
        );
    }
    return (
        <p>Legacy Avatar</p>
    );
}
