import React, { useState } from "react";
import { useParams } from "react-router-dom";

import useDashApiGuest from "../../services/useDashApiGuest";
import ListingDetailContent from "./ListingDetailContent";

import "moment-timezone";

const ListingDetail = (props) => {
    const { id } = useParams();

    const [template, isLoading, isError, refreshTemplate] = useDashApiGuest(
        "templates/" + id,
        "read:templates"
    );

    if (isLoading) {
        return <p>Loading</p>;
    }

    if (isError) {
        console.error(isError);
        return <p>Error loading Lesson data</p>;
    }

    return <ListingDetailContent template={template} />;
};

export default ListingDetail;
