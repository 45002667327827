import React from "react";
import ReactDOM from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import "./index.css";
import App from "./App";
import { Helmet } from "react-helmet";
import { BrowserRouter } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";

import "bootstrap/dist/css/bootstrap.min.css";
import Hotjar from "@hotjar/browser";
import ReactGA from "react-ga4";

import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import { createBrowserHistory } from "history";
export const history = createBrowserHistory();

if (process.env.REACT_APP_MM_ENV === "production") {
    ReactGA.initialize("G-0RC147Z9PH");

    Sentry.init({
        dsn: "https://b0a87335e99b47ada61bde3549c9b4b2@o4503923994656768.ingest.sentry.io/4503924045185026",
        integrations: [new BrowserTracing()],

        environment: "production",

        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
    });
    const siteId = 3815601;
    const hotjarVersion = 6;

    Hotjar.init(siteId, hotjarVersion);
}

const onRedirectCallback = (appState) => {
    // Use the router's history module to replace the url
    console.log("got onredirectcallback", appState);
    if (appState?.returnTo) {
        window.location.replace(appState.returnTo);
    }

    history.replace(appState?.returnTo || window.location.pathname);
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <Auth0Provider
            domain={process.env.REACT_APP_AUTH0_API_URL}
            clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: window.location.origin,
                audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                scope: "openid profile email read:classrooms read:sessions read:session create:session delete:session read:scenes create:classroom read:classsessions create:classsessions read:teacher_profile create:teacher_profile update:teacher_profile update:classroom update:session delete:sessions delete:classroom read:dash",
            }}
            cookieDomain={process.env.REACT_APP_AUTH0_COOKIE_DOMAIN}
            cacheLocation="localstorage"
            onRedirectCallback={onRedirectCallback}
        >
            <BrowserRouter history={history}>
                    <App />
            </BrowserRouter>
        </Auth0Provider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
