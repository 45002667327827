import React, { useState, useCallback, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import toast from "react-hot-toast";

import { useAuth0 } from "@auth0/auth0-react";

function RoomSecurityModal(props) {
    const [show, setShow] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    console.log("RELATED ROOM", props.object, props.object.related_room)
    if(!props.object.related_room) return;
    const defaultPermissions = props.object.related_room;

    const [locked, setLocked] = useState(defaultPermissions.locked);
    const [showResetConfirmation, setShowResetConfirmation] = useState(
        defaultPermissions.locked
    );

    const [voice, setVoice] = useState(
        defaultPermissions.guests_can_share_microphone
    );
    const [react, setReact] = useState(defaultPermissions.guests_can_react);
    const [chat, setChat] = useState(defaultPermissions.guests_can_chat);
    const [hand, setHand] = useState(defaultPermissions.guests_can_raise_hand);
    const [video, setVideo] = useState(
        defaultPermissions.guests_can_share_video
    );

    const [isPosting, setIsPosting] = useState(false);
    const [newLink, setNewLink] = useState("");
    const [isPostingLink, setIsPostingLink] = useState(false);

    const handleClose = () => {
        setShow(false);
        setShowResetConfirmation(false);
        setNewLink("");
    };

    const lockRooms = useCallback(() => {
        if (props.socket) {
            // For each room, join and send an update message
            props.object.classroom_set?.forEach((room) => {
                let ch = props.socket
                    .channel("hub:" + room.hub_id, {
                        profile: {
                            displayName: "teacher_bot_2df",
                            avatarId: "",
                        },
                        context: {
                            mobile: false,
                            hmd: false,
                            discord: true,
                        },
                    })
                    .join()
                    .receive("ok", (e) => {
                        console.log("joined channel", e);
                        const payload = {
                            type: "lockedRoom",
                        };

                        ch.channel.push("message", payload);
                    })
                    .receive("error", (e) => {
                        console.error("Could not connect to socket.channel", e);
                    });
            });
        }
    }, [props.socket]);

    const forceSyncPermissions = useCallback(() => {
        if (props.socket) {
            // For each room, join and send an update message
            props.object.classroom_set?.forEach((room) => {
                let ch = props.socket
                    .channel("hub:" + room.hub_id, {
                        profile: {
                            displayName: "teacher_bot_2df",
                            avatarId: "",
                        },
                        context: {
                            mobile: false,
                            hmd: false,
                            discord: true,
                        },
                    })
                    .join()
                    .receive("ok", (e) => {
                        console.log("joined channel", e);
                        const payload = {
                            type: "updatePermissions",
                        };

                        ch.channel.push("message", payload);
                    })
                    .receive("error", (e) => {
                        console.error("Could not connect to socket.channel", e);
                    });
            });
        }
    }, [props.socket]);

    const handleResetLink = () => {
        setIsPostingLink(true);

        const hub_payload = {
            hub: {
                name: "Megaminds API",
                description: "",
            },
        };

        return new Promise((resolve, error) => {
            fetch(process.env.REACT_APP_HUBS_ENDPOINT + "/api/v1/hubs", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(hub_payload),
            })
                .then((res) => res.json())
                .then((res) => {
                    const payload = {
                        base_room_link: res.url,
                        hub_id: res.hub_id,
                    };

                    getAccessTokenSilently({
                        audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                        scope: "update:classroom",
                    }).then((token) => {
                        fetch(
                            process.env.REACT_APP_MM_API_URL +
                                "classrooms/" +
                                props.object.hub_id +
                                "/",
                            {
                                method: "PATCH",
                                headers: {
                                    "Content-Type": "application/json",
                                    Authorization: `Bearer ${token}`,
                                },
                                body: JSON.stringify(payload),
                            }
                        )
                            .then((response) => {
                                return response.json();
                            })
                            .then((data) => {
                                console.log("Success", data);
                                props.refresh();
                                setIsPostingLink(false);
                                setNewLink(
                                    process.env.REACT_APP_3D_ENDPOINT +
                                        "/" +
                                        data.hub_id +
                                        "/"
                                );
                                toast.success("Lesson link successfully reset");
                            })
                            .catch((error) => {
                                toast.error(
                                    "Something went wrong while saving."
                                );
                                console.error(error);
                                setIsPostingLink(false);
                            });
                    });
                })
                .catch((e) => {
                    error(e);
                });
        });
    };

    const handleSubmit = () => {
        setIsPosting(true);

        if (locked) {
            lockRooms();
        }
        let payload = {
            locked: locked,
            guests_can_chat: chat,
            guests_can_react: react,
            guests_can_raise_hand: hand,
            guests_can_share_microphone: voice,
            guests_can_see_student_menu:
                defaultPermissions.guests_can_see_student_menu,
            guests_can_share_video: video,
            guests_can_spawn_stickynotes:
                defaultPermissions.guests_can_spawn_stickynotes,
        };

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "classrooms/" +
                    props.object.related_room.hub_id +
                    "/",
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                    forceSyncPermissions();

                    setShow(false);
                    props.refresh();
                    setIsPosting(false);
                    toast.success("Changes were saved.");
                })
                .catch((error) => {
                    toast.error("Something went wrong while saving.");
                    console.error(error);
                    setIsPosting(false);
                });
        });
    };

    const orgPerms = props.auth.profile.organization_permissions;

    return (
        <>
            <Button
                data-tooltip-id="classroomCard"
                data-tooltip-content={
                    props.assignment
                        ? "Control assignment access and security"
                        : "Control Lesson security, access and permissions"
                }
                onClick={() => setShow(true)}
                variant="card-dark"
            >
                <i className="bi bi-lock-fill"></i>
            </Button>

            <Modal centered show={show} scrollable onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Student Project Security</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5>Access</h5>
                    <Form>
                        <Form.Group className="" controlId="formBasicEmail">
                            <Form.Check // prettier-ignore
                                type="switch"
                                id="custom-switch"
                                onChange={() => setLocked(!locked)}
                                checked={locked}
                                label={"Lock all Student Spaces"}
                                className="mb-2"
                            />
                            <Form.Check.Label className="fw-light text-muted">
                                <small>
                                    When you lock this project, only you can
                                    enter and view the student's spaces.
                                </small>
                            </Form.Check.Label>
                        </Form.Group>
                    </Form>
                    <hr />
                    {!props.assignment && (
                        <>
                            <h5 className="mt-4">Permissions</h5>
                            <Form.Check.Label className="fw-light text-muted">
                                <small>
                                    Change what students can do inside their
                                    spaces.
                                </small>
                            </Form.Check.Label>
                            <div className="">
                                <p className="fw-light mt-2 mb-2">
                                    Communication
                                </p>
                                <Form>
                                    <Form.Check
                                        type="switch"
                                        id="chat"
                                        disabled={orgPerms?.deny_chat}
                                        label={
                                            orgPerms?.deny_chat
                                                ? "Enable Chat (Disabled by Administrator)"
                                                : "Enable Chat"
                                        }
                                        onChange={() => setChat(!chat)}
                                        checked={
                                            orgPerms?.deny_chat ? false : chat
                                        }
                                    />
                                    <Form.Check
                                        type="switch"
                                        id="reactions"
                                        disabled={orgPerms?.deny_reactions}
                                        label={
                                            orgPerms?.deny_reactions
                                                ? "Enable Reactions (Disabled by Administrator)"
                                                : "Enable Reactions"
                                        }
                                        onChange={() => setReact(!react)}
                                        checked={
                                            orgPerms?.deny_reactions
                                                ? false
                                                : react
                                        }
                                    />
                                    <Form.Check
                                        type="switch"
                                        id="hand"
                                        label={
                                            orgPerms?.deny_raise_hand
                                                ? "Enable Hand Raising (Disabled by Administrator)"
                                                : "Enable Hand Raising"
                                        }
                                        disabled={orgPerms?.deny_raise_hand}
                                        onChange={() => setHand(!hand)}
                                        checked={
                                            orgPerms?.deny_raise_hand
                                                ? false
                                                : hand
                                        }
                                    />
                                    <Form.Check
                                        type="switch"
                                        id="voice"
                                        label={
                                            orgPerms?.deny_microphone
                                                ? "Enable Microphone (Disabled by Administrator)"
                                                : "Enable Microphone"
                                        }
                                        disabled={orgPerms?.deny_microphone}
                                        onChange={() => setVoice(!voice)}
                                        checked={
                                            orgPerms?.deny_microphone
                                                ? false
                                                : voice
                                        }
                                    />
                                    <Form.Check
                                        type="switch"
                                        id="share"
                                        label={
                                            orgPerms?.deny_video
                                                ? "Enable Webcam and Screensharing (Disabled by Administrator)"
                                                : "Enable Webcam and Screensharing"
                                        }
                                        disabled={orgPerms?.deny_video}
                                        onChange={() => setVideo(!video)}
                                        checked={
                                            orgPerms?.deny_video ? false : video
                                        }
                                    />
                                </Form>
                            </div>
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Discard
                    </Button>
                    <Button
                        variant="success"
                        disabled={isPosting ? true : false}
                        onClick={handleSubmit}
                    >
                        {isPosting ? (
                            <>
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                <span>Loading...</span>
                            </>
                        ) : (
                            <span>Save changes</span>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RoomSecurityModal;
