import React, { useState, useEffect } from "react";
import { Socket, Presence } from "phoenix";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import { Tooltip } from "react-tooltip";
import Form from "react-bootstrap/Form";
import { Link, useNavigate } from "react-router-dom";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import { useAuth0 } from "@auth0/auth0-react";

export function AssignFromRoomModal(props) {
    const handleClose = () => props.setShow(false);
    const { getAccessTokenSilently } = useAuth0();

    const navigate = useNavigate();

    const [isPosting, setIsPosting] = useState(false);
    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState("");
    const [description, setDescription] = useState("");
    const [group, setGroup] = useState(false);
    const [empty, setEmpty] = useState(true);

    const createAssignment = () => {
        setIsPosting(true);

        if (!title) {
            setTitleError(true);
            setIsPosting(false);
            return;
        } else {
            setTitleError(false);
        }

        let assignment = {
            title: title,
            instructions: description,
            is_group: group,
            related_room: props.selectedRoom.id,
        };

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "create:classroom",
        }).then((token) => {
            fetch(process.env.REACT_APP_MM_API_URL + "assignments/create", {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${token}`,
                    Accept: "application/json",
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(assignment),
            })
                .then((res) => res.json())
                .then((data) => {
                    console.log("SUCCESSFULLY CREATED ASSIGNMENT", data);
                    navigate("/student-projects");
                })
                .catch((e) => {
                    console.error(e);
                });
        });
    };

    return (
        <Modal
            show={props.show}
            size="xl"
            fullscreen="md-down"
            onHide={handleClose}
        >
            <Modal.Header closeButton>
                <Modal.Title>New Student Project</Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-light">
                <Row className="m-0 p-0 g-3">
                    <Col xs={12} md={6} className="">
                        <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                            <div className="text-start pt-0 pb-0">
                                <h3>
                                    Create a Student Project based on{" "}
                                    <span className="fw-bold text-muted">
                                        {props.selectedRoom?.title}
                                    </span>{" "}
                                </h3>
                                <p>
                                    You can distribute copies of this Lesson to
                                    your students by using Student Projects.
                                </p>
                                <p>
                                    Students will get their own individual
                                    Student Rooms with full build permissions.
                                </p>
                                <p>
                                    You can monitor and visit all the Student
                                    Rooms from the "Student Projects" page.
                                </p>
                                <p className="fw-light">
                                    <small>
                                        Your original Lesson (
                                        {props.selectedRoom?.title}) will remain
                                        unchanged.
                                    </small>
                                </p>
                                <Button
                                    href="https://megaminds.tawk.help/article/guide-to-student-projects"
                                    size="sm"
                                    variant="purple-dark-outline"
                                    target="_blank"
                                >
                                    Learn more
                                </Button>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} md={6} className="bg-light">
                        <Row className="">
                            <Col>
                                <Card className="dash-card w-100 mx-auto p-0">
                                    <Tooltip id="info-form" />
                                    <Card.Body className="">
                                        <Form>
                                            <Form.Group
                                                className="mb-1 mt-3"
                                                controlId="formGroupEmail"
                                            >
                                                <Form.Label>
                                                    Project Title *
                                                </Form.Label>
                                                <Row className="mb-1">
                                                    <Col xs={12}>
                                                        <Form.Control
                                                            value={title}
                                                            onChange={(e) =>
                                                                setTitle(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            type="text"
                                                            isInvalid={
                                                                titleError
                                                            }
                                                            placeholder=""
                                                        />
                                                        <Form.Control.Feedback type="invalid">
                                                            Please provide a
                                                            title for your
                                                            Student Project.
                                                        </Form.Control.Feedback>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-3">
                                                <Form.Label>
                                                    Instructions
                                                </Form.Label>
                                                <Form.Text className="text-muted">
                                                    <p>
                                                        <small>
                                                            Here you can give
                                                            your students some
                                                            specific
                                                            instructions on what
                                                            to do or build in
                                                            their Student Rooms.
                                                        </small>
                                                    </p>
                                                </Form.Text>
                                                <Row className="mb-1">
                                                    <Col xs={12}>
                                                        <Form.Control
                                                            as="textarea"
                                                            value={description}
                                                            onChange={(e) =>
                                                                setDescription(
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            placeholder=""
                                                            type="text"
                                                        />
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            <Form.Group className="mb-3">
                                                <Form.Label>
                                                    Individual or Group
                                                </Form.Label>
                                                <Form.Text className="text-muted">
                                                    <p>
                                                        <small>
                                                            Should the students
                                                            build by themselves
                                                            or in groups?
                                                        </small>
                                                    </p>
                                                </Form.Text>
                                                <Row>
                                                    <Col xs={6}>
                                                        <Card
                                                            onClick={() =>
                                                                setGroup(false)
                                                            }
                                                            className={
                                                                !group
                                                                    ? "p-4 text-center form-card-light border-success"
                                                                    : "p-4 text-center form-card-white"
                                                            }
                                                        >
                                                            <Card.Body>
                                                                <i
                                                                    style={{
                                                                        fontSize:
                                                                            "1.4rem",
                                                                    }}
                                                                    className="bi bi-person-fill text-purple-dark"
                                                                ></i>
                                                                <p className="mb-0 prevent-select">
                                                                    Individual
                                                                </p>
                                                                {!group ? (
                                                                    <span
                                                                        style={{
                                                                            lineHeight:
                                                                                "100%",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                fontSize:
                                                                                    "1.4rem",
                                                                            }}
                                                                            className="position-absolute top-0 end-0 bi bi-check-circle-fill text-success m-2"
                                                                        ></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        style={{
                                                                            lineHeight:
                                                                                "100%",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                fontSize:
                                                                                    "1.4rem",
                                                                            }}
                                                                            className="position-absolute top-0 end-0 bi bi-circle-fill text-muted m-2"
                                                                        ></i>
                                                                    </span>
                                                                )}
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Card
                                                            onClick={() =>
                                                                setGroup(true)
                                                            }
                                                            className={
                                                                group
                                                                    ? "p-4 text-center form-card-light border-success"
                                                                    : "p-4 text-center form-card-white"
                                                            }
                                                        >
                                                            <Card.Body>
                                                                <i
                                                                    style={{
                                                                        fontSize:
                                                                            "1.4rem",
                                                                    }}
                                                                    className="bi bi-people-fill text-purple-dark"
                                                                ></i>
                                                                <p className="mb-0 prevent-select">
                                                                    Group
                                                                </p>
                                                                {group ? (
                                                                    <span
                                                                        style={{
                                                                            lineHeight:
                                                                                "100%",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                fontSize:
                                                                                    "1.4rem",
                                                                            }}
                                                                            className="position-absolute top-0 end-0 bi bi-check-circle-fill text-success m-2"
                                                                        ></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        style={{
                                                                            lineHeight:
                                                                                "100%",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                fontSize:
                                                                                    "1.4rem",
                                                                            }}
                                                                            className="position-absolute top-0 end-0 bi bi-circle-fill text-muted m-2"
                                                                        ></i>
                                                                    </span>
                                                                )}
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            {/*
                                    <Form.Group className="mb-3 mt-4">
                                        <Form.Label>
                                            <strong>Start and Deadline</strong>
                                        </Form.Label>
                                        <Form.Text className="text-muted">
                                            <p>
                                                Optionally add a deadline and
                                                start date to the assignment.
                                            </p>
                                        </Form.Text>
                                        <Row className="mb-1">
                                            <Col xs={12}>
                                                <Form.Control
                                                    value={description}
                                                    onChange={(e) =>
                                                        setDescription(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder=""
                                                    type="datetime-local"
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    */}
                                        </Form>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button
                    disabled={isPosting}
                    variant="success"
                    onClick={() => createAssignment()}
                >
                    Create Student Project
                </Button>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
