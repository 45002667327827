import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Badge from "react-bootstrap/Badge";
import Stack from "react-bootstrap/Stack";
import Row from "react-bootstrap/Row";

import Categories from "./sidebar/Categories";
import Search from "./sidebar/Search";
import Grades from "./sidebar/Grades";
import Format from "./sidebar/Format";
import ResourceTypes from "./sidebar/ResourceTypes";
import ClearBadges from "./sidebar/ClearBadges";

import useFacetFilterAPI from "../../services/useFacetFilterAPI";

const FilterSidebar = ({
    resetFilters,
    showFeatured,
    tempSearchValue,
    setTempSearchValue,
    setSearchValue,
    selectedCategories,
    setSelectedCategories,
    selectedGrades,
    setSelectedGrades,
    selectedResourceTypes,
    setSelectedResourceTypes,
    selectedFormats,
    setSelectedFormats,
}) => {
    const [facet, facetLoading, facetError] =
        useFacetFilterAPI("templates/facet/");

    if (facetLoading) {
        return <p>Loading...</p>;
    }

    if (facetError) {
        return <p>Could not load Filter Sidebar</p>;
    }

    return (
        <div className="d-none d-sm-block">
            <Search
                tempSearchValue={tempSearchValue}
                setTempSearchValue={setTempSearchValue}
                setSearchValue={setSearchValue}
            />
            {!showFeatured && (
                <Button
                    variant="outline-secondary"
                    size="sm"
                    className="mt-2 w-100 text-start"
                    onClick={() => resetFilters()}
                >
                    <i className="bi bi-x-lg me-2"></i>
                    Reset all filters
                </Button>
            )}
            <ClearBadges
                selectedCategories={selectedCategories}
                setSelectedCategories={setSelectedCategories}
                selectedGrades={selectedGrades}
                setSelectedGrades={setSelectedGrades}
                selectedResourceTypes={selectedResourceTypes}
                setSelectedResourceTypes={setSelectedResourceTypes}
                setSelectedFormats={setSelectedFormats}
                selectedFormats={selectedFormats}
            />

            <Format
                setSelectedFormats={setSelectedFormats}
                selectedFormats={selectedFormats}
            />
            {facet.categories.length > 0 && (
                <Categories
                    categories={facet.categories}
                    selectedCategories={selectedCategories}
                    setSelectedCategories={setSelectedCategories}
                />
            )}
            {facet.grades.length > 0 && (
                <Grades
                    grades={facet.grades}
                    selectedGrades={selectedGrades}
                    setSelectedGrades={setSelectedGrades}
                />
            )}
            {facet.resource_types.length > 0 && (
                <ResourceTypes
                    resourceTypes={facet.resource_types}
                    selectedResourceTypes={selectedResourceTypes}
                    setSelectedResourceTypes={setSelectedResourceTypes}
                />
            )}
        </div>
    );
};

export default FilterSidebar;
