import React from "react";
import { useParams } from "react-router-dom";

import Students from "./assessment/Students";
import Questions from "./assessment/Questions";
import ListGroup from "react-bootstrap/ListGroup";

import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

import useDashApi from "../../services/useDashApi";
import { useAuth } from "../../context/AuthContext";

const EmptyRoomsPage = (props) => {
    if (props.isLoadingRooms) {
        return null;
    }

    return (
        <div className="h-100 w-100 bg-light p-5">
            <div className="d-flex flex-column h-100 justify-content-center align-items-center">
                <div style={{ maxWidth: "380px" }} className="text-center">
                    <i
                        className="bi bi-easel text-purple-dark"
                        style={{ fontSize: "3rem" }}
                    ></i>
                    <p className="fw-bold">
                        Lessons offer a versatile and immersive space to enrich
                        your lessons.
                    </p>
                    <ListGroup className="text-start">
                        <ListGroup.Item>
                            <strong>How it works</strong>
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>1.</strong> Create a new Lesson
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>2.</strong> Present your lesson by adding
                            content you already have or by using our libraries.
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>3.</strong> Invite your students to the
                            Lesson. Lessons support both live and self-paced
                            learning.
                        </ListGroup.Item>
                        <ListGroup.Item>
                            <strong>4.</strong> Use Assessment tools to conduct
                            checks for understanding.
                        </ListGroup.Item>
                    </ListGroup>
                    <Button
                        href="https://megaminds.tawk.help/article/teaching-with-megaminds"
                        target="_blank"
                        variant="link"
                        className="mb-0 mt-2 fw-light"
                    >
                        <small>Learn More</small>
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default EmptyRoomsPage;
