import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import { useAuth } from "../../context/AuthContext";
import Bulk from "./routes/Bulk";
import Individual from "./routes/Individual";

const Buy = (bulkDirect) => {
    const [bulk, setBulk] = useState(bulkDirect ? false : true);

    const auth = useAuth();

    return (
        <>
            <Container className="p-5">
                <Row className="g-3">
                    <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Card
                            onClick={() => setBulk(false)}
                            className={
                                !bulk
                                    ? "border border-2 border-success h-100"
                                    : "h-100"
                            }
                        >
                            <Row className="h-100">
                                <Col xs={2} className="m-3">
                                    <i
                                        className="bi bi-person-fill"
                                        style={{ fontSize: "2rem" }}
                                    ></i>
                                </Col>
                                <Col className="d-flex align-items-center">
                                    <h5 className="mb-0">
                                        Individual Subscription
                                    </h5>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col xs={12} sm={6} md={6} lg={4} xl={3}>
                        <Card
                            onClick={() => setBulk(true)}
                            className={
                                bulk
                                    ? "border border-2 border-success h-100"
                                    : "h-100"
                            }
                        >
                            <Row className="h-100">
                                <Col xs={2} className="m-3">
                                    <i
                                        className="bi bi-people-fill"
                                        style={{ fontSize: "2rem" }}
                                    ></i>
                                </Col>
                                <Col className="d-flex align-items-center">
                                    <h5 className="mb-0">Multiple Licences</h5>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
                {bulk ? (
                    <Bulk setBulk={setBulk} auth={auth} />
                ) : (
                    <Individual
                        auth={auth}
                        current_plan={auth?.profile?.has_active_subscription}
                        setBulk={setBulk}
                    />
                )}
            </Container>
        </>
    );
};

export default Buy;
