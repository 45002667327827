import React from "react";
import Modal from "react-bootstrap/Modal";
function AssignmentGuideModal(props) {
    const handleClose = () => props.setShow(false);

    const handleLoad = (e) => {
        // Force focus on iframe load so that key input works
        e.target.focus();
    };

    return (
        <>
            <Modal
                fullscreen={true}
                animation={false}
                autoFocus={false}
                show={props.show}
                onHide={handleClose}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Guide - Assignments</Modal.Title>
                </Modal.Header>
                <Modal.Body className="m-0 p-0 w-100 h-100">
                    <iframe
                        title="Assignment guide"
                        className="w-100 h-100"
                        autoFocus
                        onLoad={(e) => handleLoad(e)}
                        src="https://megaminds.world/hw3pw4S/?skip_entry"
                    />
                </Modal.Body>
            </Modal>
        </>
    );
}

export default AssignmentGuideModal;
