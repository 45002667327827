import React from "react";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { useAuth0 } from "@auth0/auth0-react";

function LoginRequiredModal(props) {
    const { loginWithRedirect } = useAuth0();

    return (
        <Modal show={props.show} onHide={() => props.onHide()}>
            <Modal.Header closeButton>
                <Modal.Title>You need to log in first</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>
                    To start using this room, sign in or create a{" "}
                    <strong>free account</strong>.
                </p>
                <Row className="p-4">
                    <Col className="mb-2">
                        <Button
                            onClick={() =>
                                loginWithRedirect({
                                    authorizationParams: {
                                        screen_hint: "signup",
                                    },
                                    appState: {
                                        redirectUri: window.location.pathname,
                                        returnTo: window.location.pathname,
                                    },
                                })
                            }
                            variant="success"
                            className="w-100"
                        >
                            Sign up for free
                        </Button>
                    </Col>
                    <Col
                        xs={12}
                        className="d-flex justify-content-middle align-items-center"
                    >
                        <Button
                            onClick={() =>
                                loginWithRedirect({
                                    appState: {
                                        returnTo: window.location.pathname,
                                    },
                                })
                            }
                            variant="link"
                            className="w-100"
                        >
                            I already have an account
                        </Button>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => props.onHide()}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default LoginRequiredModal;
