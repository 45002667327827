import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import { useTrackingCode } from "react-hubspot-tracking-code-hook";

const usePageTracking = (profile) => {
    const location = useLocation();
    const { setPathPageView, setIdentity } = useTrackingCode();

    useEffect(() => {
        if (process.env.REACT_APP_MM_ENV === "production") {
            ReactGA.send({
                hitType: "pageview",
                page: location.pathname + location.search,
                title: location.pathname + location.search,
            });

            if (profile && profile.email) {
                setIdentity(profile.email);
                setPathPageView(location.pathname + location.search);
            }
        }
    }, [location, profile]);
};

export default usePageTracking;
