import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Dropdown from "react-bootstrap/Dropdown";
import InputGroup from "react-bootstrap/InputGroup";
import DropdownButton from "react-bootstrap/DropdownButton";

const sortButtons = ({ sortValue, setSortValue }) => {
    return (
        <Row className="mb-4">
            <Col className="d-flex justify-content-start">
                <InputGroup>
                    <DropdownButton
                        variant="light border"
                        as={ButtonGroup}
                        title={
                            <>
                                <i className="bi bi-sort-down-alt me-2"></i>
                                <span className="">Sort</span>
                            </>
                        }
                        id="bg-vertical-dropdown-1"
                    >
                        <Dropdown.Item
                            onClick={() =>
                                sortValue === "-room_count"
                                    ? setSortValue("")
                                    : setSortValue("-room_count")
                            }
                            eventKey="1"
                        >
                            {sortValue === "-room_count" ? (
                                <span>
                                    Most Popular
                                    <i className="bi bi-check float-end text-success"></i>
                                </span>
                            ) : (
                                <span>Most Popular</span>
                            )}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() =>
                                sortValue === "room_count"
                                    ? setSortValue("")
                                    : setSortValue("room_count")
                            }
                            eventKey="1"
                        >
                            {sortValue === "room_count" ? (
                                <span>
                                    Least Popular
                                    <i className="bi bi-check float-end text-success"></i>
                                </span>
                            ) : (
                                <span>Least Popular</span>
                            )}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() =>
                                sortValue === "title"
                                    ? setSortValue("")
                                    : setSortValue("title")
                            }
                            eventKey="2"
                        >
                            {sortValue === "title" ? (
                                <span>
                                    A-Z
                                    <i className="bi bi-check float-end text-success"></i>
                                </span>
                            ) : (
                                <span>A-Z</span>
                            )}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() =>
                                sortValue === "-title"
                                    ? setSortValue("")
                                    : setSortValue("-title")
                            }
                            eventKey="1"
                        >
                            {sortValue === "-title" ? (
                                <span>
                                    Z-A
                                    <i className="bi bi-check float-end text-success"></i>
                                </span>
                            ) : (
                                <span>Z-A</span>
                            )}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() =>
                                sortValue === "timestamp_created"
                                    ? setSortValue("")
                                    : setSortValue("timestamp_created")
                            }
                            eventKey="1"
                        >
                            {sortValue === "timestamp_created" ? (
                                <span>
                                    Newest
                                    <i className="bi bi-check float-end text-success"></i>
                                </span>
                            ) : (
                                <span>Newest</span>
                            )}
                        </Dropdown.Item>
                        <Dropdown.Item
                            onClick={() =>
                                sortValue === "-timestamp_created"
                                    ? setSortValue("")
                                    : setSortValue("-timestamp_created")
                            }
                            eventKey="1"
                        >
                            {sortValue === "-timestamp_created" ? (
                                <span>
                                    Oldest
                                    <i className="bi bi-check float-end text-success"></i>
                                </span>
                            ) : (
                                <span>Oldest</span>
                            )}
                        </Dropdown.Item>
                    </DropdownButton>
                </InputGroup>
            </Col>
        </Row>
    );
};

export default sortButtons;
