import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";

import { Link } from "react-router-dom";
import ListGroup from "react-bootstrap/ListGroup";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import { useAuth } from "../../context/AuthContext";
import Dropdown from "react-bootstrap/Dropdown";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";
import { defaultStaticRanges } from "./modals/defaultRanges";
import moment from "moment";
import "moment-timezone";

import UsersOverTimeChart from "./analytics/UsersOverTimeChart";
import ActivityChart from "./analytics/ActivityChart";
import UserCard from "./analytics/UserCard";
import PeakActivityScatterChart from "./analytics/PeakUsageTimes";
import getString from "./analytics/PeakUsageTimes";

function countChatMessages(sessions) {
    let chatMessageCount = 0;

    sessions?.forEach((session) => {
        session.logevent_set.forEach((event) => {
            if (event.action === "com_chat_message") {
                chatMessageCount++;
            }
        });
    });

    return chatMessageCount;
}


const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

import {
    Chart as ChartJS,
    RadialLinearScale,
    ArcElement,
    Tooltip,
    Legend,
} from "chart.js";
import { PolarArea, Bar } from "react-chartjs-2";

ChartJS.register(RadialLinearScale, ArcElement, Tooltip, Legend);

import useSingleRoomAPI from "../../services/useSingleRoomAPI";

const groupSessionsByUser = (sessions) => {
    return sessions.reduce((acc, session) => {
        const { name } = session;
        if (!acc[name]) {
            acc[name] = [];
        }
        acc[name].push(session);
        return acc;
    }, {});
};

const DateSelector = ({ data, onSelectDate }) => {
    const uniqueDates = getUniqueDates(data);

    return (
        <Dropdown>
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                Select Date
            </Dropdown.Toggle>

            <Dropdown.Menu>
                {uniqueDates.map((date) => (
                    <Dropdown.Item
                        key={date}
                        onClick={() => onSelectDate(date)}
                    >
                        {date}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

const getUniqueDates = (data) => {
    const dates = new Set();
    data.forEach((session) => {
        dates.add(session.date_start.split("T")[0]); // Extracting just the date part
    });
    return Array.from(dates);
};

const getUniqueNames = (data) => {
    const names = new Set();
    data.forEach((session) => {
        names.add(session.name); // Extracting just the date part
    });
    return names.size;
};
const MainComponent = ({ originalData }) => {
    const { id } = useParams();
    const [selectedDate, setSelectedDate] = useState(null);
    const [filterShortSessions, setFilterShortSessions] = useState(false);
    const [filteredData, setFilteredData] = useState(originalData);
    const [room, isLoading, isError] = useSingleRoomAPI(id);

    console.log("Data", originalData, filteredData);

    useEffect(() => {
        let newData = [...originalData];

        if (selectedDate) {
            newData = newData.filter((session) =>
                session.date_start.includes(selectedDate)
            );
        }

        if (filterShortSessions) {
            newData = newData.filter(
                (session) => session.time_spent_minutes >= 1
            );
        }

        setFilteredData(newData);
    }, [selectedDate, filterShortSessions, originalData]);

    const handleDateSelect = (date) => {
        setSelectedDate(date);
    };

    const handleCheckboxChange = () => {
        setFilterShortSessions(!filterShortSessions);
    };

    const groupedSessions = groupSessionsByUser(filteredData);
    const uniqueUsers = getUniqueNames(filteredData);

    return (
        <>
            <Container fluid className="g-0 bg-light">
                <Row className="bg-white p-3 p-lg-4 border-bottom m-0">
                    <Col className="d-flex justify-content-between align-items-center">
                        {!isLoading && room.assignment_parent ? (
                            <Link to="/student-projects">
                            <i className="bi bi-arrow-left me-2"></i>Back
                        </Link>
                        ) : (
                            <Link to="/lessons">
                            <i className="bi bi-arrow-left me-2"></i>Back
                        </Link>
                        )}             
                        <Form.Check // prettier-ignore
                            type="switch"
                            id="custom-switch"
                            label="Filter Sessions Less Than 1 Minute"
                            checked={filterShortSessions}
                            onChange={handleCheckboxChange}
                        />
                    </Col>
                </Row>
            </Container>
            <Container className="g-0 bg-light p-3">
                <Row>
                    <Col>
                        <Card>
                            <Card.Body className="">
                                <h4 className="fw-bold">
                                    {getUniqueNames(filteredData)}
        </h4>
                                <p className="mb-0">visitors</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body className="">
                                <h4 className="fw-bold">
                                    {countChatMessages(filteredData)}
                                </h4>
                                <p className="mb-0">messages</p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col xs={4}>
                        <h4>Logs</h4>
                    </Col>

                    <Accordion>
                        {Object.entries(groupedSessions).map(
                            ([userName, sessions], index) => (
                                <UserCard
                                    name={userName}
                                    sessions={sessions}
                                    index={index}
                                />
                            )
                        )}
                    </Accordion>
                </Row>
                <Row className="mt-4">
                    <Col xs={4}>
                        <h4>Insights</h4>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col xs={12}>
                        <Card>
                            <Card.Header>
                                <Card.Title className="mb-0">
                                    Usage across months
                                </Card.Title>
                            </Card.Header>
                            <Card.Body>
                                <ActivityChart data={filteredData} />
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col xs={12}>
                        <Card className="">
                            <Card.Header>
                                <Row>
                                    <Col className="d-flex justify-content-between align-items-center">
                                        <Card.Title className="mb-0">
                                            Peak Useage Times (Daily)
                                        </Card.Title>
                                        <DateSelector
                                            data={filteredData}
                                            onSelectDate={handleDateSelect}
                                        />
                                    </Col>
                                </Row>
                            </Card.Header>
                            <Card.Body>
                                <Row>
                                    <Col>
                                        <p>
                                            Selected Date:{" "}
                                            <span className="fw-bold">
                                                {selectedDate || "None"}
                                            </span>
                                        </p>
                                        <PeakActivityScatterChart
                                            data={filteredData}
                                        />
                                    </Col>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
};

const Analytics = (props) => {
    const { id } = useParams();
    const auth = useAuth();

    if (!auth.profile) {
        return <p>You must be signed in to view this page</p>;
    }

    const [room, isLoading, isError] = useSingleRoomAPI(id);
    const [selectedSession, setSelectedSession] = useState(false);
    const [showSessionDetails, setShowSessionDetails] = useState(false);
    const [userList, setUserList] = useState([]);

    const [chartOptions, setChartOption] = useState({});
    const [selectedDate, setSelectedDate] = useState(null);

    const handleDateSelect = (date) => {
        setSelectedDate(date);
    };


    console.log("Room Data: ", room);

    if (isLoading) {
        return <p>Loading</p>;
    }

    if (isError) {
        return <p>Error loading Lesson data</p>;
    }

    if (!room || !room.session_set) {
        return <p>No data for this room</p>;
    }
    if (isError) {
        return <p>Error loading room data</p>;
    }

    return (
        <>
            <Container fluid className="g-0 bg-light">
                <Row className="bg-white p-3 p-lg-4 border-bottom m-0">
                    <Col md={12} lg={7} className="d-flex align-items-center">
                        <h3 className="mb-0">
                            Analytics and Events for {room.title}
                        </h3>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-lg-end mt-4 mb-2 mt-lg-0 mb-lg-0"></Col>
                </Row>
            </Container>
            <MainComponent originalData={room.session_set} />
        </>
    );
};

export default Analytics;
