import React, { useState } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { CheckoutSingleModal } from "../modals/CheckoutSingleModal";

const Individual = ({ setBulk, current_plan, auth }) => {
    const [showCheckout, setShowCheckout] = useState(false);

    const { loginWithRedirect } = useAuth0();

    return (
        <>
            <CheckoutSingleModal
                setShow={setShowCheckout}
                show={showCheckout}
            />
            <Alert variant="secondary" className="mt-5">
                <p className="mb-0">
                    <i className="bi bi-info-circle me-2"></i>
                    Get shared Lessons, collaboration and management tools and
                    more with{" "}
                    <a href="https://gomegaminds.com/for-schools">
                        School Licences.
                    </a>
                </p>
            </Alert>
            <Card.Body className="mt-5">
                <Row className="mb-3 g-4">
                    <Col
                        xs={12}
                        md={6}
                        controlId="formGridState"
                        className="d-none d-sm-block"
                    >
                        <Card className="h-100">
                            <Card.Body>
                                <Row className="text-center">
                                    <h3 className="mb-0 fw-bold text-purple-dark">
                                        Free
                                    </h3>
                                </Row>
                                <hr />
                                <Row className="text-center mb-4">
                                    {current_plan === "Free Plan" && (
                                        <small className="mb-4 mt-3 fw-bold text-purple-dark text-center">
                                            This is your current plan
                                        </small>
                                    )}
                                </Row>
                                <p className="fw-bold mb-0">Features</p>
                                <ul className="fw-light">
                                    <li className="mb-3 mt-3">
                                        <p className="mb-2">3 Lessons</p>
                                    </li>
                                    <li className="mb-3 mt-3">
                                        <p className="mb-2">
                                            3 Student Projects
                                        </p>
                                    </li>
                                    <li className="mb-3 mt-3">
                                        <p className="mb-2">
                                            25 users in one Lesson
                                        </p>
                                    </li>
                                    <li className="mb-3 mt-3">
                                        <p className="mb-2">
                                            Voice chat, screen / webcam share,
                                            in-lesson messages, sticky notes
                                        </p>
                                    </li>
                                    <li className="mb-3 mt-3">
                                        <p className="mb-2">
                                            Access to Edit Mode for creation of
                                            interactive presentations, user
                                            creation tools, gamified activities,
                                            and digital galleries
                                        </p>
                                    </li>
                                    <li className="mb-3 mt-3">
                                        <p className="mb-2">
                                            Premade template-based lessons
                                        </p>
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={12} md={6}>
                        <Card className="h-100">
                            <Card.Body>
                                <Row className="text-center">
                                    <Col>
                                        <h3 className="mb-0 fw-bold text-purple-dark">
                                            Premium
                                        </h3>
                                    </Col>
                                </Row>
                                <hr />
                                {current_plan === "Premium Plan" ? (
                                    <Row>
                                        <small className="mb-4 mt-3 fw-bold text-purple-dark text-center">
                                            This is your current plan
                                        </small>
                                        <Col>
                                            <Link
                                                to="/profile"
                                                className="w-100"
                                                variant="secondary"
                                            >
                                                Manage
                                            </Link>
                                        </Col>
                                    </Row>
                                ) : (
                                    <Row className="text-center mb-4">
                                        <p className="mt-3 mb-4 fw-bold text-purple-dark text-center">
                                            <span className="">
                                                $14.99 / Month
                                            </span>
                                        </p>
                                        <Col>
                                            {auth.profile ? (
                                                <Button
                                                    className="w-100"
                                                    onClick={() =>
                                                        setShowCheckout(true)
                                                    }
                                                    variant="success"
                                                >
                                                    Upgrade now
                                                </Button>
                                            ) : (
                                                <Row>
                                                    <Col
                                                        xs={12}
                                                        className="d-flex justify-content-middle align-items-center"
                                                    >
                                                        <Button
                                                            onClick={() =>
                                                                loginWithRedirect(
                                                                    {
                                                                        authorizationParams:
                                                                            {
                                                                                redirect_uri:
                                                                                    process
                                                                                        .env
                                                                                        .REACT_APP_BUY_REDIRECT,
                                                                            },
                                                                    }
                                                                )
                                                            }
                                                            variant="purple-dark"
                                                            className="w-100"
                                                        >
                                                            Sign in to upgrade
                                                        </Button>
                                                    </Col>
                                                </Row>
                                            )}
                                        </Col>
                                    </Row>
                                )}
                                <p className="fw-bold mt-4">Features</p>
                                <ul className="fw-light">
                                    <li className="mb-3 mt-3">
                                        <p className="mb-2">Unlimited Lessons</p>
                                    </li>
                                    <li className="mb-3 mt-3">
                                        <p className="mb-2">
                                            Unlimited Assignments
                                        </p>
                                    </li>
                                    <li className="mb-3 mt-3">
                                        <p className="mb-2">
                                            25 users in one session
                                        </p>
                                    </li>
                                    <li className="mb-3 mt-3">
                                        <p className="mb-2">
                                            Voice chat, screen / webcam share,
                                            in-lesson messages, sticky notes
                                        </p>
                                    </li>
                                    <li className="mb-3 mt-3">
                                        <p className="mb-2">
                                            Access to Edit Mode for creation of
                                            interactive presentations, user
                                            creation tools, gamified activities,
                                            and digital galleries
                                        </p>
                                    </li>
                                    <li className="mb-3 mt-3">
                                        <p className="mb-2">
                                            Premade template-based lessons
                                        </p>
                                    </li>
                                    <li className="mb-3 mt-3">
                                        <p className="mb-2">
                                            Third party EdTech Integrations
                                        </p>
                                    </li>
                                </ul>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Card.Body>
        </>
    );
};

export default Individual;
