import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Dropdown from "react-bootstrap/Dropdown";
import toast from "react-hot-toast";

import { useLocation } from "react-router-dom";

import { useAuth0 } from "@auth0/auth0-react";
import { useAuth } from "../../../context/AuthContext";

function UnarchiveModal(props) {
    const [show, setShow] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const [isPosting, setIsPosting] = useState(false);
    const handleClose = () => setShow(false);
    const openModal = () => {
        setShow(true);
    };

    const handleSubmit = () => {
        setIsPosting(true);
        let payload = {
            is_archived: false,
        };

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    props.endpoint +
                    "/" +
                    props.object.hub_id +
                    "/",
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                    setShow(false);
                    props.refresh();
                    setIsPosting(false);
                    toast.success("Changes were saved.");
                })
                .catch((error) => {
                    toast.error("Something went wrong while saving.");
                    console.error(error);
                    setIsPosting(false);
                });
        });
    };

    return (
        <>
            <Button
                variant="white"
                className="rounded-0"
                as={Dropdown.Item}
                onClick={() => openModal()}
            >
                {props.buttonText}
            </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Archive Your Lesson</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to unarchive <span className="fw-bold">{props.title}</span>?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Discard
                    </Button>
                    <Button
                        variant="success"
                        disabled={isPosting ? true : false}
                        onClick={handleSubmit}
                    >
                        {isPosting ? (
                            <>
                                <span
                                    className="spinner-border spinner-border-sm"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                <span>Loading...</span>
                            </>
                        ) : (
                            <span>Save changes</span>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default UnarchiveModal;
