import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVrCardboard, faMobile, faLaptop } from "@fortawesome/free-solid-svg-icons";

export default function getDeviceIcon(context) {

    if(context.hmd) {
        return <FontAwesomeIcon icon={faVrCardboard} />
    }
    if(context.mobile) {
        return <FontAwesomeIcon icon={faMobile} />
    }

    return <FontAwesomeIcon icon={faLaptop} />
}


