import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const usePendingTemplates = (page = 1) => {
    const { getAccessTokenSilently } = useAuth0();
    const [templates, setTemplates] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(false);
    const [refreshIndex, setRefreshIndex] = useState(0);

    const refresh = () => {
        setRefreshIndex(refreshIndex + 1);
    };

    useEffect(() => {
        setLoading(true);
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "read:classrooms",
        }).then((token) => {
            // Logged in successfully, now we do the request
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "templates/review?page=" +
                    page,

                {
                    headers: {
                        Authorization: "Bearer " + token,
                    },
                }
            )
                .then((resp) => {
                    if (resp.status === 403) {
                        setError("You don't have permission.");
                    } else {
                        return resp.json();
                    }
                })
                .then((data) => {
                    console.log(data);
                    setTemplates(data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.error(
                        "Caught error while loading templates, most likely permissions",
                        error
                    );
                    setLoading(false);
                    setError(error);
                });
        });
    }, [getAccessTokenSilently, refreshIndex, page]);

    return [templates, loading, error, refresh];
};

export default usePendingTemplates;
