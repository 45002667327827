import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import RejectModal from "./RejectModal";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

import ListingDetailContent from "../ListingDetailContent";

function ReviewModal(props) {
    const [showModal, setShowModal] = useState(false);

    const [showPreview, setShowPreview] = useState(false);

    const onHide = () => {
        setShowModal(false);
    };

    const handleLoad = (e) => {
        // Force focus on iframe load so that key input works
        e.target.focus();
    };

    return (
        <Modal
            show={props.show}
            fullscreen={true}
            onHide={() => props.onHide()}
        >
            <Modal.Header closeButton>
                <Modal.Title>Review Room</Modal.Title>
            </Modal.Header>
            {showPreview ? (
                <Modal.Body className="m-0 p-0">
                    <iframe
                        style={{ position: "absolute" }}
                        title="preview"
                        className="w-100 h-100"
                        src={
                            process.env.REACT_APP_3D_ENDPOINT +
                            "/" +
                            props.template.source_classroom_clone.hub_id
                        }
                        autoFocus
                        onLoad={(e) => handleLoad(e)}
                    />
                </Modal.Body>
            ) : (
                <Modal.Body className="bg-light p-0">
                    <ListingDetailContent
                        template={props.template}
                        reviewing={true}
                    />
                </Modal.Body>
            )}
            <Modal.Footer>
                <RejectModal
                    show={showModal}
                    onHide={onHide}
                    handleReject={props.handleReject}
                    template={props.template}
                />
                <Button
                    variant="purple-dark"
                    className="me-2"
                    onClick={() => setShowPreview(!showPreview)}
                >
                    <FontAwesomeIcon icon={faEye} fixedWidth className="me-2" />
                    Toggle Preview
                </Button>
                <Button
                    variant="success"
                    onClick={props.handleApprove}
                    className="me-2"
                >
                    Approve Room
                </Button>
                <Button
                    variant="danger"
                    onClick={() => setShowModal(true)}
                    className="me-3"
                >
                    Reject Room
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default ReviewModal;
