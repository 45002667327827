import React, { useState, useEffect } from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import { contains } from "./utils";

const formats = [
    { title: "Lesson", value: "room" },
    { title: "Student Project", value: "student_project" },
];

const Formats = ({ selectedFormats, setSelectedFormats }) => {
    const handleFormatCheck = (format) => {
        const isChecked = contains(selectedFormats, format);

        if (isChecked) {
            // Remove from list
            const cleanedFormats = selectedFormats.filter(
                (oldFormat) => oldFormat.value !== format.value
            );
            setSelectedFormats(cleanedFormats);
        } else {
            // Add to list
            setSelectedFormats([...selectedFormats, format]);
        }
    };

    return (
        <Row className="mt-4">
            <Col xs={12}>
                <p className="fw-bold">Type</p>
            </Col>
            <Col xs={12} className="d-flex justify-content-start">
                <Row>
                    <Col xs={12}>
                        {formats.map((format) => (
                            <Form.Check
                                onClick={() => {
                                    handleFormatCheck(format);
                                }}
                                id={"format-" + format.title}
                                checked={contains(selectedFormats, format)}
                                label={format.title}
                            />
                        ))}
                    </Col>
                </Row>
            </Col>
            <Col />
        </Row>
    );
};

export default Formats;
