import React, { useState, useEffect } from "react";
import { Socket, Presence } from "phoenix";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Spinner from "react-bootstrap/Spinner";
import Alert from "react-bootstrap/Alert";
import Table from "react-bootstrap/Table";
import { useAuth0 } from "@auth0/auth0-react";
import InputGroup from "react-bootstrap/InputGroup";
import toast from "react-hot-toast";
import ListGroup from "react-bootstrap/ListGroup";

function AssignmentListItem({
    room,
    socket,
    show,
    setShow,
    refresh,
    refreshSelectedAssignment,
    deleteAssignedRoom,
}) {
    const [socketsAttempted, setSocketsAttempted] = useState(false);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const [deleteWarning, setDeleteWarning] = useState(false);
    const [newGrade, setNewGrade] = useState(room.assignment_grade);
    const [showGrading, setShowGrading] = useState(false);
    const { getAccessTokenSilently } = useAuth0();
    console.log(room);

    useEffect(() => {
        if (socket) {
            let ch = socket
                .channel("hub:" + room.hub_id, {
                    profile: {
                        displayName: "teacher_bot_2df",
                        avatarId: "",
                    },
                    context: {
                        mobile: false,
                        hmd: false,
                        discord: true,
                    },
                })
                .join()
                .receive("ok", (e) => {})
                .receive("error", (e) => {
                    console.error("Could not connect to socket.channel", e);
                });

            let presences = new Presence(ch.channel);

            presences.onSync(() => {
                let listBy = (id, { metas: [first, ...rest] }) => {
                    first.count = rest.length + 1; // count of this user's presences
                    first.id = id;
                    return first;
                };

                let filteredList = presences
                    .list(listBy)
                    .filter((user) => user.context.discord !== true);

                setOnlineUsers(filteredList);
            });

            setSocketsAttempted(true);
        }
    }, [room.hub_id, socket]);

    if (!socketsAttempted) {
        return (
            <tr>
                <td>Loading</td>
            </tr>
        );
    }

    const saveGrade = (room) => {
        // Fetch patch to object by id
        const payload = {
            assignment_grade: parseInt(newGrade),
        };
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "classrooms/" +
                    room.hub_id +
                    "/",
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                    refreshSelectedAssignment();
                    setShowGrading(false);
                    setNewGrade(data.assignment_grade);
                    toast.success("Changes were saved.");
                })
                .catch((error) => {
                    toast.error("Something went wrong while saving.");
                    console.error(error);
                    refreshSelectedAssignment();
                });
        });
    };

    return (
        <tr className="p-3">
            <td>{room.title}</td>
            <td>{onlineUsers.length}</td>
            <td className="">
                <div style={{ position: "relative", display: "inline-block" }}>
                    <Button
                        target="_blank"
                        rel="noopener noreferrer"
                        href={
                            process.env.REACT_APP_3D_ENDPOINT +
                            "/" +
                            room.hub_id +
                            "/"
                        }
                        size="sm"
                        variant="success"
                    >
                        Visit / Review
                    </Button>
                </div>
            </td>
            <td>{room.hub_id}</td>
            <td className="">
                {showGrading ? (
                    <>
                        <InputGroup className="w-80">
                            <input
                                type="number"
                                value={newGrade}
                                onChange={(e) => setNewGrade(e.target.value)}
                                placeholder="0-100"
                                className="float-end form-control"
                                min="0"
                                max="100"
                            />
                            <Button
                                variant="success"
                                disabled={newGrade > 100 || newGrade < 0}
                                onClick={() => saveGrade(room)}
                                className="float-end fw-light"
                                size="sm"
                            >
                                Save
                            </Button>
                            <Button
                                variant="secondary"
                                onClick={() => setShowGrading(false)}
                                className="float-end fw-light"
                                size="sm"
                            >
                                Cancel
                            </Button>
                        </InputGroup>
                    </>
                ) : (
                    <Button
                        variant="link"
                        onClick={() => setShowGrading(true)}
                        className="text-primary fw-light p-0"
                    >
                        <small>
                            {room.assignment_grade !== null ? (
                                room.assignment_grade + "%"
                            ) : (
                                <span>Click to Grade</span>
                            )}
                        </small>
                    </Button>
                )}
            </td>

            <td>
                <Button
                    href={"/lessons/analytics/" + room.id}
                    target="_blank"
                    variant="card-dark"
                >
                    <i className="bi bi-bar-chart-fill"></i>
                </Button>
            </td>
            <td className="text-end">
                {deleteWarning ? (
                    <>
                        <Button
                            onClick={() => deleteAssignedRoom(room)}
                            size="sm"
                            variant="danger"
                        >
                            Confirm Permanent Delete
                        </Button>
                        <Button
                            onClick={() => setDeleteWarning(false)}
                            size="sm"
                            variant="secondary"
                        >
                            Cancel
                        </Button>
                    </>
                ) : (
                    <Button
                        onClick={() => setDeleteWarning(true)}
                        size="sm"
                        variant="link"
                    >
                        <i className="bi bi-trash-fill text-danger"></i>
                    </Button>
                )}
            </td>
        </tr>
    );
}

export function AssignmentListModalContent(props) {
    const [socket, setSocket] = useState(null);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const { getAccessTokenSilently } = useAuth0();

    const handleRefreshClick = () => {
        setIsRefreshing(true);
        setTimeout(() => setIsRefreshing(false), 700);
        props.refresh();
    };

    const deleteAssignedRoom = (room) => {
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "create:classroom",
        }).then((token) => {
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "classrooms/" +
                    room.id +
                    "/",
                {
                    method: "DELETE",
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                }
            )
                .then((res) => {
                    props.refreshSelectedAssignment();
                })
                .catch((error) => {});
        });
    };

    useEffect(() => {
        if (props.rooms?.length > 0) {
            const socket = new Socket(process.env.REACT_APP_PHOENIX_ENDPOINT);
            socket.onError(async (error) => {});
            socket.connect();
            setSocket(socket);
        }
    }, [props.rooms]);

    const hideAndShowInviteModal = () => {
        props.swapToInvites();
    };

    return (
        <>
            <Row>
                <Col>
                    {props.rooms?.length > 0 ? (
                        <Table hover size="">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Online Students</th>
                                    <th className="">Visit</th>
                                    <th className="">
                                        Code{" "}
                                        <a
                                            href="https://megaminds.tawk.help/article/how-students-can-get-back-to-their-rooms"
                                            target="_blank"
                                        >
                                            <i class="bi bi-question-circle"></i>
                                        </a>
                                    </th>
                                    <th className="">Grade Status</th>
                                    <th>Logs</th>
                                    <th className="text-end">Delete</th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.rooms?.map((room, index) => (
                                    <AssignmentListItem
                                        room={room}
                                        socket={socket}
                                        key={index}
                                        index={index}
                                        deleteAssignedRoom={deleteAssignedRoom}
                                        refresh={props.refresh}
                                        refreshSelectedAssignment={
                                            props.refreshSelectedAssignment
                                        }
                                    />
                                ))}
                            </tbody>
                        </Table>
                    ) : (
                        <Alert variant="warning">
                            <p className="">
                                When students create spaces they will appear
                                here.
                            </p>
                            <p className="mb-0">
                                Click the button below to load new Student
                                Spaces.
                            </p>
                        </Alert>
                    )}
                </Col>
            </Row>
            <Row className="">
                <Col className="mb-3">
                    <Button
                        variant="purple-dark"
                        size="sm"
                        className="me-2 mt-2"
                        disabled={isRefreshing}
                        onClick={() => props.refreshSelectedAssignment()}
                    >
                        {isRefreshing ? (
                            <>
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                />{" "}
                                Load new Student Spaces
                                <span className="visually-hidden">
                                    Loading...
                                </span>
                            </>
                        ) : (
                            <>
                                <i className="bi bi-arrow-clockwise"></i> Load
                                new Student Spaces
                            </>
                        )}
                    </Button>
                    <Button
                        size="sm"
                        className="mt-2"
                        variant="success"
                        onClick={() => hideAndShowInviteModal()}
                    >
                        Invite Students
                    </Button>{" "}
                </Col>
            </Row>
        </>
    );
}

export default function AssignmentListModal(props) {
    const handleClose = () => props.setShow(false);

    return (
        <Modal show={props.show} size="xl" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>
                    Student Spaces for {props.assignment?.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body className="m-4">
                <AssignmentListModalContent
                    swapToInvites={props.swapToInvites}
                    rooms={props.assignment?.classroom_set}
                    refresh={props.refresh}
                    refreshSelectedAssignment={props.refreshSelectedAssignment}
                    setShow={props.setShow}
                    hub_id={props.assignment?.hub_id}
                    showInviteModal={props.showInviteModal}
                />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
