import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";

function ExploreHeader(props) {
    return (
        <Row className="bg-white border-bottom m-0 p-0 pt-2 pb-2 p-md-3 d-none d-sm-flex">
            <Col md={6} lg={4} className="align-items-center">
                <h3 className="mb-0">Explore</h3>
            </Col>
            <Col className="d-flex align-items-center justify-content-md-end">
                {props.profile?.can_review && (
                    <Button
                        as={Link}
                        to="/explore/review"
                        variant="secondary"
                        className="ms-lg-3 p-md-2 p-lg-2 ps-lg-3 pe-lg-3"
                    >
                        <i className="bi bi-collection-fill"></i>
                        <span className="ms-3">Review</span>
                    </Button>
                )}
                <Button
                    as={Link}
                    to="/new-listing"
                    variant="purple-dark"
                    className="ms-lg-3 p-md-2 p-lg-2 ps-lg-3 pe-lg-3"
                >
                    <i className="bi bi-plus-circle"></i>
                    <span className="ms-2">Submit a Lesson</span>
                </Button>
            </Col>
        </Row>
    );
}

export default ExploreHeader;
