import React from "react";
import { Scatter } from "react-chartjs-2";

const processDataForScatterChart = (data) => {
    const scatterData = [];

    data.forEach((session) => {
        const startTime = new Date(session.date_start);
        const hours = startTime.getHours() + startTime.getMinutes() / 60;
        const timeSpent = session.time_spent_minutes;

        scatterData.push({ x: hours, y: timeSpent });
    });

    return scatterData;
};

const formatAMPM = (hours) => {
    const suffix = hours >= 12 ? "PM" : "AM";
    let hour = hours % 12 || 12; // Convert 24h to 12h format
    hour = Math.floor(hour);
    return `${hour} ${suffix}`;
};

const PeakActivityScatterChart = ({ data }) => {
    const scatterData = processDataForScatterChart(data);

    const chartData = {
        datasets: [
            {
                label: "User Sessions",
                data: scatterData,
                backgroundColor: "rgba(255, 99, 132, 0.6)",
                borderColor: "rgba(255, 99, 132, 1)",
                borderWidth: 1,
                pointRadius: 5,
            },
        ],
    };

    const options = {
        scales: {
            x: {
                type: "linear",
                position: "bottom",
                ticks: {
                    callback: (value) => formatAMPM(value),
                },
                title: {
                    display: true,
                    text: "Time of Day",
                },
                grid: {
                    display: false,
                },
            },
            y: {
                title: {
                    display: true,
                    text: "Time Spent (minutes)",
                },
                grid: {
                    display: true,
                    drawBorder: false,
                },
            },
        },
        plugins: {
            tooltip: {
                callbacks: {
                    label: (context) => {
                        return `Time Spent: ${context.raw.y.toFixed(2)} mins`;
                    },
                    title: (context) => {
                        return `Session at ${formatAMPM(context[0].raw.x)}`;
                    },
                },
            },
        },
    };

    return <Scatter data={chartData} options={options} />;
};

export default PeakActivityScatterChart;
