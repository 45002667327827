import React from "react";
import Container from "react-bootstrap/Container";
import Card from "react-bootstrap/Card";
import Badge from "react-bootstrap/Badge";
import ListGroup from "react-bootstrap/ListGroup";

const WhatsNew = () => {
    return (
        <Container className="p-5">
            <Card className="dash-card p-2">
                <Card.Body>
                    <Card.Title>
                        <h5 className="fw-bold">What’s New</h5>
                    </Card.Title>
                    <Card.Text className="text-muted mt-4">
                        <small>
                            On this page you will find updates regarding
                            noteable features and changes.
                        </small>
                    </Card.Text>
                    <Card.Text className="text-muted">
                        <small>
                            Our development team also deploy fixes and smaller
                            changes every single day, however these will not be
                            reflected here.
                        </small>
                    </Card.Text>
                    <p className="">
                        <small>
                            Have suggestions or features you'd like to see?
                        </small>
                        <small>
                            {" "}
                            Drop us a line at{" "}
                            <a href="mailto:contact@gomegaminds.com">
                                contact@gomegaminds.com
                            </a>
                        </small>
                    </p>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                Updates to Student Spaces
                                <Badge bg="secondary" className="float-end">
                                    October 30th
                                </Badge>
                            </p>
                            <ul>
                                <li>
                                    <p>
                                        You can now grade Student Spaces (from
                                        Student Projects -> View Student Spaces)
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        You can now access analytics for Student
                                        Spaces (from Student Projects -> View
                                        Student Spaces)
                                    </p>
                                </li>
                                <li>
                                    <p>
                                        Bug fixes related to accessing rooms
                                        +++.
                                    </p>
                                </li>
                            </ul>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                You can now archive Lessons.
                                <Badge bg="secondary" className="float-end">
                                    August 25th
                                </Badge>
                            </p>
                            <p>
                                Archived Lessons are lessons you don't plan to
                                use actively but still want to keep around
                                without deleting them.
                            </p>
                            <p>
                                Find the new "Archive" tab on the Lessons page.
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                You can now share Lessons within Organizations
                                (for Schools & Districts)
                                <Badge bg="secondary" className="float-end">
                                    August 20th
                                </Badge>
                            </p>
                            <p>Find the new tab in your "Lessons" page</p>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                Rooms are now renamed to Lessons
                                <Badge bg="secondary" className="float-end">
                                    June 24th
                                </Badge>
                            </p>
                            <p>
                                For more clearity on the use case, we have
                                decided to rename "Rooms" to "Lessons".
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                Organization (School/District) Level Feature
                                Selection
                                <Badge bg="secondary" className="float-end">
                                    June 24th
                                </Badge>
                            </p>
                            <p>
                                District/School admins can now disable features
                                such as Microphone, Webcam, Reactions site-wide
                                from the Organization menus.
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                Student Project Permissions
                                <Badge bg="secondary" className="float-end">
                                    June 24th
                                </Badge>
                            </p>
                            <p>
                                You can now set permissions and lock Student
                                Rooms made inside a Student Project.
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                Instant Room Permissions
                                <Badge bg="secondary" className="float-end">
                                    May 20th
                                </Badge>
                            </p>
                            <p>
                                Changing the Room Security Settings
                                (permissions, locking room, etc.) on the
                                dashboard will immediatley take effect for
                                connected users inside a room.
                            </p>
                            <p>
                                Users are no longer required to reload the page
                                for changes to room permissions take effect.
                            </p>
                            <p>
                                Locking a room will now disconnect all connected
                                participants.
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                Performance and Audio Improvements
                                <Badge bg="secondary" className="float-end">
                                    May 15th
                                </Badge>
                            </p>
                            <p>
                                We have made changes to our platform to improve
                                audio quality and overall performance.
                            </p>
                            <p>3D models and rooms should load faster.</p>
                            <p>
                                Overall load times (such as cloning, creating
                                rooms) are reduced
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                Explore Page Redesign
                                <Badge bg="secondary" className="float-end">
                                    March 22nd
                                </Badge>
                            </p>
                            <p>
                                We have redesigned the Explore page with a
                                filter sidebar to make it easier to find what
                                you are looking for. We have also added the
                                option to filter by Activity types, so check out
                                all the different use cases and get inspired!
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                Assignments renamed to Student Projects
                                <Badge bg="secondary" className="float-end">
                                    March 5th
                                </Badge>
                            </p>
                            <p>
                                We have decided to rename "Assignments" to
                                "Student Projects" to help communicate the use
                                case of this feature.
                            </p>
                            <p>
                                Everything remains the same, but it's now called
                                Student Projects.
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                QR Code Invites
                                <Badge bg="secondary" className="float-end">
                                    January 22th
                                </Badge>
                            </p>
                            <p>
                                We have added QR Codes to the Invite menus for
                                both Assignments and Rooms.
                            </p>
                            <p>You can see the QR codes under</p>
                            <ul>
                                <li>
                                    <small>Rooms {"->"} Invite</small>
                                </li>
                                <li>
                                    <small>Assignments {"->"} Share</small>
                                </li>
                            </ul>
                            <p>
                                <small>
                                    You can also make them huge to let users
                                    scan the app from far away, like
                                    Smartboards, etc.
                                </small>
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                New Landing page{" "}
                                <Badge bg="secondary" className="float-end">
                                    January 15th
                                </Badge>
                            </p>
                            <p>
                                <small>
                                    We have made it easier to access our Getting
                                    Started and help resources, as well as
                                    giving everything a calmer and friendlier
                                    look.
                                </small>
                            </p>
                            <p>
                                <small>
                                    We plan to expand this page with analytics
                                    and quick-glance room monitoring in the
                                    future.
                                </small>
                            </p>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                New Assignments flow
                                <Badge className="float-end" bg="secondary">
                                    January 15th
                                </Badge>
                            </p>
                            <p>
                                <small>
                                    We have made it easier to set up an
                                    assignment and scaffold your assignments.
                                    Moving assignments out from rooms and into
                                    its own menu also helps reduce complexity of
                                    the "My Rooms" page.
                                </small>
                            </p>
                            <p>
                                <small>
                                    You can also start an assignment easilly
                                    without an existing room from the "New
                                    Assignment" button on "Assignments" page.
                                </small>
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">GeoGebra Support</p>
                            <p>
                                <small>
                                    You can now add GeoGebra activities directly
                                    inside a MegaMinds room.
                                </small>
                            </p>
                            <p className="fw-light">
                                <small>
                                    <i>
                                        Inside the room, click Add {"->"}{" "}
                                        GeoGebra
                                    </i>
                                </small>
                            </p>
                            <p className="fw-light">
                                <small>
                                    <i>
                                        Read our guide on{" "}
                                        <a
                                            href="https://megaminds.tawk.help/article/how-do-i-add-geogebra-to-my-room"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            Adding GeoGebra to your rooms
                                        </a>
                                    </i>
                                </small>
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">New Assessment Tools</p>
                            <p>
                                <small>
                                    You can now grade and export assessment
                                    data, and add Multiple Choice Questions to
                                    rooms!
                                </small>
                            </p>
                            <p className="fw-light">
                                <small>
                                    <i>
                                        From My Rooms {"->"} Submissions to see
                                        the new assessment dashboard
                                    </i>
                                </small>
                            </p>
                            <p className="fw-light">
                                <small>
                                    <i>
                                        Add Content {"->"} Multiple Choice
                                        Question from within a room
                                    </i>
                                </small>
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">Polypad Integration</p>
                            <p>
                                <small>
                                    You can now use Mathilab Polypad to add math
                                    manipulatives to your room.
                                </small>
                            </p>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">Changes to Edit Mode</p>
                            <p>
                                <small>
                                    We have made it simpler to add and edit
                                    content. The old Edit Mode toolbar is gone
                                    in favour of a sleek "Add Content" menu and
                                    a quick toggle to move and edit content
                                    placed in the room.
                                </small>
                            </p>
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
                <Card.Body>
                    <ListGroup>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">Templates</p>
                            <small>
                                Instantly create rooms from our templates and
                                example content. Use them as is or swap out the
                                content with your own.
                            </small>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">Assignments</p>
                            <small>
                                Give your students their own rooms to work on
                                independently or in groups to demonstrate
                                knowledge, problem solve, and express themselves
                                creatively.
                            </small>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                Automatically Place Content
                            </p>
                            <small>
                                When starting with a blank room, you can now
                                upload all your files and links and have them
                                automatically populate along the walls inside
                                the room.
                            </small>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">Edtech Integrations</p>
                            <p>
                                <small>
                                    You can now use NearPod, Pear Deck, Kahoot
                                    and Quizizz inside a room so you can
                                    continue to use the lessons you’ve already
                                    created, or add a new, more engaging,
                                    dimension to your existing Edtech tools.
                                </small>
                            </p>
                            <p>
                                <small className="fw-light">
                                    Enter Edit Mode and you’ll see the new
                                    integration button.
                                </small>
                            </p>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">
                                Replacing / Editing Content
                            </p>
                            <p>
                                <small>
                                    We have added new ways to edit content
                                    already placed in the room. Easily swap out
                                    existing content or change text as if you
                                    were editing a document. Enter Edit Mode and
                                    click on an object to see the new options.
                                </small>
                            </p>
                            <p>
                                <small className="fw-light">
                                    Enter Edit Mode and click on an object to
                                    see the new options.
                                </small>
                            </p>
                        </ListGroup.Item>
                        <ListGroup.Item as="li" className="p-3">
                            <p className="fw-bold">360 Photos</p>
                            <small>
                                You can now add 360 Photos, or convert a regular
                                photo into 360 to create engaging tours or
                                virtual field trips. In Edit Mode, simply check
                                ‘360 Photo’ in the Object Menu. Bonus: Try it
                                with a GIF and see what happens!
                            </small>
                            <br />
                        </ListGroup.Item>
                    </ListGroup>
                </Card.Body>
            </Card>
        </Container>
    );
};

export default WhatsNew;
