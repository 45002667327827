import React, { useState } from "react";
import { Link } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import usePendingTemplates from "../../services/usePendingTemplates";
import PendingReviewCard from "./cards/PendingReviewCard";
import Paginator from "../../components/Paginator";

const PendingReview = () => {
    // Use what API to get pending rooms?
    const [page, setPage] = useState(1);
    const [templates, isLoading, isError, refreshTemplates] =
        usePendingTemplates(page);

    console.log(templates, isError);

    if (isError) {
        return <p>Something went wrong! {isError}</p>;
    }

    return (
        <>
            <Container fluid className="g-0 bg-light">
                <Row className="bg-white p-3 p-lg-4 border-bottom m-0">
                    <Link to="/explore">
                        <i className="bi bi-arrow-left me-2"></i>Explore
                    </Link>
                </Row>
            </Container>
            <Container className="g-0 bg-light">
                <Row>
                    {!isLoading &&
                        templates.map((template) => (
                            <Row key={template.id} className="p-0 m-0 g-0">
                                <PendingReviewCard
                                    template={template}
                                    refresh={refreshTemplates}
                                />
                            </Row>
                        ))}
                    {!isLoading && templates.length === 0 && (
                        <p className="mt-5">Currently no pending templates.</p>
                    )}
                </Row>
                <Row>
                    <Paginator
                        items={templates}
                        page={page}
                        setPage={setPage}
                    />
                </Row>
            </Container>
        </>
    );
};

export default PendingReview;
