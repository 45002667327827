import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import toast from "react-hot-toast";
import ReactGA from "react-ga4";

import { useAuth0 } from "@auth0/auth0-react";

function EditAssignmentModal(props) {
    const [show, setShow] = useState(false);

    const { getAccessTokenSilently } = useAuth0();

    const [title, setTitle] = useState(props.object.title);
    const [description, setDescription] = useState(props.object.instructions);
    const [count, setCount] = useState(props.instructions?.length);
    const [isPosting, setIsPosting] = useState(false);

    const handleClose = () => setShow(false);

    const openModal = () => {
        ReactGA.event({
            category: props.category,
            action: "modal_opened_edit_room",
            label: "User opened the Edit modal", // optional
        });
        setShow(true);
    };

    const handleSubmit = () => {
        setIsPosting(true);
        let payload = {
            title: title,
            instructions: description,
        };

        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            fetch(
                process.env.REACT_APP_MM_API_URL +
                    "assignments/detail/" +
                    props.object.id,
                {
                    method: "PATCH",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify(payload),
                }
            )
                .then((response) => {
                    return response.json();
                })
                .then((data) => {
                    console.log("Success", data);
                    setShow(false);
                    props.refresh();
                    setIsPosting(false);
                    toast.success("Changes were saved.");
                })
                .catch((error) => {
                    toast.error("Something went wrong while saving.");
                    console.error(error);
                    setIsPosting(false);
                });
        });
    };

    const handleDescription = (e) => {
        setDescription(e.target.value);
        setCount(e.target.value.length);
    };

    return (
        <>
            <Button
                variant="white"
                className="rounded-0"
                as={Dropdown.Item}
                onClick={() => openModal()}
            >
                {props.buttonText}
            </Button>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit {props.object.title}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Label>Title</Form.Label>
                    <Form.Control
                        onChange={(e) => setTitle(e.target.value)}
                        value={title}
                        type="text"
                        onKeyDown={(e) => {
                            if (e.key === "Enter") {
                                handleSubmit();
                            }
                        }}
                        placeholder="My amazing room"
                    />
                    <Form.Group className="mb-3 mt-3">
                        <Form.Label>Instructions</Form.Label>
                        <Form.Text className="text-muted">
                            <p>
                                <small>
                                    Here you can give your students some
                                    specific instructions on what to do in their
                                    rooms.
                                </small>
                            </p>
                        </Form.Text>
                        <Form.Control
                            as="textarea"
                            disabled={isPosting}
                            value={description}
                            onChange={(e) => handleDescription(e)}
                            placeholder=""
                            style={{
                                minHeight: "110px",
                            }}
                            type="text"
                        />
                        {count > 500 && (
                            <Form.Text
                                className={
                                    count > 2000 ? "text-danger" : "text-muted"
                                }
                            >
                                <p className="mt-1 fw-light float-end">
                                    <small>Character Limit: {count}/2000</small>
                                </p>
                            </Form.Text>
                        )}
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>
                        Discard
                    </Button>
                    <Button
                        variant="success"
                        disabled={isPosting || count > 2000}
                        onClick={handleSubmit}
                    >
                        {isPosting ? (
                            <>
                                <span
                                    className="spinner-border spinner-border-sm me-2"
                                    role="status"
                                    aria-hidden="true"
                                ></span>
                                <span>Loading...</span>
                            </>
                        ) : (
                            <span>Save changes</span>
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default EditAssignmentModal;
