import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import InputGroup from "react-bootstrap/InputGroup";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth0 } from "@auth0/auth0-react";
import {
    CardElement,
    AddressElement,
    Elements,
    useStripe,
    useElements,
} from "@stripe/react-stripe-js";

import { useNavigate } from "react-router-dom";

const applyCoupon = (coupon, token, setCouponError, addCoupon) => {
    fetch(
        process.env.REACT_APP_MM_API_URL +
            "payments/coupons/retrieve/" +
            coupon,
        {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        }
    )
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (data.error) {
                console.log("error coupon", data);
                setCouponError(data.message);
            } else {
                if (data.active) {
                    console.log("Coupon result", data);
                    addCoupon(data);
                } else {
                    setCouponError("Invalid Coupon");
                }
            }
        })
        .catch((error) => {
            console.log("Coupon error", error);
        });
};

const createSubscription = (
    paymentMethod,
    price,
    stripe,
    token,
    coupon,
    setLoading,
    setErrorText,
    onFinished,
    address
) => {
    console.log("Creating subscription...");
    console.log("Coupon", coupon);
    const payload = {
        price: price,
        paymentMethod: paymentMethod.id,
        address: address,
        coupon: coupon?.id,
    };
    console.log("payload", payload);

    fetch(process.env.REACT_APP_MM_API_URL + "payments/subscription/create", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(payload),
    })
        .then((response) => {
            return response.json();
        })
        .then((data) => {
            if (data.error) {
                setLoading(false);
                setErrorText(data);
                return;
            }

            if (data.latest_invoice.paid) {
                console.log("Paid");
                setTimeout(() => {
                    setLoading(false);
                    onFinished();
                }, 2000);
            } else {
                stripe
                    .confirmPayment({
                        clientSecret:
                            data.latest_invoice.payment_intent.client_secret,
                        confirmParams: {
                            return_url: window.location.href,
                        },
                    })
                    .then((result) => {
                        if (result.error) {
                            alert(result.error.message);
                        } else {
                            alert("success card");
                        }
                    });
            }
        })
        .catch((error) => {
            console.log("Error", error);
            setLoading(false);
            setErrorText(error);
        });
};

const CheckoutForm = (props) => {
    const navigate = useNavigate();
    const stripe = useStripe();
    const elements = useElements();
    const { getAccessTokenSilently } = useAuth0();

    const [buttonText] = useState("Upgrade Now");
    const [showCouponMenu, setShowCouponMenu] = useState(false);
    const [loading, setLoading] = useState(false);
    const [address, setAddress] = useState("");
    const [addressCompleted, setAddressCompleted] = useState(false);
    const [cardCompleted, setCardCompleted] = useState(false);
    const [errorText, setErrorText] = useState("");

    const getStripePrice = () => {
        if (props.cycle === "monthly") {
            return process.env.REACT_APP_STRIPE_MONTHLY_PRICE;
        }
        if (props.cycle === "yearly") {
            return process.env.REACT_APP_STRIPE_YEARLY_PRICE;
        }
    };

    const onFinished = () => {
        navigate("/profile", { state: { subscriptionSuccess: true } });
    };
    const loadingText = (
        <>
            <p className="mb-0">
                <Spinner
                    as="span"
                    animation="border"
                    className="me-2"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                />
                Processing...
            </p>
            <span className="visually-hidden">Loading...</span>
        </>
    );

    const handleSubmit = async (event) => {
        // Create PaymentMethod
        setLoading(true);
        let card = elements.getElement(CardElement);
        if (elements == null) {
            return;
        }
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: card,
        });

        if (error) {
            setLoading(false);
            setErrorText(error);
            return;
        }

        // Create Subscription
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            createSubscription(
                paymentMethod,
                getStripePrice(),
                stripe,
                token,
                props.activeCoupon,
                setLoading,
                setErrorText,
                onFinished,
                address
            );
        });
    };
    return (
        <form>
            <Row>
                <Col>
                    <Form.Group>
                        <AddressElement
                            options={{ mode: "billing" }}
                            onChange={(event) => {
                                setAddressCompleted(event.complete);

                                if (event.complete) {
                                    // Extract potentially complete address
                                    setAddress(event.value.address);
                                }
                            }}
                        />
                        <CardElement
                            onChange={(event) => {
                                setCardCompleted(event.complete);
                            }}
                            className="form-control p-3 mt-4"
                        />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label className="mt-4">
                            <p className="text-danger">{errorText?.message}</p>
                        </Form.Label>
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <small className="fw-light mb-2">
                    By continuing you accept our Terms and Conditions and Terms
                    of Service
                </small>
            </Row>
            <Row>
                <small className="fw-bold">
                    {props.cycle === "monthly"
                        ? "$" + props.monthlyPrice
                        : "$" + props.yearlyPrice}{" "}
                    USD will be charged when clicking "Pay Now".
                </small>
            </Row>
            {props.cycle === "monthly" && (
                <>
                    <Row className="mt-4">
                        <Col>
                            <Button
                                variant="link"
                                onClick={() =>
                                    setShowCouponMenu(!showCouponMenu)
                                }
                            >
                                Coupon
                            </Button>
                        </Col>
                    </Row>
                    {showCouponMenu && (
                        <Row className="mt-3">
                            <Col>
                                {props.activeCoupon ? (
                                    <ListGroup>
                                        <ListGroup.Item>
                                            <Row>
                                                <Col
                                                    xs={1}
                                                    className="d-flex justify-content-center align-items-center"
                                                >
                                                    <i className="bi bi-check text-success"></i>
                                                </Col>
                                                <Col xs={4}>
                                                    {
                                                        props.activeCoupon
                                                            .coupon.name
                                                    }
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    </ListGroup>
                                ) : (
                                    <>
                                        <InputGroup>
                                            <Form.Control
                                                type="text"
                                                placeholder="Coupon Code"
                                                onChange={(e) =>
                                                    props.setCoupon(
                                                        e.target.value
                                                    )
                                                }
                                            />
                                            <Button
                                                variant="secondary"
                                                onClick={() =>
                                                    props.handleCouponApplied()
                                                }
                                            >
                                                Apply
                                            </Button>
                                        </InputGroup>
                                        {props.couponError && (
                                            <Form.Label className="mt-2 text-danger">
                                                {props.couponError}
                                            </Form.Label>
                                        )}
                                    </>
                                )}
                            </Col>
                        </Row>
                    )}
                </>
            )}
            <Button
                variant="success"
                className="mt-4 w-100"
                onClick={() => handleSubmit()}
                disabled={loading || !addressCompleted || !cardCompleted}
            >
                {loading ? loadingText : buttonText}
            </Button>
        </form>
    );
};

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PK);

export function CheckoutSingleModal({ setShow, show }) {
    const [cycle, setCycle] = useState("monthly");

    const [coupon, setCoupon] = useState("");
    const [activeCoupon, setActiveCoupon] = useState(null);

    const [couponError, setCouponError] = useState("");

    const [yearlyPrice] = useState(149.99);
    const [monthlyPrice] = useState(14.99);

    const { getAccessTokenSilently } = useAuth0();

    const yearlyMonthlyCost = (149.99 / 12).toFixed(2);

    const addCoupon = (new_coupon) => {
        setActiveCoupon(new_coupon);
    };

    useEffect(() => {
        if (cycle === "yearly" && activeCoupon) {
            setCoupon(null);
            setActiveCoupon(null);
        }
    }, [cycle, activeCoupon]);

    const handleCouponApplied = () => {
        console.log("APplying coupon", coupon);
        getAccessTokenSilently({
            audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
            scope: "update:classroom",
        }).then((token) => {
            applyCoupon(coupon, token, setCouponError, addCoupon);
        });
    };

    return (
        <Modal show={show} size="md" scrollable onHide={() => setShow(false)}>
            <Modal.Header closeButton>
                <Modal.Title>Checkout</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Row className="mt-2">
                    <Col xs={12}>
                        <Card className="h-100">
                            <Card.Body>
                                <Row className="">
                                    <h5 className="">MegaMinds Premium</h5>
                                    <p className="fw-light mb-0">
                                        Upgrade your account to Premium
                                        subscription.
                                    </p>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <h5>Cycle</h5>
                </Row>
                <Row className="mt-2">
                    <Col xs={6}>
                        <Card
                            onClick={() => setCycle("monthly")}
                            className={
                                cycle === "monthly"
                                    ? "border border-5 border-success h-100 shadow cursor-pointer"
                                    : "h-100 border border-5 cursor-pointer"
                            }
                        >
                            <Card.Body>
                                <Row className="">
                                    <h5 className="">Monthly</h5>
                                    <p className="fw-bold">
                                        ${monthlyPrice} / month
                                    </p>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col xs={6}>
                        <Card
                            onClick={() => setCycle("yearly")}
                            className={
                                cycle === "yearly"
                                    ? "border border-5 border-success h-100 shadow cursor-pointer"
                                    : "h-100 border border-5 cursor-pointer"
                            }
                        >
                            <Card.Body>
                                <Row className="">
                                    <h5 className="">Yearly</h5>
                                    <p className="fw-bold">
                                        ${yearlyMonthlyCost} / month
                                    </p>
                                    <small className="fw-light">
                                        ${yearlyPrice} billed annually
                                    </small>
                                </Row>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-5">
                    <h5>Payment Details</h5>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Elements stripe={stripePromise}>
                            <CheckoutForm
                                setCouponError={setCouponError}
                                setCoupon={setCoupon}
                                couponError={couponError}
                                activeCoupon={activeCoupon}
                                setActiveCoupon={setActiveCoupon}
                                handleCouponApplied={handleCouponApplied}
                                cycle={cycle}
                                coupon={coupon}
                                monthlyPrice={monthlyPrice}
                                yearlyPrice={yearlyPrice}
                            />
                        </Elements>
                    </Col>
                </Row>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShow(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}
