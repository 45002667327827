import React, { useState, useEffect } from "react";
import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { Link } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Alert from "react-bootstrap/Alert";
import { Tooltip } from "react-tooltip";
import { toast } from "react-hot-toast";
import Form from "react-bootstrap/Form";
import { getPicture } from "../../utils/get-picture";

import MyScenesBrowser from "../rooms/MyScenesBrowser";
import MyRoomsBrowser from "../rooms/MyRoomsBrowser";
import SceneBrowserCard from "../rooms/cards/SceneBrowserCard";
import RoomBrowserCard from "../rooms/cards/RoomBrowserCard";
import useRoomAPI from "../../services/useRoomAPI";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { createRoom } from "../../utils/create-room";
import { useAuth } from "../../context/AuthContext";

const randomPlaceholders = [
    "Present your favorite topic",
    "Create a living document that will be used throughout this semester.",
    "Demonstrate photosynthesis",
    "Present 3 different greek philosophers and their core ideas",
];
const randomPlaceholder =
    randomPlaceholders[Math.floor(Math.random() * randomPlaceholders.length)];

const NewAssignmentPage = (props) => {
    const { getAccessTokenSilently } = useAuth0();
    const navigate = useNavigate();
    const auth = useAuth();

    const [isPosting, setIsPosting] = useState(false);
    const [showBrowser, setShowBrowser] = useState(false);
    const [showRoomBrowser, setShowRoomBrowser] = useState(false);

    const [titleError, setTitleError] = useState(false);
    const [titleLengthError, setTitleLengthError] = useState(false);
    const [page, setPage] = useState(0);
    const [validated, setValidated] = useState(0);

    const [title, setTitle] = useState("");
    const [description, setDescription] = useState("");
    const [group, setGroup] = useState(false);
    const [empty, setEmpty] = useState(true);
    const [selectedScene, setSelectedScene] = useState(null);
    const [selectedRoom, setSelectedRoom] = useState(null);

    const [rooms, isLoading, isError] = useRoomAPI(1, "", "-id", auth);

    const [count, setCount] = useState(0);

    const pickRandomPlaceholder = () => {
        return randomPlaceholder;
    };

    const redirectToClassroomList = (id) => {
        navigate("/student-projects", {
            state: {
                newRoom: id,
            },
        });
    };

    useEffect(() => {
        if (!isLoading && rooms?.results && rooms.results.length > 0) {
            setSelectedRoom(rooms.results[0]);
        }
    }, [isLoading, rooms]);

    const handleDescription = (e) => {
        setDescription(e.target.value);
        setCount(e.target.value.length);
    };
    const handleSubmit = () => {
        setIsPosting(true);

        if (title.length > 255) {
            setTitleError(false);
            setTitleLengthError(true);
            setIsPosting(false);
            return;
        } else {
            setTitleLengthError(false);
        }

        if (!title) {
            setTitleError("Please give your Project a name.");
            setIsPosting(false);
            return;
        } else {
            setTitleError(false);
        }

        if (empty) {
            // We create an empty classroom first
            let classroom = {
                title: "ASSIGNMENT ROOT",
                description: description,
                scene: selectedScene.id,
                // Student Project
                is_group: group,
                is_fresh: false,
                is_empty_root: true,
                team: null,
                // Permissions
                guests_can_move: true,
                guests_can_create: true,
                guests_can_chat: true,
                guests_can_draw: true,
                guests_can_raise_hand: true,
                guests_can_react: true,
                guests_can_share_video: true,
                guests_can_share_microphone: true,
                guests_can_see_student_menu: true,
                guests_can_spawn_stickynotes: true,
            };

            console.log("Creating using the following payload", classroom);

            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                scope: "create:classroom",
            }).then((token) => {
                createRoom(classroom, token)
                    .then((resp) => {
                        let assignment = {
                            title: title,
                            instructions: description,
                            is_group: group,
                            related_room: resp.id,
                        };

                        fetch(
                            process.env.REACT_APP_MM_API_URL +
                                "assignments/create",
                            {
                                method: "POST",
                                headers: {
                                    Authorization: `Bearer ${token}`,
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                },
                                body: JSON.stringify(assignment),
                            }
                        )
                            .then((res) => res.json())
                            .then((data) => {
                                console.log(
                                    "SUCCESSFULLY CREATED ASSIGNMENT",
                                    data
                                );
                                redirectToClassroomList(data.id);
                            })
                            .catch((e) => {
                                error(e);
                            });
                    })
                    .catch((e) => {
                        toast.error(e.message);
                        setIsPosting(false);
                    });
            });
        } else {
            let assignment = {
                title: title,
                instructions: description,
                is_group: group,
                related_room: selectedRoom.id,
            };

            getAccessTokenSilently({
                audience: process.env.REACT_APP_AUTH0_API_AUDIENCE,
                scope: "create:classroom",
            }).then((token) => {
                fetch(process.env.REACT_APP_MM_API_URL + "assignments/create", {
                    method: "POST",
                    headers: {
                        Authorization: `Bearer ${token}`,
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(assignment),
                })
                    .then((res) => res.json())
                    .then((data) => {
                        console.log("SUCCESSFULLY CREATED ASSIGNMENT", data);
                        redirectToClassroomList(data.id);
                        setIsPosting(false);
                    })
                    .catch((e) => {
                        console.error(e);
                        toast.error(e.message);
                        setIsPosting(false);
                    });
            });
        }
    };

    if (isLoading) {
        <p>Loading...</p>;
    }

    if (page === 0) {
        // General Info
        return (
            <>
                <Container fluid>
                    <Button
                        variant="link"
                        className="p-0 mt-3 mb-3 text-decoration-none"
                        as={Link}
                        to={"/student-projects"}
                    >
                        <i className="bi bi-arrow-left me-2"></i>
                        Back to Student Projects
                    </Button>
                </Container>
                <Container
                    className="g-0 bg-light"
                    style={{ maxWidth: "612px" }}
                >
                    <Row className="">
                        <Col>
                            <Card className="dash-card w-100 mx-auto">
                                <Tooltip id="info-form" />
                                <Card.Header className="ps-4 bg-white">
                                    <Card.Title className="pt-2">
                                        New Student Project
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="ps-4 pe-4">
                                    <Form.Label>
                                        <strong>
                                            Empty Space or Existing Lesson
                                        </strong>
                                    </Form.Label>
                                    <Form.Text className="text-muted">
                                        <p>
                                            Do you want your students to start
                                            with an empty 3D Space, or use one
                                            of your own Lessons as a template to
                                            get started?
                                        </p>
                                    </Form.Text>
                                    <Row>
                                        <Col xs={6}>
                                            <Card
                                                onClick={() => setEmpty(true)}
                                                className={
                                                    empty
                                                        ? "p-4 text-center form-card-light border-success"
                                                        : "p-4 text-center form-card-white"
                                                }
                                            >
                                                <Card.Body>
                                                    <i
                                                        style={{
                                                            fontSize: "1.4rem",
                                                        }}
                                                        className="bi bi-box text-purple-dark"
                                                    ></i>
                                                    <p className="mb-0 prevent-select">
                                                        Empty Space
                                                    </p>
                                                    {empty ? (
                                                        <span
                                                            style={{
                                                                lineHeight:
                                                                    "100%",
                                                            }}
                                                        >
                                                            <i
                                                                style={{
                                                                    fontSize:
                                                                        "1.4rem",
                                                                }}
                                                                className="position-absolute top-0 end-0 bi bi-check-circle-fill text-success m-2"
                                                            ></i>
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{
                                                                lineHeight:
                                                                    "100%",
                                                            }}
                                                        >
                                                            <i
                                                                style={{
                                                                    fontSize:
                                                                        "1.4rem",
                                                                }}
                                                                className="position-absolute top-0 end-0 bi bi-circle-fill text-muted m-2"
                                                            ></i>
                                                        </span>
                                                    )}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                        <Col xs={6}>
                                            <Card
                                                onClick={() => setEmpty(false)}
                                                className={
                                                    !empty
                                                        ? "p-4 text-center form-card-light border-success"
                                                        : "p-4 text-center form-card-white"
                                                }
                                            >
                                                <Card.Body>
                                                    <i
                                                        style={{
                                                            fontSize: "1.4rem",
                                                        }}
                                                        className="bi bi-search text-purple-dark"
                                                    ></i>
                                                    <p className="mb-0 prevent-select">
                                                        Existing Lesson
                                                    </p>
                                                    {!empty ? (
                                                        <span
                                                            style={{
                                                                lineHeight:
                                                                    "100%",
                                                            }}
                                                        >
                                                            <i
                                                                style={{
                                                                    fontSize:
                                                                        "1.4rem",
                                                                }}
                                                                className="position-absolute top-0 end-0 bi bi-check-circle-fill text-success m-2"
                                                            ></i>
                                                        </span>
                                                    ) : (
                                                        <span
                                                            style={{
                                                                lineHeight:
                                                                    "100%",
                                                            }}
                                                        >
                                                            <i
                                                                style={{
                                                                    fontSize:
                                                                        "1.4rem",
                                                                }}
                                                                className="position-absolute top-0 end-0 bi bi-circle-fill text-muted m-2"
                                                            ></i>
                                                        </span>
                                                    )}
                                                </Card.Body>
                                            </Card>
                                        </Col>
                                    </Row>
                                </Card.Body>
                                {!empty && !rooms.count && (
                                    <Card.Body className="ps-4 pe-4">
                                        <Alert variant="warning" className="">
                                            <p className="">
                                                You have no existing Lessons.
                                            </p>
                                            <p className="mb-0 text-muted">
                                                <small>
                                                    Create rooms on the{" "}
                                                    <Link to="/lessons">
                                                        My Lessons
                                                    </Link>{" "}
                                                    page, or grab user-created
                                                    Lessons on the{" "}
                                                    <Link to="/explore">
                                                        Explore
                                                    </Link>{" "}
                                                    page.
                                                </small>
                                            </p>
                                        </Alert>
                                    </Card.Body>
                                )}
                                <Card.Footer className="ps-4 pe-4 pt-3 pb-3">
                                    <Row>
                                        <Col></Col>
                                        <Col className="d-flex justify-content-center align-items-center">
                                            <p className="mb-0 text-muted">
                                                <small>Step 1 of 3</small>
                                            </p>
                                        </Col>
                                        <Col className="d-flex justify-content-end align-items-center">
                                            <Button
                                                disabled={
                                                    !empty && rooms.count <= 0
                                                }
                                                variant="success"
                                                onClick={() => setPage(2)}
                                            >
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    if (page === 2) {
        // General Info
        return (
            <>
                <Container fluid>
                    <Button
                        variant="link"
                        className="p-0 mt-3 mb-3 text-decoration-none"
                        as={Link}
                        to={"/student-projects"}
                    >
                        <i className="bi bi-arrow-left me-2"></i>
                        Back to Student Projects
                    </Button>
                </Container>
                <Container
                    className="g-0 bg-light"
                    style={{ maxWidth: "612px" }}
                >
                    <Row className="">
                        <Col>
                            <Card className="dash-card w-100 mx-auto">
                                <Tooltip id="info-form" />
                                <Card.Header className="ps-4 bg-white">
                                    <Card.Title className="pt-2">
                                        New Student Project
                                    </Card.Title>
                                </Card.Header>
                                <Card.Body className="ps-4 pe-4">
                                    {empty ? (
                                        <Form>
                                            <Form.Group className="mb-3 mt-3">
                                                <Form.Label>
                                                    <strong>
                                                        Select a design
                                                    </strong>
                                                </Form.Label>
                                                <Form.Text className="text-muted">
                                                    <p>
                                                        This will be the empty
                                                        design your students
                                                        will build in
                                                    </p>
                                                </Form.Text>
                                            </Form.Group>
                                            <MyScenesBrowser
                                                show={showBrowser}
                                                setShow={setShowBrowser}
                                                selectedScene={selectedScene}
                                                setSelectedScene={
                                                    setSelectedScene
                                                }
                                                setScene={(room) => {
                                                    setSelectedScene(room);
                                                }}
                                                onFinished={(room) => {
                                                    setSelectedScene(room);
                                                    setShowBrowser(false);
                                                    console.log(selectedScene);
                                                }}
                                            />
                                            <Form.Group
                                                className="mb-4"
                                                controlId="formBasicEmail"
                                            >
                                                {selectedScene ? (
                                                    <Row>
                                                        <Col xs={12}>
                                                            <Card.Body
                                                                style={{
                                                                    backgroundImage:
                                                                        "url(" +
                                                                        getPicture(
                                                                            selectedScene.photo
                                                                        ) +
                                                                        ")",
                                                                    backgroundRepeat:
                                                                        "no-repeat",
                                                                    backgroundPosition:
                                                                        "center",
                                                                    backgroundSize:
                                                                        "cover",
                                                                }}
                                                                className="w-100 h-100 pt-0 pb-0 p-0 m-0"
                                                            >
                                                                <Row
                                                                    style={{
                                                                        backdropFilter:
                                                                            "brightness(60%) grayscale(30%)",
                                                                    }}
                                                                    className="d-flex justify-content-center align-items-center h-100 pt-5 pb-5 me-0 pe-3 ms-0 ps-3"
                                                                >
                                                                    <Col>
                                                                        <Card.Title className="text-white mb-0">
                                                                            {
                                                                                selectedScene.title
                                                                            }
                                                                        </Card.Title>
                                                                    </Col>
                                                                    <Col className="d-flex justify-content-end">
                                                                        <Button
                                                                            className="border"
                                                                            variant="success"
                                                                            onClick={() =>
                                                                                setShowBrowser(
                                                                                    true
                                                                                )
                                                                            }
                                                                        >
                                                                            Browse
                                                                            Designs
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Col>
                                                    </Row>
                                                ) : (
                                                    <Button
                                                        variant="purple-dark"
                                                        onClick={() =>
                                                            setShowBrowser(true)
                                                        }
                                                    >
                                                        Select
                                                    </Button>
                                                )}
                                            </Form.Group>
                                        </Form>
                                    ) : (
                                        <Form>
                                            <Form.Group className="mb-3 mt-3">
                                                <Form.Label>
                                                    <strong>
                                                        Select a Lesson
                                                    </strong>
                                                </Form.Label>
                                                <Form.Text className="text-muted">
                                                    <p>
                                                        We'll use this Lesson as
                                                        a template for each new
                                                        Student 3D Space
                                                    </p>
                                                </Form.Text>
                                            </Form.Group>
                                            <MyRoomsBrowser
                                                show={showRoomBrowser}
                                                setShow={setShowRoomBrowser}
                                                setRoom={(room) => {
                                                    setSelectedRoom(room);
                                                }}
                                                setSelectedRoom={
                                                    setSelectedRoom
                                                }
                                                onFinished={(room) => {
                                                    setSelectedRoom(room);
                                                    setShowRoomBrowser(false);
                                                }}
                                            />
                                            <Form.Group
                                                className="mb-4"
                                                controlId="formBasicEmail"
                                            >
                                                <Row>
                                                    <Col xs={12}>
                                                        <Card className="h-100">
                                                            <Card.Body
                                                                style={{
                                                                    backgroundImage:
                                                                        "url(" +
                                                                        getPicture(
                                                                            selectedRoom
                                                                                .scene
                                                                                .photo
                                                                        ) +
                                                                        ")",
                                                                    backgroundRepeat:
                                                                        "no-repeat",
                                                                    backgroundPosition:
                                                                        "center",
                                                                    backgroundSize:
                                                                        "cover",
                                                                }}
                                                                className="w-100 h-100 pt-0 pb-0 p-0 m-0"
                                                            >
                                                                <Row
                                                                    style={{
                                                                        backdropFilter:
                                                                            "brightness(60%) grayscale(30%)",
                                                                    }}
                                                                    className="d-flex justify-content-center align-items-center h-100 pt-5 pb-5 me-0 pe-3 ms-0 ps-3"
                                                                >
                                                                    <Col>
                                                                        <Card.Title className="text-white mb-0">
                                                                            {
                                                                                selectedRoom.title
                                                                            }
                                                                        </Card.Title>
                                                                    </Col>
                                                                    <Col className="d-flex justify-content-end">
                                                                        <Button
                                                                            className="border"
                                                                            variant="success"
                                                                            onClick={() =>
                                                                                setShowRoomBrowser(
                                                                                    true
                                                                                )
                                                                            }
                                                                        >
                                                                            Browse
                                                                            my
                                                                            Lessons
                                                                        </Button>
                                                                    </Col>
                                                                </Row>
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                        </Form>
                                    )}
                                </Card.Body>
                                <Card.Footer className="ps-4 pe-4 pt-3 pb-3">
                                    <Row>
                                        <Col>
                                            <Button
                                                disabled={isPosting}
                                                variant="secondary"
                                                onClick={() => setPage(0)}
                                            >
                                                Previous
                                            </Button>
                                        </Col>
                                        <Col className="d-flex justify-content-center align-items-center">
                                            <p className="mb-0 text-muted">
                                                <small>Step 2 of 3</small>
                                            </p>
                                        </Col>
                                        <Col className="d-flex justify-content-end">
                                            <Button
                                                variant="success"
                                                onClick={() => setPage(3)}
                                            >
                                                Next
                                            </Button>
                                        </Col>
                                    </Row>
                                </Card.Footer>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    if (page === 3) {
        // General Info
        return (
            <>
                <Container fluid>
                    <Button
                        variant="link"
                        className="p-0 mt-3 mb-3 text-decoration-none"
                        as={Link}
                        to={"/student-projects"}
                    >
                        <i className="bi bi-arrow-left me-2"></i>
                        Back to Student Projects
                    </Button>
                </Container>
                <Container
                    className="g-0 bg-light"
                    style={{ maxWidth: "612px" }}
                >
                    <Row className="">
                        <Col>
                            <Form>
                                <Card className="dash-card w-100 mx-auto">
                                    <Tooltip id="info-form" />
                                    <Card.Header className="ps-4 bg-white">
                                        <Card.Title className="pt-2">
                                            New Student Project
                                        </Card.Title>
                                    </Card.Header>
                                    <Card.Body className="ps-4 pe-4">
                                        <Form>
                                            <Form.Group
                                                className="mb-1 mt-3"
                                                controlId="formGroupEmail"
                                            >
                                                <Form.Label>
                                                    Project Name
                                                </Form.Label>
                                                <Form.Control
                                                    required
                                                    value={title}
                                                    onChange={(e) =>
                                                        setTitle(e.target.value)
                                                    }
                                                    disabled={isPosting}
                                                    type="text"
                                                    isInvalid={
                                                        titleError ||
                                                        titleLengthError
                                                    }
                                                    placeholder=""
                                                />
                                                {titleError && (
                                                    <Form.Control.Feedback type="invalid">
                                                        Please give your Student
                                                        Project a name.
                                                    </Form.Control.Feedback>
                                                )}
                                                {titleLengthError && (
                                                    <Form.Control.Feedback type="invalid">
                                                        Your title is too long
                                                    </Form.Control.Feedback>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-3">
                                                <Form.Label>
                                                    Instructions
                                                </Form.Label>
                                                <Form.Text className="text-muted">
                                                    <p>
                                                        <small>
                                                            Here you can give
                                                            your students some
                                                            specific
                                                            instructions on what
                                                            to do in their 3D
                                                            spaces.
                                                        </small>
                                                    </p>
                                                </Form.Text>
                                                <Form.Control
                                                    as="textarea"
                                                    disabled={isPosting}
                                                    value={description}
                                                    onChange={(e) =>
                                                        handleDescription(e)
                                                    }
                                                    placeholder={
                                                        "Example: " +
                                                        pickRandomPlaceholder()
                                                    }
                                                    style={{
                                                        minHeight: "110px",
                                                    }}
                                                    type="text"
                                                />
                                                {count > 500 && (
                                                    <Form.Text
                                                        className={
                                                            count > 2000
                                                                ? "text-danger"
                                                                : "text-muted"
                                                        }
                                                    >
                                                        <p className="mt-1 fw-light float-end">
                                                            <small>
                                                                Character Limit:{" "}
                                                                {count}/2000
                                                            </small>
                                                        </p>
                                                    </Form.Text>
                                                )}
                                            </Form.Group>
                                            <Form.Group className="mb-3 mt-4">
                                                <Form.Label>
                                                    Individual or Group
                                                </Form.Label>
                                                <Form.Text className="text-muted">
                                                    <p>
                                                        <small>
                                                            Should the students
                                                            build by themselves
                                                            or in groups?
                                                        </small>
                                                    </p>
                                                </Form.Text>
                                                <Row>
                                                    <Col xs={6}>
                                                        <Card
                                                            onClick={() =>
                                                                setGroup(false)
                                                            }
                                                            className={
                                                                !group
                                                                    ? "p-4 text-center form-card-light border-success"
                                                                    : "p-4 text-center form-card-white"
                                                            }
                                                        >
                                                            <Card.Body>
                                                                <i
                                                                    style={{
                                                                        fontSize:
                                                                            "1.4rem",
                                                                    }}
                                                                    className="bi bi-person-fill text-purple-dark"
                                                                ></i>
                                                                <p className="mb-0 prevent-select">
                                                                    Individual
                                                                </p>
                                                                {!group ? (
                                                                    <span
                                                                        style={{
                                                                            lineHeight:
                                                                                "100%",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                fontSize:
                                                                                    "1.4rem",
                                                                            }}
                                                                            className="position-absolute top-0 end-0 bi bi-check-circle-fill text-success m-2"
                                                                        ></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        style={{
                                                                            lineHeight:
                                                                                "100%",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                fontSize:
                                                                                    "1.4rem",
                                                                            }}
                                                                            className="position-absolute top-0 end-0 bi bi-circle-fill text-muted m-2"
                                                                        ></i>
                                                                    </span>
                                                                )}
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                    <Col xs={6}>
                                                        <Card
                                                            onClick={() =>
                                                                setGroup(true)
                                                            }
                                                            className={
                                                                group
                                                                    ? "p-4 text-center form-card-light border-success"
                                                                    : "p-4 text-center form-card-white"
                                                            }
                                                        >
                                                            <Card.Body>
                                                                <i
                                                                    style={{
                                                                        fontSize:
                                                                            "1.4rem",
                                                                    }}
                                                                    className="bi bi-people-fill text-purple-dark"
                                                                ></i>
                                                                <p className="mb-0 prevent-select">
                                                                    Group
                                                                </p>
                                                                {group ? (
                                                                    <span
                                                                        style={{
                                                                            lineHeight:
                                                                                "100%",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                fontSize:
                                                                                    "1.4rem",
                                                                            }}
                                                                            className="position-absolute top-0 end-0 bi bi-check-circle-fill text-success m-2"
                                                                        ></i>
                                                                    </span>
                                                                ) : (
                                                                    <span
                                                                        style={{
                                                                            lineHeight:
                                                                                "100%",
                                                                        }}
                                                                    >
                                                                        <i
                                                                            style={{
                                                                                fontSize:
                                                                                    "1.4rem",
                                                                            }}
                                                                            className="position-absolute top-0 end-0 bi bi-circle-fill text-muted m-2"
                                                                        ></i>
                                                                    </span>
                                                                )}
                                                            </Card.Body>
                                                        </Card>
                                                    </Col>
                                                </Row>
                                            </Form.Group>
                                            {/*
                                    <Form.Group className="mb-3 mt-4">
                                        <Form.Label>
                                            <strong>Start and Deadline</strong>
                                        </Form.Label>
                                        <Form.Text className="text-muted">
                                            <p>
                                                Optionally add a deadline and
                                                start date to the assignment.
                                            </p>
                                        </Form.Text>
                                        <Row className="mb-1">
                                            <Col xs={12}>
                                                <Form.Control
                                                    value={description}
                                                    onChange={(e) =>
                                                        setDescription(
                                                            e.target.value
                                                        )
                                                    }
                                                    placeholder=""
                                                    type="datetime-local"
                                                />
                                            </Col>
                                        </Row>
                                    </Form.Group>
                                    */}
                                        </Form>
                                    </Card.Body>
                                    <Card.Footer className="ps-4 pe-4 pt-3 pb-3">
                                        <Row>
                                            <Col>
                                                <Button
                                                    disabled={isPosting}
                                                    variant="secondary"
                                                    onClick={() => setPage(2)}
                                                >
                                                    Previous
                                                </Button>
                                            </Col>
                                            <Col className="d-flex justify-content-center align-items-center">
                                                <p className="mb-0 text-muted">
                                                    <small>Step 3 of 3</small>
                                                </p>
                                            </Col>
                                            <Col className="d-flex justify-content-end">
                                                <Button
                                                    disabled={
                                                        isPosting ||
                                                        count > 2000
                                                    }
                                                    variant="success"
                                                    onClick={() =>
                                                        handleSubmit()
                                                    }
                                                >
                                                    {isPosting ? (
                                                        <>
                                                            <span
                                                                className="spinner-border spinner-border-sm"
                                                                role="status"
                                                                aria-hidden="true"
                                                            ></span>{" "}
                                                            Creating...
                                                        </>
                                                    ) : (
                                                        "Create Project"
                                                    )}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Card.Footer>
                                </Card>
                            </Form>
                        </Col>
                    </Row>
                </Container>
            </>
        );
    }

    return <p>Error</p>;
};

export default NewAssignmentPage;
