import React from "react";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";

const getRandomColor = () => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
};

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);

const ActivityChart = ({ data }) => {
    // Parse JSON

    // Create a dictionary to hold total time for each user per month
    const totalTimePerUserPerMonth = {};

    // Process each session
    data.forEach((session) => {
        const monthYear = session.date_end.slice(0, 7); // Extracting YYYY-MM from the date
        const key = `${session.name}_${monthYear}`;

        if (!totalTimePerUserPerMonth[key]) {
            totalTimePerUserPerMonth[key] = 0;
        }
        totalTimePerUserPerMonth[key] += session.time_spent_minutes;
    });

    // Extract unique months for labels
    const uniqueMonths = [
        ...new Set(
            Object.keys(totalTimePerUserPerMonth).map(
                (key) => key.split("_")[1]
            )
        ),
    ];

    // Create datasets for each user
    const datasets = Object.keys(totalTimePerUserPerMonth).reduce(
        (acc, key) => {
            const [userName, monthYear] = key.split("_");
            const monthIndex = Number( monthYear.split("-")[1]) - 1;

            if (!acc[userName]) {
                acc[userName] = new Array(uniqueMonths.length).fill(0);
            }

            acc[userName][monthIndex] = totalTimePerUserPerMonth[key];
            return acc;
        },
        {}
    );

    // ... [rest of your code remains the same]

    const emptyData = Object.keys(datasets).reduce((acc, userName) => {
        acc[userName] = new Array(uniqueMonths.length).fill(0);
        return acc;
    }, {});

    // Populate the map with actual data
    Object.keys(datasets).forEach((userName) => {
        const userActivity = datasets[userName];
        userActivity.forEach((value, index) => {
            emptyData[userName][index] = value;
        });
    });

    // Create an array to hold total time spent for each month
    const totalPerMonth = new Array(12).fill(0);

    // Sum the time spent for each user and month
    Object.values(datasets).forEach((userActivity) => {
        userActivity.forEach((value, index) => {
            totalPerMonth[index] += value;
        });
    });

    // Adjusted chartData to use 'bar' type and include month names
    const chartData = {
        labels: Array.from({ length: 12 }, (_, i) =>
            new Date(2022, i).toLocaleString("en-US", { month: "short" })
        ), // Display month names
        datasets: [
            {
                label: "Total Time Spent",
                data: totalPerMonth,
                backgroundColor: getRandomColor(), // Generate a random color for the bar
                borderColor: getRandomColor(),
            },
        ],
    };

    // Adjusted chartOptions for the bar chart
    const chartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                type: "category",
                labels: Array.from({ length: 12 }, (_, i) =>
                    new Date(2022, i).toLocaleString("en-US", {
                        month: "short",
                    })
                ), // Display month names
            },
            y: {
                beginAtZero: true,
            },
        },
    };

    return (
        <div>
            <Bar data={chartData} options={chartOptions} />
        </div>
    );
};

export default ActivityChart;
